import { MENU_DASHBOARD, MENU_REPORT_CHART, MENU_REPORT_DOWNLOAD, 
  MENU_DRAFT, MENU_RFP, MENU_RFP_DETAILS_VENDOR_CANDIDATE, 
  MENU_RFP_ACTION_SUBMIT, MENU_DETAILS_REJECT, MENU_DETAILS_APPROVE, MENU_DETAILS_CANCEL, MENU_DETAILS_UPDATE_APPROVER,
  MENU_QUALIFICATION, MENU_QUALIFICATION_ACTION_SEND_RFP,
  MENU_QUALIFICATION_ACTION_DISQUALIFIED, MENU_QUALIFICATION_ACTION_REVISION, MENU_QUALIFICATION_ACTION_APPROVE, 
  MENU_QUALIFICATION_ACTION_SEND_PROPOSAL, MENU_QUALIFICATION_ACTION_ASK_REVISION,
  MENU_SCORING_TEKNIS, MENU_SCORING_ESCROW, MENU_SCORING_DECISION,
  MENU_RFP_NEGOTIATION, MENU_RFP_NEGOTIATION_UPLOAD_OFFER, MENU_RFP_NEGOTIATION_BAN_APPROVE, MENU_RFP_NEGOTIATION_SCORING_PRICE, MENU_RFP_NEGOTIATION_SCORING_PRICE_SUBMIT, 
  MENU_RFP_NEGOTIATION_SCORING_PRICE_APPROVE, MENU_RFP_NEGOTIATION_SCHEDULE_CONFIRM, MENU_RFP_NEGOTIATION_OFFER_DELETE, MENU_RFP_NEGOTIATION_OFFER_SAVE, MENU_RFP_NEGOTIATION_BAN, MENU_RFP_NEGOTIATION_OFFER_REVISION, 
  MENU_RFP_NEGOTIATION_BAN_SUBMIT, MENU_RFP_NEGOTIATION_BAN_VERIFICATOR_CHANGE, MENU_RFP_NEGOTIATION_EXPORT_XLSX, MENU_RFP_NEGOTIATION_OFFER_LIST,
  MENU_RFP_NEGOTIATION_DETAILS_BAN, MENU_RFP_NEGOTIATION_SCORING_PRICE_LOG_APPROVAL, MENU_RFP_NEGOTIATION_OFFER_GET, MENU_RFP_NEGOTIATION_DETAILS, MENU_RFP_NEGOTIATION_BAN_REPORT, MENU_RFP_NEGOTIATION_BAN_PREVIEW, MENU_RFP_NEGOTIATION_RENEGOTIATION,
  MENU_RFQ, MENU_RFQ_ACTION_SUBMIT, MENU_RFQ_DETAILS_APPROVE, MENU_RFQ_DETAILS_REJECT, MENU_RFQ_DETAILS_CANCEL, MENU_RFQ_DETAILS_SEND_RFQ, MENU_RFQ_DETAILS_UPDATE_APPROVER, 
  MENU_RFQ_NEGOTIATION_FPTN_APPROVE, MENU_RFQ_NEGOTIATION_BAN_APPROVE, MENU_RFQ_NEGOTIATION_OFFER_UPLOAD, MENU_RFQ_NEGOTIATION_OFFER_SAVE, MENU_RFQ_NEGOTIATION_OFFER_DELETE,
  MENU_RFQ_NEGOTIATION_REQUIRE, MENU_RFQ_NEGOTIATION_SCHEDULE_CONFIRM, MENU_RFQ_NEGOTIATION_OFFER_DEAL,
  MENU_RFQ_NEGOTIATION_VERIFICATOR_CHANGE, MENU_RFQ_NEGOTIATION_FPTN_SUBMIT, MENU_RFQ_NEGOTIATION_OFFER_REVISION, 
  MENU_RFQ_NEGOTIATION_FPTN, MENU_RFQ_NEGOTIATION_BAN, MENU_RFQ_NEGOTIATION_OFFER_GET, MENU_RFQ_NEGOTIATION_FPTN_REPORT, MENU_RFQ_NEGOTIATION_BAN_REPORT, MENU_RFQ_NEGOTIATION_BAN_PREVIEW, MENU_RFQ_NEGOTIATION_RENEGOTIATION,
  MENU_VENDORS, MENU_SEND_RFI, MENU_LIST_VENDORS, MENU_VENDOR_DETAILS_DOCUMENT_BY_VENDOR, MENU_VENDOR_DETAILS_DOCUMENT_BY_PROJECT, MENU_VENDOR_DETAILS_PROJECTS, MENU_OFFICIAL_SIGNATURE,
  MENU_RFP_SKT_LOI, MENU_RFP_SKT_LOI_CANCEL, MENU_RFP_SKT_LOI_SUBMIT, MENU_RFP_SKT_LOI_SECURITY_CODE, MENU_RFP_SKT_LOI_APPROVAL, MENU_RFP_SKT_LOI_CHANGE_APPROVAL, MENU_RFP_SKT_PDF_PREVIEW, MENU_RFP_LOI_PDF_PREVIEW, 
  MENU_RFP_APPROVAL_DETAIL, MENU_RFP_APPROVAL_PROCUREMENT, MENU_RFP_APPROVAL_LOG, MENU_RFP_APPROVAL_DELETE_DRAFT, MENU_RFP_APPROVAL_APPROVAL, MENU_RFP_APPROVAL_APPROVE_WITH_AGREEMENT, MENU_RFP_APPROVAL_CHANGE_APPROVER, MENU_RFP_APPROVAL_CHANGE_REQUESTER, MENU_RFP_APPROVAL_SECURITY_CODE, MENU_RFP_APPROVAL_PREVIEW,
  MENU_RFQ_LOI, MENU_RFQ_LOI_SUBMIT, MENU_RFQ_LOI_CANCEL, MENU_RFQ_LOI_APPROVAL, MENU_RFQ_LOI_CHANGE_APPROVAL, MENU_RFQ_LOI_SECURITY_CODE, MENU_RFQ_LOI_PDF_PREVIEW, MENU_RFP_SKT_LOI_CHANGE_REQUESTER,
  MENU_RFQ_APPROVAL_DETAIL, MENU_RFQ_APPROVAL_PROCUREMENT, MENU_RFQ_APPROVAL_LOG, MENU_RFQ_APPROVAL_DELETE_DRAFT, MENU_RFQ_APPROVAL_APPROVAL, MENU_RFQ_APPROVAL_APPROVE_WITH_AGREEMENT, MENU_RFQ_APPROVAL_CHANGE_APPROVER, MENU_RFQ_APPROVAL_CHANGE_REQUESTER, MENU_RFQ_APPROVAL_SECURITY_CODE, MENU_RFQ_APPROVAL_PREVIEW,
  MENU_RFQ_LOI_CHANGE_REQUESTER, MENU_RFP_SPP, MENU_RFQ_SPP, MENU_RFQ_SPP_CHANGE_APPROVER, MENU_RFQ_SPP_CANCEL, MENU_RFQ_SPP_SECURITY_CODE     
} from "@/core/constant/menuRole";
import { ENDPOINT_DASHBOARD, ENDPOINT_USER } from "@/core/constant/menuEndpoint";
/* eslint-disable */
export const Store = {
	namespaced: true,

	// nama module, untuk call di component menggunakan "example/<nama_method>"
	name: 'dashboard',

	state: {
    menu: [],
    userProfile: {
      version: null,
      created_by: null,
      created_at: null,
      modified_by: null,
      modified_at: null,
      enabled: null,
      userId: "",
      name: "user",
      email: "",
      external: false,
      internal: true,
      username: "user",
      detil: {
        phone_number: "",
        sub_department_code: "",
        sub_department_name: ""
      }
    },
    filterProject: {
      currentPage: 1,
			pageCount: 10,
			totalRecords: null,
			search: "",
			formDate: {
				start: '',
				end: '',
			},
			type: '',
			selectedType: 'All',
			selectedSort: 'Date Created: Newest',
			sort: 'createdAt,desc',
			status: '',
			selectedStatus: 'All Status',
    },
	},

	getters: {
    //dashboard
    menuDashboard: state => {
      return state.menu?.find(e => e.menuId === MENU_DASHBOARD)
    },
    menuReportChart: state => {
      return state.menu?.find(e => e.menuId === MENU_REPORT_CHART)
    },
    menuReportDownload: state => {
      return state.menu?.find(e => e.menuId === MENU_REPORT_DOWNLOAD)
    },
    menuDraft: state => {
      return state.menu?.find(e => e.menuId === MENU_DRAFT)
    },
    //rfp
    menuRFP: state => {
      return state.menu?.find(e => e.menuId === MENU_RFP)
    },
    menuRfpDetailsVendorCandidate: state => {
      return state.menu?.find(e => e.menuId === MENU_RFP_DETAILS_VENDOR_CANDIDATE)
    },
    menuRfpActionSubmit: state => {
      return state.menu?.find(e => e.menuId === MENU_RFP_ACTION_SUBMIT)
    },
    //detail rfp
    menuDetailsReject: state => {
      return state.menu?.find(e => e.menuId === MENU_DETAILS_REJECT)
    },
    menuDetailsApprove: state => {
      return state.menu?.find(e => e.menuId === MENU_DETAILS_APPROVE)
    },
    menuDetailsCancel: state => {
      return state.menu?.find(e => e.menuId === MENU_DETAILS_CANCEL)
    },
    menuDetailsActionUpdateApprover: state => {
      return state.menu?.find(e => e.menuId === MENU_DETAILS_UPDATE_APPROVER)
    },
    //qualification rfp
    menuQualification: state => {
      return state.menu?.find(e => e.menuId === MENU_QUALIFICATION)
    },
    menuQualificationActionSendRfp: state => {
      return state.menu?.find(e => e.menuId === MENU_QUALIFICATION_ACTION_SEND_RFP)
    },
    menuQualificationActionDisqualified: state => {
      return state.menu?.find(e => e.menuId === MENU_QUALIFICATION_ACTION_DISQUALIFIED)
    },
    menuQualificationActionRevision: state => {
      return state.menu?.find(e => e.menuId === MENU_QUALIFICATION_ACTION_REVISION)
    },
    menuQualificationActionApprove: state => {
      return state.menu?.find(e => e.menuId === MENU_QUALIFICATION_ACTION_APPROVE)
    },
    menuQualificationActionSendProposal: state => {
      return state.menu?.find(e => e.menuId === MENU_QUALIFICATION_ACTION_SEND_PROPOSAL)
    },
    menuQualificationActionAskRevision: state => {
      return state.menu?.find(e => e.menuId === MENU_QUALIFICATION_ACTION_ASK_REVISION)
    },
    //scoring teknis
    menuScoringTeknis: state => {
      return state.menu?.find(e => e.menuId === MENU_SCORING_TEKNIS)
    },
    menuScoringEscrow: state => {
      return state.menu?.find(e => e.menuId === MENU_SCORING_ESCROW)
    },
    menuScoringDecision: state => {
      return state.menu?.find(e => e.menuId === MENU_SCORING_DECISION)
    },
    //negotiation rfp
    menuRfpNegotiation: state => {
      return state.menu?.find(e => e.menuId === MENU_RFP_NEGOTIATION)
    },
    menuRfpNegotiationUploadOffer: state => {
      return state.menu?.find(e => e.menuId === MENU_RFP_NEGOTIATION_UPLOAD_OFFER)
    },
    menuRfpNegotiationBanApprove: state => {
      return state.menu?.find(e => e.menuId === MENU_RFP_NEGOTIATION_BAN_APPROVE)
    },
    menuRfpNegotiationScoringPrice: state => {
      return state.menu?.find(e => e.menuId === MENU_RFP_NEGOTIATION_SCORING_PRICE)
    },
    menuRfpNegotiationScoringPriceSubmit: state => {
      return state.menu?.find(e => e.menuId === MENU_RFP_NEGOTIATION_SCORING_PRICE_SUBMIT)
    },
    menuRfpNegotiationScoringPriceApprove: state => {
      return state.menu?.find(e => e.menuId === MENU_RFP_NEGOTIATION_SCORING_PRICE_APPROVE)
    },
    menuRfpNegotiationScheduleConfirm: state => {
      return state.menu?.find(e => e.menuId === MENU_RFP_NEGOTIATION_SCHEDULE_CONFIRM)
    },
    menuRfpNegotiationOfferDelete: state => {
      return state.menu?.find(e => e.menuId === MENU_RFP_NEGOTIATION_OFFER_DELETE)
    },
    menuRfpNegotiationOfferSave: state => {
      return state.menu?.find(e => e.menuId === MENU_RFP_NEGOTIATION_OFFER_SAVE)
    },
    menuRfpNegotiationBan: state => {
      return state.menu?.find(e => e.menuId === MENU_RFP_NEGOTIATION_BAN)
    },
    menuRfpNegotiationOfferRevision: state => {
      return state.menu?.find(e => e.menuId === MENU_RFP_NEGOTIATION_OFFER_REVISION)
    },
    menuRfpNegotiationBanSubmit: state => {
      return state.menu?.find(e => e.menuId === MENU_RFP_NEGOTIATION_BAN_SUBMIT)
    },
    menuRfpNegotiationBanVerificatorChange: state => {
      return state.menu?.find(e => e.menuId === MENU_RFP_NEGOTIATION_BAN_VERIFICATOR_CHANGE)
    },
    menuRfpNegotiationExportXlsx: state => {
      return state.menu?.find(e => e.menuId === MENU_RFP_NEGOTIATION_EXPORT_XLSX)
    },
    menuRfpNegotiationOfferList: state => {
      return state.menu?.find(e => e.menuId === MENU_RFP_NEGOTIATION_OFFER_LIST)
    },
    menuRfpNegotiationDetailsBan: state => {
      return state.menu?.find(e => e.menuId === MENU_RFP_NEGOTIATION_DETAILS_BAN)
    },
    menuRfpNegotiationScoringPriceLogApproval: state => {
      return state.menu?.find(e => e.menuId === MENU_RFP_NEGOTIATION_SCORING_PRICE_LOG_APPROVAL)
    },
    menuRfpNegotiationOfferGet: state => {
      return state.menu?.find(e => e.menuId === MENU_RFP_NEGOTIATION_OFFER_GET)
    },   
    menuRfpNegotiationDetails: state => {
      return state.menu?.find(e => e.menuId === MENU_RFP_NEGOTIATION_DETAILS)
    },
    menuRfpNegotiationBanReport: state => {
      return state.menu?.find(e => e.menuId === MENU_RFP_NEGOTIATION_BAN_REPORT)
    },
    menuRfpNegotiationBanPreview: state => {
      return state.menu?.find(e => e.menuId === MENU_RFP_NEGOTIATION_BAN_PREVIEW)
    },
    menuRfpNegotiationRenegotiation: state => {
      return state.menu?.find(e => e.menuId === MENU_RFP_NEGOTIATION_RENEGOTIATION)
    },
    menuRfpApprovalProcurement: state => {
      return state.menu?.find(e => e.menuId === MENU_RFP_APPROVAL_PROCUREMENT)
    },
    menuRfpApprovalLog: state => {
      return state.menu?.find(e => e.menuId === MENU_RFP_APPROVAL_LOG)
    },
    menuRfpApprovalDeleteDraft: state => {
      return state.menu?.find(e => e.menuId === MENU_RFP_APPROVAL_DELETE_DRAFT)
    },
    menuRfpApprovalApprove: state => {
      return state.menu?.find(e => e.menuId === MENU_RFP_APPROVAL_APPROVAL)
    },
    menuRfpApprovalApproveWithAgreement: state => {
      return state.menu?.find(e => e.menuId === MENU_RFP_APPROVAL_APPROVE_WITH_AGREEMENT)
    },
    menuRfpApprovalPreview: state => {
      return state.menu?.find(e => e.menuId === MENU_RFP_APPROVAL_PREVIEW)
    },
    menuRfpApprovalChangeApprover: state => {
      return state.menu?.find(e => e.menuId === MENU_RFP_APPROVAL_CHANGE_APPROVER)
    },
    menuRfpApprovalChangeRequester: state => {
      return state.menu?.find(e => e.menuId === MENU_RFP_APPROVAL_CHANGE_REQUESTER)
    },
    menuRfpApprovalSecurityCode: state => {
      return state.menu?.find(e => e.menuId === MENU_RFP_APPROVAL_SECURITY_CODE)
    },
    menuRfpApprovalDetail: state => {
      return state.menu?.find(e => e.menuId === MENU_RFP_APPROVAL_DETAIL)
    },
    menuRfpSktLoi: state => {
      return state.menu?.find(e => e.menuId === MENU_RFP_SKT_LOI)
    },
    menuRfpSktLoiCancel: state => {
      return state.menu?.find(e => e.menuId === MENU_RFP_SKT_LOI_CANCEL)
    },
    menuRfpSktLoiSubmit: state => {
      return state.menu?.find(e => e.menuId === MENU_RFP_SKT_LOI_SUBMIT)
    },
    menuRfpSktLoiSecurityCode: state => {
      return state.menu?.find(e => e.menuId === MENU_RFP_SKT_LOI_SECURITY_CODE)
    },
    menuRfpSktLoiApproval: state => {
      return state.menu?.find(e => e.menuId === MENU_RFP_SKT_LOI_APPROVAL)
    },
    menuRfpSktLoiChangeRequester: state => {
      return state.menu?.find(e => e.menuId === MENU_RFP_SKT_LOI_CHANGE_REQUESTER)
    },
    menuRfpSktLoiChangeApproval: state => {
      return state.menu?.find(e => e.menuId === MENU_RFP_SKT_LOI_CHANGE_APPROVAL)
    },
    menuRfpSktPdfPreview: state => {
      return state.menu?.find(e => e.menuId === MENU_RFP_SKT_PDF_PREVIEW)
    },
    menuRfpLoiPdfPreview: state => {
      return state.menu?.find(e => e.menuId === MENU_RFP_LOI_PDF_PREVIEW)
    },
    menuRfpSpp: state => {
      return state.menu?.find(e => e.menuId === MENU_RFP_SPP)
    },
    //rfq
    menuRFQ: state => {
      return state.menu?.find(e => e.menuId === MENU_RFQ)
    },
    menuRfqActionSubmit: state => {
      return state.menu?.find(e => e.menuId === MENU_RFQ_ACTION_SUBMIT)
    },
    menuRfqDetailsApprove: state => {
      return state.menu?.find(e => e.menuId === MENU_RFQ_DETAILS_APPROVE)
    },
    menuRfqDetailsReject: state => {
      return state.menu?.find(e => e.menuId === MENU_RFQ_DETAILS_REJECT)
    },
    menuRfqDetailsCancel: state => {
      return state.menu?.find(e => e.menuId === MENU_RFQ_DETAILS_CANCEL)
    },
    menuRfqDetailsSendRfq: state => {
      return state.menu?.find(e => e.menuId === MENU_RFQ_DETAILS_SEND_RFQ)
    },
    menuRfqDetailsUpdateApprover: state => {
      return state.menu?.find(e => e.menuId === MENU_RFQ_DETAILS_UPDATE_APPROVER)
    },

    //negotiation rfq
    menuRfqNegotiationFptnApprove: state => {
      return state.menu?.find(e => e.menuId === MENU_RFQ_NEGOTIATION_FPTN_APPROVE)
    },
    menuRfqNegotiationBanApprove: state => {
      return state.menu?.find(e => e.menuId === MENU_RFQ_NEGOTIATION_BAN_APPROVE)
    },
    menuRfqNegotiationOfferUpload: state => {
      return state.menu?.find(e => e.menuId === MENU_RFQ_NEGOTIATION_OFFER_UPLOAD)
    },
    menuRfqNegotiationOfferSave: state => {
      return state.menu?.find(e => e.menuId === MENU_RFQ_NEGOTIATION_OFFER_SAVE)
    },
    menuRfqNegotiationOfferDelete: state => {
      return state.menu?.find(e => e.menuId === MENU_RFQ_NEGOTIATION_OFFER_DELETE)
    },
    menuRfqNegotiationScheduleRequire: state => {
      return state.menu?.find(e => e.menuId === MENU_RFQ_NEGOTIATION_REQUIRE)
    },
    menuRfqNegotiationScheduleConfirm: state => {
      return state.menu?.find(e => e.menuId === MENU_RFQ_NEGOTIATION_SCHEDULE_CONFIRM)
    },
    menuRfqNegotiationOfferDeal: state => {
      return state.menu?.find(e => e.menuId === MENU_RFQ_NEGOTIATION_OFFER_DEAL)
    },
    menuRfqNegotiationVerificatorChange: state => {
      return state.menu?.find(e => e.menuId === MENU_RFQ_NEGOTIATION_VERIFICATOR_CHANGE)
    },
    menuRfqNegotiationFptnSubmit: state => {
      return state.menu?.find(e => e.menuId === MENU_RFQ_NEGOTIATION_FPTN_SUBMIT)
    },
    menuRfqNegotiationOfferRevision: state => {
      return state.menu?.find(e => e.menuId === MENU_RFQ_NEGOTIATION_OFFER_REVISION)
    },
    menuRfqNegotiationFptn: state => {
      return state.menu?.find(e => e.menuId === MENU_RFQ_NEGOTIATION_FPTN)
    },
    menuRfqNegotiationBan: state => {
      return state.menu?.find(e => e.menuId === MENU_RFQ_NEGOTIATION_BAN)
    },
    menuRfqNegotiationOfferGet: state => {
      return state.menu?.find(e => e.menuId === MENU_RFQ_NEGOTIATION_OFFER_GET)
    },
    menuRfqNegotiationFptnReport: state => {
      return state.menu?.find(e => e.menuId === MENU_RFQ_NEGOTIATION_FPTN_REPORT)
    },
    menuRfqNegotiationBanReport: state => {
      return state.menu?.find(e => e.menuId === MENU_RFQ_NEGOTIATION_BAN_REPORT)
    },
    menuRfqNegotiationBanPreview: state => {
      return state.menu?.find(e => e.menuId === MENU_RFQ_NEGOTIATION_BAN_PREVIEW)
    },
    menuRfqNegotiationRenegotiation: state => {
      return state.menu?.find(e => e.menuId === MENU_RFQ_NEGOTIATION_RENEGOTIATION)
    },
    menuRfqApprovalProcurement: state => {
      return state.menu?.find(e => e.menuId === MENU_RFQ_APPROVAL_PROCUREMENT)
    },
    menuRfqApprovalLog: state => {
      return state.menu?.find(e => e.menuId === MENU_RFQ_APPROVAL_LOG)
    },
    menuRfqApprovalDeleteDraft: state => {
      return state.menu?.find(e => e.menuId === MENU_RFQ_APPROVAL_DELETE_DRAFT)
    },
    menuRfqApprovalApproval: state => {
      return state.menu?.find(e => e.menuId === MENU_RFQ_APPROVAL_APPROVAL)
    },
    menuRfqApprovalApproveWithAgreement: state => {
      return state.menu?.find(e => e.menuId === MENU_RFQ_APPROVAL_APPROVE_WITH_AGREEMENT)
    },
    menuRfqApprovalPreview: state => {
      return state.menu?.find(e => e.menuId === MENU_RFQ_APPROVAL_PREVIEW)
    },
    menuRfqApprovalChangeApprover: state => {
      return state.menu?.find(e => e.menuId === MENU_RFQ_APPROVAL_CHANGE_APPROVER)
    },
    menuRfqApprovalChangeRequester: state => {
      return state.menu?.find(e => e.menuId === MENU_RFQ_APPROVAL_CHANGE_REQUESTER)
    },
    menuRfqApprovalSecurityCode: state => {
      return state.menu?.find(e => e.menuId === MENU_RFQ_APPROVAL_SECURITY_CODE)
    },
    menuRfqApprovalDetail: state => {
      return state.menu?.find(e => e.menuId === MENU_RFQ_APPROVAL_DETAIL)
    },
    menuRfqLoi: state => {
      return state.menu?.find(e => e.menuId === MENU_RFQ_LOI)
    },
    menuRfqLoiSubmit: state => {
      return state.menu?.find(e => e.menuId === MENU_RFQ_LOI_SUBMIT)
    },
    menuRfqLoiCancel: state => {
      return state.menu?.find(e => e.menuId === MENU_RFQ_LOI_CANCEL)
    },
    menuRfqLoiApproval: state => {
      return state.menu?.find(e => e.menuId === MENU_RFQ_LOI_APPROVAL)
    },
    menuRfqLoiChangeRequester: state => {
      return state.menu?.find(e => e.menuId === MENU_RFQ_LOI_CHANGE_REQUESTER)
    },
    menuRfqLoiChangeApproval: state => {
      return state.menu?.find(e => e.menuId === MENU_RFQ_LOI_CHANGE_APPROVAL)
    },
    menuRfqLoiSecurityCode: state => {
      return state.menu?.find(e => e.menuId === MENU_RFQ_LOI_SECURITY_CODE)
    },
    menuRfqLoiPdfPreview: state => {
      return state.menu?.find(e => e.menuId === MENU_RFQ_LOI_PDF_PREVIEW)
    },
    menuRfqSpp: state => {
      return state.menu?.find(e => e.menuId === MENU_RFQ_SPP)
    },
    menuRfqSppChangeApprover: state => {
      return state.menu?.find(e => e.menuId === MENU_RFQ_SPP_CHANGE_APPROVER)
    },
    menuRfqSppCancel: state => {
      return state.menu?.find(e => e.menuId === MENU_RFQ_SPP_CANCEL)
    },
    menuRfqSppSecurityCode: state => {
      return state.menu?.find(e => e.menuId === MENU_RFQ_SPP_SECURITY_CODE)
    },
    
    //vendors
    menuVendors: state => {
      return state.menu?.find(e => e.menuId === MENU_VENDORS)
    },
    menuSendRFI: state => {
      return state.menu?.find(e => e.menuId === MENU_SEND_RFI)
    },
    menuListVendors: state => {
      return state.menu?.find(e => e.menuId === MENU_LIST_VENDORS)
    },
    menuVendorDetailsDocumentByVendor: state => {
      return state.menu?.find(e => e.menuId === MENU_VENDOR_DETAILS_DOCUMENT_BY_VENDOR)
    },
    menuVendorDetailsDocumentByProject: state => {
      return state.menu?.find(e => e.menuId === MENU_VENDOR_DETAILS_DOCUMENT_BY_PROJECT)
    },
    menuVendorDetailsProjects: state => {
      return state.menu?.find(e => e.menuId === MENU_VENDOR_DETAILS_PROJECTS)
    },
    menuOfficialSignature: state => {
      return state.menu?.find(e => e.menuId === MENU_OFFICIAL_SIGNATURE)
    },
  },

	mutations: {
    SET_MENU(state, payload) {
      state.menu = payload
    },
    SET_USERPROFILE(state, payload) {
			state.userProfile = payload
		},
    SET_FILTER_PROJECT(state, payload) {
      state.filterProject = payload
    },
	},

	actions: {
    async getMenu({ commit }, payload) {
      try {
        const response = await this.$api.get(ENDPOINT_DASHBOARD)
        console.log(response, 'menu');
        commit('SET_MENU', response.data.result)
        return response
      } catch (error) {
        console.log(error);
      }
    },
    // get user detail/user profile
    async getUserDetail({commit, state}) {
			try {
				const response = await this.$api.get(ENDPOINT_USER)
				console.log(response.data, 'user profile')
				commit('SET_USERPROFILE', response.data.result)
        return response
			} catch (error) {
				console.log(error);
			}
		},
    async resetFilterProject({commit}) {
      const filterProject = {
        currentPage: 1,
        pageCount: 10,
        totalRecords: null,
        search: "",
        formDate: {
          start: '',
          end: '',
        },
        type: '',
        selectedType: 'All',
        selectedSort: 'Date Created: Newest',
        sort: 'createdAt,desc',
        status: '',
        selectedStatus: 'All Status',
      }
      commit('SET_FILTER_PROJECT', filterProject)
    },
    setQueryBacktoHome({ state, rootState }, payload) {
      return ({
				project: rootState.projectLog.activeButton?.toLowerCase(),
				...(state.filterProject.selectedStatus && state.filterProject.selectedStatus !== 'All Status' && { status: state.filterProject.selectedStatus }),
				...(state.filterProject.formDate.start && { start: state.filterProject.formDate.start }),
				...(state.filterProject.formDate.end && { end: state.filterProject.formDate.end }),
				...(state.filterProject.selectedSort && state.filterProject.selectedSort !== 'Date Created: Newest' && { sort: state.filterProject.selectedSort }),
				...(state.filterProject.search && { search: state.filterProject.search }),
				...(state.filterProject.selectedType && state.filterProject.selectedType !== 'All' && { type: state.filterProject.selectedType }),
				...(state.filterProject.currentPage && state.filterProject.currentPage !== 1 && { page: state.filterProject.currentPage }),
				...(state.filterProject.pageCount && +state.filterProject.pageCount !== 10 && { size: state.filterProject.pageCount }),
			})
    }
	}
}
