<template>
  <div>
    <div class="top-0 z-50 flex flex-wrap items-center justify-between w-full gap-6 p-4 bg-white shadow-primary-sm stikcy">
      <div class="flex">
        <router-link :to="GISTA" exact>
          <img src="@/core/assets/icons/navbar/gista.svg" alt="gista" width="40" />
        </router-link>
      </div>
      <div class="flex items-center gap-6">
        <router-link
          :to="NOTIF"
          exact
          class="flex items-center justify-center w-10 h-10 transition bg-white rounded-lg shadow-primary-sm border-primary"
        >
          <span class="text-2xl font-semibold icon-bell text-primary"></span>
        </router-link>
        <router-link
          :to="INBOX"
          exact
          class="flex items-center justify-center w-10 h-10 transition bg-white rounded-lg shadow-primary-sm border-primary"
        >
          <span class="text-2xl font-semibold icon-inbox text-primary"></span>
        </router-link>
        <router-link :to="PROFILE">
          <div
            class="flex items-center justify-center w-12 h-12 overflow-hidden text-lg font-semibold transition rounded-full initial bg-primary-lightest text-primary-darkest"
          >
            {{ nameInitials }}
            <slot></slot>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { PATH_ROOT, INBOX, NOTIF, PROFILE, GISTA } from '@/core/constant/routeName'

export default {
  name: "Navbar",
  data() {
    return {
      userInfo: window.keycloak.tokenParsed,
      INBOX,
      NOTIF,
      PROFILE,
      GISTA,
    };
  },
  computed: {
    userProfile() {
      return this.$store.state.dashboard.userProfile
    },
    nameInitials() {
      let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");
      let initials = [...this.userInfo.name.matchAll(rgx)] || [];
      initials = (
        (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
      ).toUpperCase();
      return initials;
    },
  },
  methods: {
    async logout() {
      await this.$router.push(`/${PATH_ROOT}`).catch(() => ({}))
      window.keycloak.logout()
    },
    toHome() {
      this.$router.push(`/${PATH_ROOT}`).catch(() => ({}))
    },
  },
};
</script>
