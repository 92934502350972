<template>
  <div v-if="mainPage !== EDIT && sppProcess && sppPage && menuRfpSpp?.canUpdate && (someWaitingApproval ||isRejected)">
		<div class="flex flex-col justify-between gap-6 p-4 sm:flex-row">
			<div class="w-full break-words sm:w-1/2">
				<ButtonGista type="secondary"
					color="error"
					@click="toggleCancel"
					customClass="w-full">
					Cancel SPP</ButtonGista>
			</div>
      <div class="w-full sm:w-1/2" v-if="isRejected">
        <ButtonGista 
          color="secondary"
          @click="clickEdit"
          customClass="w-full">
          Edit SPP</ButtonGista>
      </div>
			<div class="w-full sm:w-1/2" v-if="!isRejected">
				<ButtonGista type="secondary"
					color="primary"
					@click="toggleIsOpen"
					customClass="w-full">
					Change Approvers</ButtonGista>
			</div>
		</div>
    <ModalConfirm 
      title="Batalkan SPP?" 
      message="Apakah Anda Yakin?" 
      icon="rejected" 
      textButton="Yes" 
      :isOpen="isOpenCancel" 
      @changeIsOpen="() => isOpenCancel = false" 
      @click="clickCancel"
      colorButton="error"></ModalConfirm>
		<GistaModal :value="isOpen"
			@input="toggleIsOpen"
			customClass="max-w-2xl bg-white">
      <ValidationObserver v-slot="{ handleSubmit }">
				<div class="w-full p-5">
					<div class="flex justify-center w-full mb-4">
						<h5 class="text-lg font-semibold text-gray">Change Approver</h5>
					</div>
					<div class="w-full mb-4">
            <ValidationProvider name="newApprover"
              rules="required"
              v-slot="{ errors }">
              <AutocompleteNewSolutip
                label="New Approver"
                placeholder="Search"
                :options="approverList"
                @updateInput="inputSearchApprover($event)"
                @updateAutoComplete="changeApprover($event)"
                @focus="focusApprover()"
                @blur="blurApprover()"
                :error="!!errors[0]"
                v-model="form.search"
                :isLoading="isLoading"
                :mandatory="true"
                textField="name"
                textField2="jobTitle"
                iconLeft="icon-search text-xl text-gray-light"
              >
                <template #message>
                  <p class="mt-1 text-xs text-error">{{ errors[0] }}</p>
                </template>
              </AutocompleteNewSolutip>
            </ValidationProvider>
					</div>
					<div class="w-full mb-4">
						<ValidationProvider name="notes"
							rules="required|max:500"
							v-slot="{ errors }">
							<TextareaGista label="Notes"
								placeholder="Leave a comment here"
								v-model="form.notes"
								:error="!!errors[0]"
								:mandatory="true">
								<template #message>
									<p>{{ errors[0] }}</p>
								</template>
							</TextareaGista>
						</ValidationProvider>
					</div>
					<div class="flex justify-end">
						<ButtonGista type="primary"
							color="success"
							@click="handleSubmit(submitChange)"
							customClass="w-full sm:w-56">
							Change Approver
						</ButtonGista>
					</div>
				</div>
			</ValidationObserver>
		</GistaModal>
	</div>
</template>

<script>
import AutocompleteNewSolutip from '@/core/components/custom/AutocompleteNew'
import ModalConfirm from '@/core/components/modal/Confirm'
import { MESSAGE_SUCCESS_CHANGE_APPROVAL } from '@/core/constant/successMessage'
import { PATH_ROOT } from '@/core/constant/routeName'
import { EDIT } from '@/core/constant/procurementApproval.js'

export default {
  name: 'SppChangeApprover',
  data() {
    return {
      EDIT,
      isOpen: false,
      isOpenCancel: false,
      form: {
        username: '',
        name: '',
        notes: '',
        search: ''
      },
      fields: [],
      isLoading: false,
      applicationRoles: {
        REVIEWER_DLOG: 'SOLUTIP_SPV_LOG,SOLUTIP_SPV1_LOG,SOLUTIP_SPV2_LOG,SOLUTIP_SPV3_LOG',
        APPROVER_DLOG: 'SOLUTIP_SPV1_LOG,SOLUTIP_SPV2_LOG,SOLUTIP_SPV3_LOG',
        REVIEWER_GSIT: 'SOLUTIP_SPV1_GSIT,SOLUTIP_SPV2_GSIT,SOLUTIP_SPV3_GSIT',
        APPROVER_GSIT: 'SOLUTIP_SPV2_GSIT,SOLUTIP_SPV3_GSIT,SOLUTIP_EVP',
        REVIEWER_UKKP: 'SOLUTIP_SPV1_KP,SOLUTIP_SPV2_KP,SOLUTIP_SPV3_KP',
        APPROVER_UKKP: 'SOLUTIP_SPV1_KP,SOLUTIP_SPV2_KP,SOLUTIP_SPV3_KP',
			},
      eselon: 's1,s2,s3,s4',
      REVIEWER_DLOG: 'REVIEWER_DLOG'
    };
  },
  computed: {
		dataSpp: {
      get() {
        return this.$store.state.spp.dataSpp
      },
      set(value) {
        this.$store.commit('spp/SET_DATA_SPP', value)
      }
    },
		approvals: {
      get() {
        return this.$store.state.spp.approvals
      },
      set(value) {
        this.$store.commit('spp/SET_APPROVALS', value)
      }
    },
    filterForApproverList() {
			const people = []
      for (const key in this.dataSpp.approvals) {
        if (this.dataSpp.approvals[key].username) {
          people.push(this.dataSpp.approvals[key].username)
        }
      }
      if (this.form.username) {
        people.push(this.form.username)
      }
      return people
		},
    approverList() {
			return this.$store.state.rfp.approverList.filter(e => !this.filterForApproverList.includes(e.userName))
		},
    onReviewApproval() {
      return this.dataSpp.approvals.find(e => e.approvalStatus === 'AWAITING_APPROVAL')
    },
		sppPage() {
      return this.$route.path.includes('project-spp')
    },
    modal: {
			get() {
				return this.$store.state.modal.modal
			},
			set(value) {
				this.$store.commit('modal/SET_MODAL', value)
			}
		},
    userDetail() {
      return this.$store.state.dashboard.userProfile;
    },
    requester() {
      return this.dataSpp.requester?.toLowerCase() === this.userDetail.username?.toLowerCase()
    },
    someWaitingApproval() {
      return this.dataSpp.approvals?.some(e => e.approvalStatus === 'AWAITING_APPROVAL')
    },
    menuRfpSpp() {
      return this.$store.getters['dashboard/menuRfpSpp']
    },
    mainPage() {
      return this.$store.state.spp.mainPage
    },
    sppProcess() {
      return this.$store.state.spp.sppProcess
    },
    isRejected() {
      return this.dataSpp.approvals?.some(e => e.approvalStatus === 'REJECT')
    },
  },
  methods: {
    toggleIsOpen() {
      this.isOpen = !this.isOpen
      this.form = {
        username: '',
        name: '',
        notes: '',
        search: ''
      }
    },
		toggleCancel() {
      this.isOpenCancel = !this.isOpenCancel
    },
		async clickCancel() {
      this.modal.modalLoading = true
			const response = await this.$store.dispatch('spp/cancelApprovalSpp', { rfpId: this.$route.params.projectId })
      if (response?.status) {
				this.toggleCancel()
        const query = await this.$store.dispatch('dashboard/setQueryBacktoHome')
        this.$store.dispatch('spp/resetState', { resetAll: true })
			  this.$router.push({ path: `/${PATH_ROOT}`, query }).catch(() => ({}))
      }
      this.modal.modalLoading = false
    },
    async getApproverList(key) {
      this.isLoading = true
      await this.$store.dispatch('rfp/getApproverList', { applicationRoles: this.applicationRoles[key], name: this.form.search, eselon: key === this.REVIEWER_DLOG? this.eselon : '' })
      this.isLoading = false
    },
    inputSearchApprover() {
      const type = this.onReviewApproval.approvalType
      this.getApproverList(type)
		},
    focusApprover() {
      const type = this.onReviewApproval.approvalType
      this.getApproverList(type)
		},
    async changeApprover(e) {
      this.form = {
        ...this.form,
        username: e.userName,
        name: e.name,
        search: e.name
      }
		},
    blurApprover() {
			if (!this.form.search) {
				this.form = {
          ...this.form,
          username: '',
          name: '',
          search: ''
        }
			} else {
        this.form = {
          ...this.form,
          search: this.form.name
        }
			}
		},
    async submitChange() {
      this.modal.modalLoading = true
      const response = await this.$store.dispatch('spp/changeApprovalSpp', { rfpId: this.$route.params.projectId, data: { username: this.form.username, name: this.form.name, notes: this.form.notes } })
      if (response?.status < 300) {
        this.$store.dispatch('projectLog/refetchSppRfpLog', { rfpId: this.$route.params.projectId })
        this.modal.modalSuccessApproved = { value: true, title: MESSAGE_SUCCESS_CHANGE_APPROVAL }
        this.toggleIsOpen()
      }
      this.modal.modalLoading = false
    },
    clickEdit() {
      this.$store.commit('spp/SET_MAIN_PAGE', EDIT)
    }
  },
  components: {
    AutocompleteNewSolutip,
		ModalConfirm
  }
};
</script>