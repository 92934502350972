export async function run(service) {
  const requireRouters = require.context("@/modules", true, /router\.js$/);
  requireRouters.keys().forEach((fileName) => {
    const router = requireRouters(fileName);

    const routerService = service.Router;
    routerService.registerModuleRoutes(router.Routes);
  });

  const requireStores = require.context("@/modules", true, /\.store\.js$/);
  requireStores.keys().forEach((fileName) => {
    const store = requireStores(fileName);

    const storeService = service.Store;
    storeService.registerModuleStore(store.Store);
  });
}
