<template>
	<div>
    <gista-modal :value="isOpen"
      customClass="max-w-2xl bg-white"
      :scrollable="true"
      @input="closeModal">
      <div class="w-full p-6">
        <div class="flex justify-center mb-6" v-if="title">
						<p class="w-full text-xl font-semibold text-gray-dark">{{ title }}</p>
					</div>
        <div class="flex">
          <p class="w-full break-words whitespace-pre-wrap text-gray">{{ text }}</p>
        </div>
      </div>
    </gista-modal>
	</div>
</template>

<script>
export default {
	name: "ModalReadMore",
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    text: {
      type: String,
      default: '',
    }
  },
	methods: {
		closeModal() {
			this.$emit("click")
		}
	}
}
</script>