/* eslint-disable */
import { ENDPOINT_RFQ_QUOTATION_ITEM_LIST, ENDPOINT_RFQ_QUOTATION_ITEM_LIST_PAGINATION, ENDPOINT_RFQ_DETAILS_APPROVE, ENDPOINT_RFQ_DETAILS_REJECT,ENDPOINT_RFQ_DETAILS_SEND_RFQ, ENDPOINT_RFQ_DETAILS, ENDPOINT_RFQ_DETAILS_UPDATE_APPROVER } from '@/core/constant/menuEndpoint'
import { formatThousandSeparate } from '@/core/components/helpers/formatRupiah'


export const Store = {
  namespaced: true,
  name: 'quotationDetail',
  state: {
    activePage: 'quotation-detail',
    paramQuotationId: 0,
    quotationInformation: {
      isNewProject: true,
      projectId: "",
      projectName: "", 
      vendorId: "", 
      vendorName: "", 
      isNewVendor: false, 
      tahun: "", 
      budgetSpent: "", 
      kodeWbsLev1: "", 
      judulRfq: "", 
      notes: "",
      directApproverId: "", 
      directApproverName: "",
      filterLocalPartnerVendorId: "",
      filterVendorId: ""
    },
    rfqItemLists: [],
    rfqItemListsPagination: {}
  },
  gettters: {

  },
  mutations: {
    SET_ACTIVE_PAGE(state, payload) {
      state.activePage = payload
    },
    SET_PARAM_QUOTATION_ID(state, payload) {
      state.paramQuotationId = payload
    },
    SET_QUOTATION_INFORMATION(state, payload) {
      state.quotationInformation = payload
    },
    SET_RFQ_ITEM_LISTS(state, payload) {
      state.rfqItemLists = payload
    },
    SET_RFQ_ITEM_LISTS_PAGINATION(state, payload) {
      state.rfqItemListsPagination = payload
    },
    SET_RFQ_REASON_CANCEL(state, payload) {
      state.reasonCancel = payload
    }
  },
  actions: {
    //Get API
    async getQuotationInformation({ commit, state, dispatch }, payload) {
      try {
        const rfqId = payload
        const response = await this.$api.get(`${ENDPOINT_RFQ_DETAILS}/${rfqId}`)
        const result = response.data.result
        const dataCommit = { ...result, budgetSpent: formatThousandSeparate(result.budgetSpent?.toString(), 'idr')}
        commit('SET_QUOTATION_INFORMATION', dataCommit)
        return response
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async getQuotationItemList({ commit, state, dispatch }, payload) {
      try {
        const rfqId = payload
        const response = await this.$api.get(`${ENDPOINT_RFQ_QUOTATION_ITEM_LIST}/${rfqId}`)
        commit('SET_RFQ_ITEM_LISTS', response.data.result)
        return response
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async getQuotationItemListPagination({ commit, state, dispatch }, payload) {
      try {
        const { rfqId, page, size } = payload
        const response = await this.$api.get(`${ENDPOINT_RFQ_QUOTATION_ITEM_LIST_PAGINATION}/${rfqId}`, { page, size })
        const content = response?.data?.result.content.map(e => {
          return {
            ...e,
            qty: e.qty.toString().split('.').join(',')
          };
        })
        const data = {...response?.data?.result, content}
        commit('SET_RFQ_ITEM_LISTS_PAGINATION', data)
        return response
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    //Api Put Approval Quotation
    async putRejectQuotation({ commit, dispatch, rootState }, payload) {
      try {
        const { rfqId, reason } = payload
        return await this.$api.put(`${ENDPOINT_RFQ_DETAILS_REJECT}/${rfqId}`, { reason })
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async putApproveQuotation({ commit, dispatch }, payload) {
      try {
        const { rfqId, reason } = payload
        return await this.$api.put(`${ENDPOINT_RFQ_DETAILS_APPROVE}/${rfqId}`, { reason })
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    //put change approver
    async putChangeApprover({ commit, dispatch }, payload) {
      try {
        const data = payload
        return await this.$api.put(ENDPOINT_RFQ_DETAILS_UPDATE_APPROVER, data)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },

    async putSendRFQ({ commit, dispatch}, payload) {
      try {
        return await this.$api.put(ENDPOINT_RFQ_DETAILS_SEND_RFQ, payload)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    }

  }
  
}