<template>
  <div class="w-full flex flex-col items-center relative">
    <div class="w-full flex justify-center">
      <div class="w-11/12 mb-20">
        <slot></slot>
        <BackToTop/>
      </div>
    </div>
    <Modal/>
  </div>
</template>
<script>
import Modal from './Modal'
import BackToTop from '@/core/components/custom/BackToTop'
export default {
  name: "MainLayout",
  components: { 
    Modal,
    BackToTop
  },
  
};
</script>
