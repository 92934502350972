<template>
	<div class="w-full" v-if="approvalPage && isSubmitted && !isRejected && onReviewManagement && menuRfqApprovalApproveWithAgreement?.canUpdate">
		<div class="w-full p-4">
      <p class="mb-1 text-sm text-gray">Procurement Approval from Approver</p>
      <p class="mb-6 text-sm text-gray-dark">Upload signed approval</p>
      <div class="flex flex-col justify-between gap-6 sm:flex-row">
        <ButtonGista 
          type="secondary"
          color="error"
          :isDark="false"
          @click="toggleReject"
          customClass="w-full">
          Reject RFQ
        </ButtonGista>
        <ButtonGista 
          type="primary"
          color="success"
          @click="toggleUpload"
          class="w-full">
          Upload Approval</ButtonGista>
      </div>
		</div>
		<GistaModal 
      :value="isOpen"
      customClass="max-w-2xl bg-white"
			@input="toggleReject">
			<ValidationObserver v-slot="{ handleSubmit }">
        <div class="w-full p-5">
          <div class="flex justify-center w-full">
            <h5 class="text-lg font-semibold text-gray">Tolak Persetujuan</h5>
          </div>
          <div class="flex-wrap w-full mb-4">
            <ValidationProvider name="Reason" rules="required|max:500" v-slot="{errors}">
              <TextareaGista
                label="Reason"
                placeholder="Leave a comment here"
                v-model="reason"
                :error="!!errors[0]"
                mandatory
              >
                <template #message>
                  <p>{{errors[0]}}</p>
                </template>
              </TextareaGista>
            </ValidationProvider>

          </div>
          <div class="flex justify-center">
            <ButtonGista 
              type="primary"
              color="error"
              @click="handleSubmit(clickReject)"
              customClass="w-full sm:w-56">
              Reject
            </ButtonGista>

          </div>
        </div>
      </ValidationObserver>
		</GistaModal>
		<GistaModal 
      :value="isOpen2"
      customClass="max-w-2xl bg-white"
			@input="toggleUpload">
      <ValidationObserver ref="updateApprover">
				<div class="w-full p-5">
					<div class="flex justify-center w-full mb-4">
						<h5 class="text-lg font-semibold text-gray">Unggah Persetujuan dari Approver</h5>
					</div>
					<div class="mb-4">
						<p class="mb-1 text-sm text-gray">Upload file <span class="text-sm text-error">*</span></p>
						<div class="flex items-center justify-between w-full border rounded-lg"
							:class="`${errorFileType ? 'border-error' : 'border-primary'}`">
							<input type="file"
								id="upload-file-update-approver"
								:ref="fileRef"
								hidden
								@change="chooseFile($event, fileRef)" />
							<div class="flex justify-between" style="width: calc(100% - 7rem);">
								<span class="px-4 text-sm truncate text-gray" :title="files?.fileName">{{ files?.fileName }}</span>
								<span v-if="files?.fileName" class="pr-2 cursor-pointer icon-trash-2 text-error hover:text-error-dark" @click="clickRemoveFileAttachment()"></span>
							</div>
							<div v-if="!uploaded">
								<label for="upload-file-update-approver"
									class="flex items-center justify-center p-2 text-white rounded-r-lg cursor-pointer bg-primary hover:bg-primary-dark w-28 h-14">
									Pilih File
								</label>
							</div>
							<div v-else>
								<label for="view-file-update-approver"
									class="flex items-center justify-center p-2 text-white rounded-r-lg cursor-pointer bg-primary hover:bg-primary-dark w-28 h-14">
									<span class="text-2xl cursor-pointer icon-eye text-white" @click="openFile(files)"></span>
								</label>
							</div>
						</div>
						<div>
							<p class="mt-1 text-xs text-tertiary"
								:class="{ 'text-error': errorFileType }">
								Supported file: .pdf, max file size: 10 MB
							</p>
						</div>
					</div>
					<div class="flex-wrap w-full mb-4">
            <ValidationProvider name="Reason" rules="required|max:500" v-slot="{errors}">
              <TextareaGista
                label="Reason"
                placeholder="Leave a comment here"
                v-model="reason"
                :error="!!errors[0]"
                mandatory
              >
                <template #message>
                  <p>{{errors[0]}}</p>
                </template>
              </TextareaGista>
            </ValidationProvider>
          </div>
          <div class="flex flex-col gap-6 py-6 mt-6 border-t-2 lg:flex-row border-gray-lightest" >
            <div class="flex items-center flex-auto">
              <p class="mr-3 text-xl icon-alert-circle text-gray"></p>
              <p class="mr-3 font-medium text-gray">Pastikan seluruh informasi sudah benar, tindakan ini tidak bisa dibatalkan</p>
            </div>
            <div class="flex-none">
              <ButtonGista type="primary"
                color="success"
                @click="clickUploadApproval"
                customClass="w-full sm:w-56">
                Upload & Approve
              </ButtonGista>
            </div>
          </div>
				</div>
			</ValidationObserver>
		</GistaModal>
	</div>
</template>
<script>
import { MESSAGE_SUCCESS_UPLOAD_APPROVAL, MESSAGE_SUCCESS_APPROVAL_REJECT } from '@/core/constant/successMessage'
import { CHECKER, RECOMMENDATION, APPROVER, PROCUREMENT_APPROVED } from '@/core/constant/procurementApproval.js'

export default {
	name: "UploadApprovalReject",
	data() {
		return {
			isOpen: false,
			isOpen2: false,
      reason: '',
			files: {},
			errorFileType: false,
			fileRef: 'approval',
			uploaded: false,
		}
	},
  computed: {
    modal: {
			get() {
				return this.$store.state.modal.modal
			},
			set(value) {
				this.$store.commit('modal/SET_MODAL', value)
			}
		},
		approvalPage() {
      return this.$route.path.includes('approval')
    },
		dataProcurementApproval() {
      return this.$store.state.procurementApprovalRfq.dataProcurementApproval
    },
    isSubmitted() {
      return this.dataProcurementApproval.isSubmitted
    },
		menuRfqApprovalApproveWithAgreement() {
			return this.$store.getters['dashboard/menuRfqApprovalApproveWithAgreement']
		},
		logApproval() {
      return this.$store.state.procurementApprovalRfq.logApproval
    },
    userApprover() {
			return this.logApproval?.requiredUploadSignedApproval
    },
		recommendationChecker() {
      return this.logApproval?.logDetails?.filter(e => e.approvalType === CHECKER || e.approvalType === RECOMMENDATION)
		},
		approver() {
			return this.logApproval?.logDetails?.filter(e => e.approvalType === APPROVER)
		},
		onReviewManagement() {
      return this.recommendationChecker?.every(e => e.approvalStatus === PROCUREMENT_APPROVED) && this.approver?.some(e => e.requiredUploadSignedApproval && e.approvalStatus !== PROCUREMENT_APPROVED )
    },
		isRejected() {
      return this.dataProcurementApproval.isRejected
    },
  },
	methods: {
    async clickReject() {
			this.modal.modalLoading = true
      const response = await this.$store.dispatch('procurementApprovalRfq/putApprovalApprove', { approvalId: this.dataProcurementApproval.id, notes: this.reason, action: 'REJECT' })
      if (response?.status < 300) {
        this.toggleReject()
        this.$store.dispatch('projectLog/refetchApprovalProcurementRfqLog', { rfqId: this.$route.params.quotationId })
        this.modal.modalSuccessApproved = { value: true, title: MESSAGE_SUCCESS_APPROVAL_REJECT }
      }
      this.modal.modalLoading = false
    },
    async chooseFile(e, fileRef) {
			const file = e.target.files[0]
			const checkFileValidation = await this.$store.dispatch('file/checkFileValidation', { file, fileType: ['application/pdf'] })
			if(checkFileValidation) {
				if(!this.files) this.errorFileType = true
			} else {
				this.errorFileType = false
				this.modal.modalLoading = true
				const encrypted = await this.$store.dispatch('file/hashMD5',file)
				const formData = new FormData()
				formData.append('file', file)
				formData.append('fileRef', fileRef)
				//checksum encrypted md5
				formData.append('checksum', encrypted)
				formData.append('projectType', 'RFQ')
				const response = await this.$store.dispatch('file/uploadFile', formData)
				if (response?.status < 300 && response?.status >= 200) {
					console.log(response?.headers, 'response headers upload file');
					const data = response.data.result
					this.files = {
						fileName: data.fileName,
						filePath: data.filePath,
						documentNo: data.documentNo,
						fileRef: fileRef,
						isDeleted: false,
						id: null
					}
					this.uploaded = true
				}
				this.modal.modalLoading = false
			}
			if (this.$refs[fileRef]) {
				this.$refs[fileRef].value = ''
			}
		},
    checkErrorValidate() {
			if (!this.files?.fileName) {
				this.errorFileType = true
			}
			return !this.errorFileType
		},
    async clickUploadApproval() {
			this.modal.modalLoading = true
			const checkErrorValidate = this.checkErrorValidate()
			const success = await this.$refs.updateApprover.validate()
			if (!checkErrorValidate || !success) {
				this.modal.modalLoading = false
				return
			}
			const dataPayload = {
				approvalId: this.dataProcurementApproval.id, 
				reason: this.reason,
				fileRef: this.files.fileRef,
				fileName: this.files.fileName,
				filePath: this.files.filePath,
				documentNo: this.files.documentNo,
			}
			const response = await this.$store.dispatch('procurementApprovalRfq/putApprovalApproveWithAgreement', dataPayload)
			if (response?.status < 300) {
				this.isOpen2 = !this.isOpen2
      	this.reason = ''
				this.files =  {}
        this.$store.dispatch('projectLog/refetchApprovalProcurementRfqLog', { rfqId: this.$route.params.quotationId })
				this.modal.modalSuccessSaved = { value: true, title: MESSAGE_SUCCESS_UPLOAD_APPROVAL }
			}
			this.modal.modalLoading = false
		},
		async clickRemoveFileAttachment(){
      this.modal.modalLoading = true
			const response = await this.$store.dispatch('file/deleteFile', this.files.documentNo || this.files.fileName)
			if (response?.status < 300) {
				this.files = {}
				this.uploaded = false
			}
			this.modal.modalLoading = false
    },
		toggleReject() {
			this.isOpen = !this.isOpen
      this.reason = ''
		},
		toggleUpload() {
			if (this.files.fileName) {
				this.clickRemoveFileAttachment()
			}
			this.isOpen2 = !this.isOpen2
      this.reason = ''
		},
		openFile(n) {
			const { fileName, documentNo } = n
      this.$store.dispatch('file/viewAttachment', { filename: fileName, documentNo })
    },
	},
}
</script>

<style scoped>
.width-button-submit {
  max-width: 40rem;
}
</style>