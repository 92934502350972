// constant 
// route name
// route name for conditional difference between create rfp and edit rfp
export const EDITRFP = 'EditRFP'
export const EDITRFQ = 'EditRFQ'
export const DRAFTRFQ = 'DraftRFQ'

export const RFPNAME = 'RFP'
export const RFQNAME = 'RFQ'
export const DRAFTRFP = 'DraftRFP'
export const DETAIL = 'projectDetail'
export const PRAQUALIFICATION = 'projectPraQualification'
export const QUALIFICATION = 'projectQualification'
export const SCORING = 'projectScoring'
export const NEGOTIATION = 'projectNegotiation'
export const APPROVAL = 'projectApproval'
export const SKTLOI = 'projectSktLoi'
export const SPP = 'projectSpp'
export const ADDVENDORCANDIDATE = 'AddVendorCandidate'

export const QUOTATIONDETAILS = 'QuotationDetails'
export const QUOTATIONNEGOTIATION = 'QuotationNegotiation'
export const QUOTATIONAPPROVAL = 'QuotiationApproval'
export const QUOTATIONLOI = 'QuotiationLoi'
export const QUOTATIONSPP = 'QuotiationSpp'



// route path
export const QUOTATION_DETAIL = 'quotation-detail'

//path root
export const PATH_ROOT = 'pengadaan-it'



//path project detail
export const PROJECT_DETAIL = 'project-detail'
export const PROJECT_PRA_QUALIFICATION = 'project-pra-qualification'
export const PROJECT_QUALIFICATION = 'project-qualification'
export const PROJECT_SCORING = 'project-scoring'
export const PROJECT_NEGOTIATION = 'project-negotiation'
export const PROJECT_APPROVAL = 'project-approval'
export const PROJECT_SKT_LOI = 'project-skt-loi'
export const PROJECT_SPP = 'project-spp'
export const ADD_VENDOR_CANDIDATE = 'add-vendor-candidate'
export const EDIT_RFP = 'edit-rfp'
export const QUOTATION_DETAILS = 'quotation-detail'
export const QUOTATION_NEGOTIATION = 'quotation-negotiation'
export const QUOTATION_APPROVAL = 'quotation-approval'
export const QUOTATION_LOI = 'quotation-loi'
export const QUOTATION_SPP = 'quotation-spp'
export const EDIT_RFQ = 'edit-rfq'

// path route
export const PATH_RFP = '/pengadaan-it/rfp'
export const PATH_DRAFT_RFP = '/pengadaan-it/rfp/:id'
export const PATH_PROJECT_DETAIL = '/pengadaan-it/project-detail/:projectId'
export const PATH_PROJECT_PRA_QUALIFICATION = '/pengadaan-it/project-pra-qualification/:projectId'
export const PATH_PROJECT_QUALIFICATION = '/pengadaan-it/project-qualification/:projectId'
export const PATH_PROJECT_SCORING = '/pengadaan-it/project-scoring/:projectId'
export const PATH_PROJECT_NEGOTIATION = '/pengadaan-it/project-negotiation/:projectId'
export const PATH_PROJECT_APPROVAL = '/pengadaan-it/project-approval/:projectId'
export const PATH_PROJECT_SKT_LOI = '/pengadaan-it/project-skt-loi/:projectId'
export const PATH_PROJECT_SPP = '/pengadaan-it/project-spp/:projectId'
export const PATH_ADD_VENDOR_CANDIDATE = '/pengadaan-it/add-vendor-candidate/:projectId'
export const PATH_EDIT_RFP = '/pengadaan-it/edit-rfp/:id'

export const PATH_RFQ = '/pengadaan-it/rfq'
export const PATH_DRAFT_RFQ = '/pengadaan-it/rfq/:quotationId'
export const PATH_QUOTATION_DETAILS = '/pengadaan-it/quotation-detail/:quotationId'
export const PATH_QUOTATION_NEGOTIATION = '/pengadaan-it/quotation-negotiation/:quotationId'
export const PATH_QUOTATION_APPROVAL = '/pengadaan-it/quotation-approval/:quotationId'
export const PATH_QUOTATION_LOI = '/pengadaan-it/quotation-loi/:quotationId'
export const PATH_QUOTATION_SPP = '/pengadaan-it/quotation-spp/:quotationId'
export const PATH_EDIT_RFQ = '/pengadaan-it/edit-rfq/:quotationId'

//router link navbar
export const INBOX = '/inbox'
export const PROFILE = '/profile'
export const NOTIF = '/notifikasi'
export const GISTA = '/'