<template>
    <div v-if="approvalPage && menuRfqApprovalChangeRequester?.canUpdate && (isSubmitted || isRejected) && !loiShow">
      <div class="w-full p-4">
        <div class="">
          <ButtonGista type="secondary"
              color="primary"
              @click="handleOpen"
              customClass="w-full">
              Change Requester</ButtonGista>
        </div>
      </div>
      <GistaModal :value="isOpen"
        customClass="max-w-2xl bg-white"
        @input="handleClose">
        <ValidationObserver v-slot="{ handleSubmit }">
          <div class="w-full p-5">
            <div class="flex justify-center w-full mb-6">
              <h5 class="text-lg font-semibold text-gray">Change Requester</h5>
            </div>
            <div class="w-full gap-6 mb-6">
              <InputDisabled label="Current Requester" class="mb-6" :value="logApproval?.userRequesterName" />
              <ValidationProvider name="newRequester"
                rules="required"
                v-slot="{ errors }">
                <AutocompleteNewSolutip
                  label="Change to:"
                  placeholder="(Search)"
                  :options="requesters"
                  @updateInput="inputSearchRequester($event)"
                  @updateAutoComplete="changeRequester($event)"
                  @blur="blurRequester"
                  @focus="focusRequester"
                  v-model="newRequesterNameLocalState"
                  :isLoading="isLoadingRequester"
                  :error="!!errors[0]"
                  textField="name"
                  iconLeft="icon-search text-xl text-gray-light"
                  mandatory
                >
                  <template #message>
                    <p class="mt-1 text-xs text-error">{{ errors[0] }}</p>
                  </template>
                </AutocompleteNewSolutip>
              </ValidationProvider>
              <ValidationProvider name="Reason" rules="required|max:300" v-slot="{errors}">
                <TextareaGista
                  label="Reason"
                  placeholder="Leave a comment here"
                  v-model="reason"
                  :error="!!errors[0]"
                  class="mt-6"
                  mandatory
                >
                  <template #message>
                    <p>{{errors[0]}}</p>
                  </template>
                </TextareaGista>
              </ValidationProvider>
            </div>
            <div class="flex justify-end">
              <ButtonGista type="primary"
                color="success"
                @click="handleSubmit(clickChangeRequester)"
                customClass="w-full sm:w-56">
                Change
              </ButtonGista>
            </div>
          </div>
        </ValidationObserver>
      </GistaModal>
    </div>
  </template>
  
<script>
    import AutocompleteNewSolutip from '@/core/components/custom/AutocompleteNew'
    import { MESSAGE_SUCCESS_CHANGE_REQUESTER } from '@/core/constant/successMessage'
    import { SKT_LOI_AWAITING_FOR_CREATION } from '@/core/constant/statusProject'
    import InputDisabled from '@/core/components/custom/InputDisabled.vue'

    export default {
    name: 'ApprovalChangeRequesterRfq',
    data() {
      return {
        isOpen: false,
        newRequesterNameLocalState: '',
        isLoadingRequester: false,
        newRequester: { name: '', userName: '' },
        reason: '',
        requesters: []
      }
    },
    computed: {
        menu() {
            return this.$store.state.dashboard.menu
        },
        modal: {
            get() {
            return this.$store.state.modal.modal
            },
            set(value) {
            this.$store.commit('modal/SET_MODAL', value)
            }
        },
        approvalPage() {
            return this.$route.path.includes('approval')
        },
        menuRfqApprovalChangeRequester() {
            return this.$store.getters['dashboard/menuRfqApprovalChangeRequester']
        },
        dataProcurementApproval() {
            return this.$store.state.procurementApprovalRfq.dataProcurementApproval
        },
        isSubmitted() {
            return this.dataProcurementApproval.isSubmitted
        },
        logApproval() {
            return this.$store.state.procurementApprovalRfq.logApproval
        },
        isRejected() {
          return this.dataProcurementApproval.isRejected
        },
        projectLogRfq() {
          return this.$store.state.projectLog.projectLogRfq
        },
        loiShow() {
          const statusProject = [ SKT_LOI_AWAITING_FOR_CREATION ]
          return this.projectLogRfq?.rfqLogs?.some(e => statusProject.includes(e.statusProject))
        },
    },
    methods: {
        async handleOpen() {
          this.isOpen = true
        },
        handleClose() {
          this.resetForm()
          this.isOpen = false
        },
        async getApprovalUserRequesterProcurement() {
          this.isLoadingRequester = true
          const res = await this.$store.dispatch('procurementApprovalRfq/getApprovalUserRequesterProcurement', { approvalId: this.dataProcurementApproval.id , project: 'RFQ', nameFilter: this.newRequesterNameLocalState })
          if (res?.status < 300) {
            this.requesters = res.data.result
          }
          this.isLoadingRequester = false
        },
        async inputSearchRequester() {
          this.getApprovalUserRequesterProcurement()
        },
        changeRequester(e) {
          this.newRequester = e
          this.newRequesterNameLocalState = e.name
        },
        blurRequester() {
          if (!this.newRequesterNameLocalState) {
            this.newRequester = { name: '', userName: '' }
          }
          this.newRequesterNameLocalState = this.newRequester.name
        },
        async focusRequester() {
          this.getApprovalUserRequesterProcurement()
        },
        resetForm() {
          this.newRequester = { name: '', userName: '' }
          this.newRequesterNameLocalState = ''
          this.reason = ''
        },
        async clickChangeRequester() {
          this.modal.modalLoading = true
          const res = await this.$store.dispatch('procurementApprovalRfq/putApprovalChangeRequester', { approvalId: this.dataProcurementApproval.id, newRequester: this.newRequester.userName, reason: this.reason })
          if (res?.status < 300) {
            this.modal.modalSuccessSaved = { value: true, title: MESSAGE_SUCCESS_CHANGE_REQUESTER }
            this.$store.dispatch('projectLog/refetchApprovalProcurementRfqLog', { rfqId: this.$route.params.quotationId })
            this.handleClose()
          }
          this.modal.modalLoading = false
        },
      },
      destroyed() {
        this.resetForm()
      },
      components: {
        AutocompleteNewSolutip,
        InputDisabled
      }
  };
  </script>