export default class Component {
  constructor() {
    this.name = "Component";

    this.components = {
      layout: [],
      base: [],
    };
  }

  register(componentProp, type = "common") {
    const name = componentProp.name;
    if (name === undefined) {
      throw new Error("Missing prop name in component");
    }

    this.components[type].push({
      name: name,
      component: componentProp,
    });

    return this.components;
  }

  getComponent(type, name) {
    return this.components[type].find((com) => com.name === name);
  }

  getComponents() {
    return this.components;
  }
}
