/* eslint-disable */
import { ENDPOINT_VENDOR_DETAILS_DOCUMENT_BY_VENDOR, ENDPOINT_VENDOR_DETAILS_DOCUMENT_BY_PROJECT, ENDPOINT_VENDOR_DETAILS_PROJECTS, 
  ENDPOINT_VENDORS_RFI_DETAIL_DOC_PROJECT_REF, ENDPOINT_VENDORS_RFI_DETAIL_DOC_VENDOR_UPLOAD, ENDPOINT_VENDORS_RFI_DETAIL_DOC_PROJECT_UPLOAD, ENDPOINT_RFP_VENDOR } from '@/core/constant/menuEndpoint'
export const Store = {
	namespaced: true,

	// nama module, untuk call di component menggunakan "example/<nama_method>"
	name: 'vendor',

	state: {
    vendorDocumentsDetail: {},
    vendorDocumentsDetailProject: [],
    vendorDetailProjectEliminated: [],
    vendorDetailProjectSelected: [],
    projectNameIdSearch: [],
    vendorListAll: []
	},

	getters: {},

	mutations: {
    SET_VENDOR_DOCUMENTS_DETAIL(state, payload) {
      state.vendorDocumentsDetail = payload
    },
    SET_VENDOR_DOCUMENTS_DETAIL_PROJECT(state, payload) {
      state.vendorDocumentsDetailProject = payload
    },
    SET_VENDOR_DETAIL_PROJECT_ELIMINATED(state, payload) {
      state.vendorDetailProjectEliminated = payload
    },
    SET_VENDOR_DETAIL_PROJECT_SELECTED(state, payload) {
      state.vendorDetailProjectSelected = payload
    },
    SET_PROJECT_NAME_ID_SEARCH(state, payload) {
      state.projectNameIdSearch = payload
    },
    SET_VENDOR_LIST_ALL(state, payload) {
      state.vendorListAll = payload
    }
	},

	actions: {
    //GET
    async getVendorDocumentsDetail({ commit, dispatch,}, payload) {
      try {
        const vendorId = payload
        const response = await this.$api.get(ENDPOINT_VENDOR_DETAILS_DOCUMENT_BY_VENDOR, { vendorId })
        commit('SET_VENDOR_DOCUMENTS_DETAIL', response.data.result)
        return response
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async getVendorDocumentsDetailProject({ commit, dispatch,}, payload) {
      try {
        const { vendorId, projectId } = payload
        const response = await this.$api.get(ENDPOINT_VENDOR_DETAILS_DOCUMENT_BY_PROJECT, { vendorId, projectId })
        commit('SET_VENDOR_DOCUMENTS_DETAIL_PROJECT', response.data.result)
        return response
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },

    async getVendorDetailProjectEliminated({ commit, dispatch, }, payload) {
      try {
        const { vendorId } = payload
        const response1 = await this.$api.get(ENDPOINT_VENDOR_DETAILS_PROJECTS, { vendorId, isEliminated: true })
        const response2 = await this.$api.get(ENDPOINT_VENDOR_DETAILS_PROJECTS, { vendorId, isEliminated: false })
        commit('SET_VENDOR_DETAIL_PROJECT_ELIMINATED', response1.data.result)
        commit('SET_VENDOR_DETAIL_PROJECT_SELECTED', response2.data.result)
        return response1
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },

    async getProjectNameIDSearch({ commit, dispatch }, payload) {
      try {
        const { vendorId, search } = payload
        const response = await this.$api.get(ENDPOINT_VENDORS_RFI_DETAIL_DOC_PROJECT_REF, { vendorId, search })
        commit('SET_PROJECT_NAME_ID_SEARCH', response.data.result)
        return response
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    
    //for list vendor all
    async getVendor({ commit, dispatch }, payload) {
      try {
        const response = await this.$api.get(ENDPOINT_RFP_VENDOR, { vendorName: '' })
        commit('SET_VENDOR_LIST_ALL', response.data.result)
        return response
      } catch (error) {
        commit('SET_VENDOR_LIST_ALL', [])
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
		},

    async postVendorDocumentsUpload({ commit, dispatch, }, payload) {
      try {
        return await this.$api.post(ENDPOINT_VENDORS_RFI_DETAIL_DOC_VENDOR_UPLOAD, payload)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },

    async postVendorDocumentsUploadProject({ commit, dispatch,}, payload) {
      try {
        return await this.$api.post(ENDPOINT_VENDORS_RFI_DETAIL_DOC_PROJECT_UPLOAD, payload)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    }
    
	}
}