export const CANDIDATE = 'CANDIDATE'
export const QUALIFICATION_ELIMINATED = 'QUALIFICATION_ELIMINATED'
export const PRA_QUALIFICATION_ELIMINATED = 'PRA_QUALIFICATION_ELIMINATED'
export const RFP_SENT = 'RFP_SENT'
export const PROPOSAL_REJECTED = 'PROPOSAL_REJECTED'
export const PROPOSAL_UPLOADED = 'PROPOSAL_UPLOADED'
export const PROPOSAL_APPROVED = 'PROPOSAL_APPROVED'
export const PROPOSAL_WAITING_CHANGES = 'PROPOSAL_WAITING_CHANGES'
export const PROPOSAL_SENT = 'PROPOSAL_SENT'
export const SCORING_DECISION_ELIMINATED = 'SCORING_DECISION_ELIMINATED'
export const SCORING_DECISION_NEGOTIATION = 'SCORING_DECISION_NEGOTIATION'
export const SCORING_DECISION_APPROVAL = 'SCORING_DECISION_APPROVAL'
export const SCORING_TECH_APPROVED = 'SCORING_TECH_APPROVED'
export const APPROVAL_SELECTED = 'APPROVAL_SELECTED'
export const APPROVAL_ELIMINATED = 'APPROVAL_ELIMINATED'
