<template>
	<div v-if="currentApprover && scoringPage" class="w-full">
		<div class="w-full p-4">
      <p class="text-sm font-normal text-gray">Scoring Technical & Escrow Approval</p>
      <div class="flex flex-wrap mt-1 mb-6 text-sm font-medium text-warning-dark">Please review all tabs to be able to approve or reject</div>
      <div class="flex flex-col justify-between gap-6 sm:flex-row">
        <ButtonGista 
          type="secondary"
          color="error"
          :isDark="false"
          @click="toggle"
          :disabled="disabledApproval"
          customClass="w-full">
          Reject
        </ButtonGista>
        <ButtonGista 
          type="primary"
          color="success"
          @click="toggleApprove"
          :disabled="disabledApproval"
          class="w-full">
          Approve</ButtonGista>
      </div>
		</div>
		<GistaModal 
      :value="isOpen"
      customClass="max-w-2xl bg-white"
			@input="toggle">
			<ValidationObserver v-slot="{ handleSubmit }">
        <div class="w-full p-5">
          <div class="flex justify-center w-full">
            <h5 class="mb-6 text-xl font-semibold text-gray">Tolak Scoring Technical & Escrow</h5>
          </div>
          <div class="flex-wrap w-full mb-4">
            <ValidationProvider name="Reason" rules="required|max:500" v-slot="{errors}">
              <TextareaGista
                label="Reason"
                placeholder="Leave a comment here"
                v-model="reason"
                :error="!!errors[0]"
                mandatory
              >
                <template #message>
                  <p>{{errors[0]}}</p>
                </template>
              </TextareaGista>
            </ValidationProvider>

          </div>
          <div class="flex justify-center">
            <ButtonGista 
              type="primary"
              color="error"
              @click="handleSubmit(clickReject)"
              customClass="w-full sm:w-60">
              Reject
            </ButtonGista>

          </div>
        </div>
      </ValidationObserver>
		</GistaModal>
		<GistaModal 
      :value="isOpen2"
      customClass="max-w-2xl bg-white"
			@input="toggleApprove">
      <ValidationObserver v-slot="{ handleSubmit }">
        <div class="w-full p-5">
          <div class="w-full mb-1">
            <h4 class="mb-6 text-xl font-medium text-center">Setujui Scoring Technical & Scoring</h4>
            <div class="flex-wrap w-full mb-4">
              <ValidationProvider name="Reason" rules="max:500" v-slot="{errors}">
                <TextareaGista
                  label="Reason"
                  placeholder="Leave a comment here"
                  v-model="reason"
                  :error="!!errors[0]"
                >
                  <template #message>
                    <p>{{errors[0]}}</p>
                  </template>
                </TextareaGista>
              </ValidationProvider>

            </div>
          </div>
          <div class="flex justify-center">
            <ButtonGista 
              color="success"
              customClass="w-full sm:w-56"
              @click="handleSubmit(clickApprove)"
              >
              Approve
            </ButtonGista>

          </div>
        </div>
      </ValidationObserver>
		</GistaModal>
	</div>
</template>
<script>
import { MESSAGE_SUCCESS_REJECT_SCORING, MESSAGE_SUCCESS_APPROVE_SCORING } from '@/core/constant/successMessage'
export default {
	name: "ApprovalScoring",
	data() {
		return {
			isOpen: false,
			isOpen2: false,
      reason: ''
		}
	},
  computed: {
    modal: {
			get() {
				return this.$store.state.modal.modal
			},
			set(value) {
				this.$store.commit('modal/SET_MODAL', value)
			}
		},
    scoringVendorApproval() {
      return this.$store.state.projectDetail.scoringVendorApproval
    },
    approvalGsit() {
      return this.scoringVendorApproval?.find(e => e.unitKerja === 'GSIT')
    },
    approvalUkkp() {
      return this.scoringVendorApproval?.find(e => e.unitKerja === 'UKKP')
    },
    approvalDlog() {
      return this.scoringVendorApproval?.find(e => e.unitKerja === 'ITP')
    },
    userDetail() {
			return this.$store.state.dashboard.userProfile;
		},
    currentApprover() {
      if (this.approvalGsit?.approvalStatusLocale?.toLowerCase() === 'on review') {
        return this.userDetail.username.toLowerCase() === this.approvalGsit.userId
      } else if (this.approvalUkkp?.approvalStatusLocale?.toLowerCase() === 'on review') {
        return this.userDetail.username.toLowerCase() === this.approvalUkkp?.userId
      } else if (this.approvalDlog?.approvalStatusLocale?.toLowerCase() === 'on review') {
        return this.userDetail.username.toLowerCase() === this.approvalDlog?.userId
      } else {
        return false
      }
    },
    disabledApproval() {
      return this.approvalGsit?.needReviewTechScoring || this.approvalGsit?.needReviewEscrowScoring || this.approvalGsit?.needReviewDecisionScoring
        || this.approvalUkkp?.needReviewTechScoring || this.approvalUkkp?.needReviewEscrowScoring || this.approvalUkkp?.needReviewDecisionScoring
        || this.approvalDlog?.needReviewTechScoring || this.approvalDlog?.needReviewEscrowScoring || this.approvalDlog?.needReviewDecisionScoring
    },
    scoringPage() {
      return this.$route.path.includes('scoring')
    },
  },
	methods: {
    async clickReject() {
      try {
        this.modal.modalLoading = true
        const response = await this.$store.dispatch('projectDetail/putScoringReject', { rfpId: this.$route.params.projectId, notes: this.reason })
        if (response?.status < 300 && response?.status >= 200) {
          this.modal.modalSuccessApproved = { value: true, title: MESSAGE_SUCCESS_REJECT_SCORING }
          this.modal.modalLoading = false
          this.toggle()
          this.$store.dispatch('projectDetail/refetchScoringVendorApproval', { rfpId: this.$route.params.projectId })
          this.$emit('refetch')
				} else {
          this.$store.dispatch('modal/throwError', { response })
        }
      } catch (error) {
        this.modal.modalLoading = false
				this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
      }
    },
    async clickApprove() {
      try {
        this.modal.modalLoading = true
        const response = await this.$store.dispatch('projectDetail/putScoringApprove', { rfpId: this.$route.params.projectId, notes: this.reason })
        if (response?.status < 300 && response?.status >= 200) {
          this.modal.modalSuccessApproved = { value: true, title: MESSAGE_SUCCESS_APPROVE_SCORING }
          this.modal.modalLoading = false
          this.toggleApprove()
          this.$store.dispatch('projectDetail/refetchScoringVendorApproval', { rfpId: this.$route.params.projectId })
          this.$emit('refetch')
        } else {
          this.$store.dispatch('modal/throwError', { response })
        }
      } catch (error) {
        this.modal.modalLoading = false
				this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
      }
    },
		toggle() {
			this.isOpen = !this.isOpen
      this.reason = ''
		},
		toggleApprove() {
			this.isOpen2 = !this.isOpen2
      this.reason = ''
		},
	},
}
</script>

<style scoped>
.width-button-submit {
  max-width: 40rem;
}
</style>