<template>
  <div>
    <div class="animate-pulse pl-8">
      <div v-for="n in 5" :key="n" class="border-b border-gray-lightest w-full py-2.5 mb-3">
        <div class="h-4 rounded-md bg-gray-lightest w-full mb-3"></div>
        <div class="h-4 rounded-md bg-gray-lightest w-full mb-3"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingProjectLog'
}
</script>