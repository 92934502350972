/* eslint-disable */

export const Store = {
  namespaced: true,
  name: 'modal',
  state: {
    modal: {
      //modal success
      modalSuccessSubmitted: { value: false, title: '' },
      modalSuccessSaved: { value: false, title: '' },
      modalSuccessApproved: { value: false, title: '' },

      //modal error
      modalError: { value: false, title: '', message: '', traceid: '' },
      modalErrorForbidden: { value: false, title: '', message: '' },
      modalErrorValidate: { value: false, message: '' },

      //modal others
      modalVendorDetail: false,
      modalLoading: false,

    },
    //loading state
    loading: {
      loadingProjectLog: false,
      loadingDashboard: false,
      loadingApprovedBan: false,
      loadingApprovedScoring: false,
      loadingApprovedBanRfq: false,
      loadingApprovalProcurement: false,
      loadingSktLoi: false,
      loadingLoi: false,
      loadingSpp: false
    }
  },
  gettters: {

  },
  mutations: {
    SET_MODAL(state, payload) {
      state.modal = payload
    },
    SET_LOADING(state, payload) {
      state.loading = payload
    }
  },
  actions: {
    throwError({ commit, state, }, payload) {
      const { response } = payload
      const SERVER_ERROR = 500
      if (response?.status > SERVER_ERROR) {
        throw new Error(response?.statusText)
      }
      let err = new Error(response.data.message)
      err.traceid = response.headers['x-b3-traceid']
      err.title = response.data.description
      throw err
    },
    commitErrorModal({ commit, state }, payload) {
      const { response } = payload
      const SERVER_ERROR = 500
      if (response?.status > SERVER_ERROR) {
        commit('SET_MODAL', { ...state.modal, modalError: { value: true, title: '', message: response?.statusText } })
      } else {
        commit('SET_MODAL', { ...state.modal, modalError: { value: true, title: response.data.description, message: response.data.message, traceid: response.headers['x-b3-traceid'] } })
      }
    },
  }
  
}