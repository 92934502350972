<template>
  <div>
    <gista-modal
      width="1/2"
      :value="isOpen"
      :hideClose="false"
      customClass="max-w-2xl bg-white"
      :scrollable="true"
      @input="closeModal"
    >
      <div class="flex flex-col w-full p-6">
        <div class="flex flex-col justify-center flex-auto">
          <div class="flex justify-center mb-6">
            <p class="text-xl font-semibold text-gray">SPP</p>
          </div>
          <div class="flex justify-center mb-6">
            <p class="text-sm text-center whitespace-pre-wrap text-gray">{{ detailProject?.message }}</p>
          </div>
          <div class="mb-6">
            <div>
              <p class="mb-1 text-sm font-medium text-gray">Cancelation Request</p>
            </div>
            <div class="flex items-center justify-between border rounded-lg h-14 border-primary">
              <div class="p-4 text-base font-medium truncate text-gray">{{ detailProject?.attachment?.fileName }}</div>
              <div class="flex items-center p-4">
                <span class="text-2xl cursor-pointer icon-eye text-primary hover:text-primary-dark" @click="openFile(file)" ></span>
              </div>
            </div>
        </div>
        </div>
      </div>
    </gista-modal>
  </div>
</template>

<script>
export default {
  name: "SppOutstanding",
  props: ['isOpen', 'detailProject'],
  data() {
    return {
    }
  },
  computed: {
    modal: {
      get() {
        return this.$store.state.modal.modal
      },
      set(value) {
        this.$store.commit('modal/SET_MODAL', value)
      }
    }
  },
  methods: {
    closeModal() {
      this.$emit('toggleModal')
    },
    openFile() {
			const fileName = this.detailProject?.attachment?.fileName
      const documentNo = this.detailProject?.attachment?.documentNo
      this.$store.dispatch('file/viewAttachment', { filename: fileName, documentNo })
    },
  },
}
</script>