/* eslint-disable */
//experimental to divide store rfp

export const Store = {
  namespaced: true,
  name: 'errorRfq',
  state: {
    error: {
      projectName: { value: false, message: ''},
      judulRfq: { value: false, message: '' },
      notes: { value: false, message: '' },
    },
    errorRequiredItemList: [
      {
        'Jenis': false,
        'Product Layanan': false,
        'Qty': false,
        'UoM': false,
      }
    ],
  },
  gettters: {

  },
  mutations: {
    SET_ERROR(state, payload) {
      state.error = payload
    },
    SET_ERROR_ITEM_LIST(state, payload) {
      state.errorRequiredItemList = payload
    }
  },
  actions: {
    resetState({commit, state}) {
      const errorRequiredItemList = [
        {
          'Jenis': false,
          'Product Layanan': false,
          'Qty': false,
          'UoM': false,
        }
      ]
      commit('SET_ERROR_ITEM_LIST', errorRequiredItemList)
    }
  }
  
}