<template>
	<div v-if="modal.modalLoading">
		<LoadingSolutip :label="label" />
	</div>
</template>

<script>
import LoadingSolutip from '@/core/components/custom/Loading'
export default {
	name: "ModalLoading",
	data() {
		return {
			label: 'Loading...',
		}
	},
	computed: {
		modal: {
      get() {
        return this.$store.state.modal.modal
      },
      set(value) {
        this.$store.commit('modal/SET_MODAL', value)
      }
    }
	},
	methods: {
		click() {
			this.$emit("click")
			this.modal.modalLoading = false
		},
		closeModal() {
			this.modal.modalLoading = false
		}
	},
	components: {
		LoadingSolutip
	}
}
</script>