<template>
  <div v-if="sktLoiPage && sktLoiShow && menuRfpSktLoi?.canUpdate && isRejected && mainPage !== EDIT && requester">
    <div class="w-full p-4">
      <div class="">
        <ButtonGista 
          color="secondary"
          @click="clickEdit"
          customClass="w-full">
          Edit</ButtonGista>
      </div>
    </div>
  </div>
</template>

<script>
import { SKT_LOI_AWAITING_FOR_CREATION } from '@/core/constant/statusProject'
import { EDIT } from '@/core/constant/procurementApproval.js'

export default {
  name: "EditSktLoi",
  data() {
    return {
      EDIT
    }
  },
  computed: {
    menuRfpSktLoi() {
			return this.$store.getters['dashboard/menuRfpSktLoi']
		},
    projectLogRfp() {
      return this.$store.state.projectLog.projectLogRfp
    },
    sktLoiShow() {
      const statusProject = [ SKT_LOI_AWAITING_FOR_CREATION ]
      return this.projectLogRfp?.rfpLogs?.some(e => statusProject.includes(e.statusProject))
    },
    sktLoiPage() {
      return this.$route.path.includes('skt-loi')
    },
    mainPage() {
      return this.$store.state.sktLoi.mainPage
    },
    draftSktLoi: {
      get() {
        return this.$store.state.sktLoi.draftSktLoi
      },
      set(value) {
        this.$store.commit('sktLoi/SET_DRAFT_SKT_LOI', value)
      }
    },
    userDetail() {
      return this.$store.state.dashboard.userProfile;
    },
    requester() {
      return this.draftSktLoi.requester?.toLowerCase() === this.userDetail.username?.toLowerCase()
    },
    isRejected() {
      return this.draftSktLoi.approvals?.some(e => e.approvalStatus === 'REJECT')
    },
  },
  methods: {
    clickEdit() {
      this.$store.commit('sktLoi/SET_MAIN_PAGE', EDIT)
    }
  }
}

</script>