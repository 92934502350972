<template>
  <div v-if="loiPage && loiShow && menuRfqLoi?.canUpdate && isRejected && mainPage !== EDIT && requester">
    <div class="w-full p-4">
      <div class="">
        <ButtonGista 
          color="secondary"
          @click="clickEdit"
          customClass="w-full">
          Edit</ButtonGista>
      </div>
    </div>
  </div>
</template>

<script>
import { EDIT } from '@/core/constant/procurementApproval.js'
import { LOI_AWAITING_FOR_CREATION } from '@/core/constant/statusProject'

export default {
  name: "RfqEditLoi",
  data() {
    return {
      EDIT
    }
  },
  computed: {
    menuRfqLoi() {
			return this.$store.getters['dashboard/menuRfqLoi']
		},
    loiPage() {
      return this.$route.path.includes('loi')
    },
    mainPage() {
      return this.$store.state.loiRfq.mainPage
    },
    draftLoi: {
      get() {
        return this.$store.state.loiRfq.draftLoi
      },
      set(value) {
        this.$store.commit('loiRfq/SET_DRAFT_LOI', value)
      }
    },
    projectLogRfq() {
      return this.$store.state.projectLog.projectLogRfq
    },
    loiShow() {
      const statusProject = [ LOI_AWAITING_FOR_CREATION ]
      return this.projectLogRfq?.rfqLogs?.some(e => statusProject.includes(e.statusProject))
    },
    userDetail() {
      return this.$store.state.dashboard.userProfile;
    },
    requester() {
      return this.draftLoi.requester?.toLowerCase() === this.userDetail.username?.toLowerCase()
    },
    isRejected() {
      return this.draftLoi.approvals?.some(e => e.approvalStatus === 'REJECT')
    },
  },
  methods: {
    clickEdit() {
      this.$store.commit('loiRfq/SET_MAIN_PAGE', EDIT)
    }
  }
}

</script>