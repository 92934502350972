<template>
	<div class="w-full">
		<div class="w-full break-words">
			<div class="flex justify-between w-full">
				<div class="w-full p-4" v-if="rejectStatus">
					<ButtonGista 
						type="secondary"
						color="success"
						@click="editProject"
						class="w-full">
						Edit Project</ButtonGista>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { PATH_ROOT } from '@/core/constant/routeName'
import { GSIT_01_AWAITING_REVISION, UKKP_01_AWAITING_REVISION, SPP_PROCESS } from '@/core/constant/statusProject'

export default {
	name: "CancelEditRfp",
	computed: {
		projectLogRfp() {
			return this.$store.state.projectLog.projectLogRfp
		},
		rejectStatus() {
			const status = [ GSIT_01_AWAITING_REVISION, UKKP_01_AWAITING_REVISION ]
			const rfpLogs = this.projectLogRfp?.rfpLogs
			const logsLength = rfpLogs.length
			if (rfpLogs.length > 0) {
				if(rfpLogs[logsLength - 1]?.statusProject === SPP_PROCESS) return status.includes(rfpLogs[logsLength - 2]?.statusProject)
				return status.includes(rfpLogs[logsLength - 1]?.statusProject)
			}
			return false
		},
	},
	methods: {
		editProject() {
			this.$router.push(`/${PATH_ROOT}/edit-rfp/${this.$route.params.projectId}`).catch(() => ({}))
		},
	},
}
</script>