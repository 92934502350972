/* eslint-disable */
export const Store = {
    namespaced: true,

    // nama module, untuk call di component menggunakan "example/<nama_method>"
    name: 'example',

    state: {},

    getters: {},

    mutations: {},

    actions: {
        /* Start of : Contoh pemanggilan API */
        async exampleAPICall({ commit }, payload) {
            const response = await this.$api.get(`master/cabang`, payload);
            return response.data.data;
        },
        /* End of : Contoh pemanggilan API */
    }
}