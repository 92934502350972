/* eslint-disable */
import { ENDPOINT_DETAILS_REJECT, ENDPOINT_DETAILS_APPROVE } from '@/core/constant/menuEndpoint'
export const Store = {
  namespaced: true,
  name: 'approval',
  state: {
  },
  mutations: {
  },
  actions: {
    async putProposalInformationReject({commit, dispatch}, payload) {
      try {
        const { rfpId, reason } = payload
        return await this.$api.put(`${ENDPOINT_DETAILS_REJECT}/${rfpId}`, { reason })
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async putProposalInformationApprove({commit, dispatch}, payload) {
      try {
        const { rfpId, reason } = payload
        return await this.$api.put(`${ENDPOINT_DETAILS_APPROVE}/${rfpId}`, { reason } )
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
  }
  
}