<template>
	<div class="w-full">
		<div class="w-full p-4">
      <div class="flex flex-col justify-between gap-6 sm:flex-row">
        <ButtonGista 
          v-if="menuReject?.canUpdate"
          type="secondary"
          color="error"
          :isDark="false"
          @click="toggle"
          customClass="w-full">
          Reject RFP
        </ButtonGista>
        <ButtonGista 
          v-if="menuApprove?.canUpdate"
          :type="`${roleGsitUkkp?'secondary':'primary'}`"
          color="success"
          @click="toggleApprove"
          class="w-full">
          Approve RFP</ButtonGista>
      </div>
		</div>
		<GistaModal 
      :value="isOpen"
      customClass="max-w-2xl bg-white"
			@input="toggle">
			<ValidationObserver v-slot="{ handleSubmit }">
        <div class="w-full p-5">
          <div class="flex justify-center w-full">
            <h5 class="text-lg font-semibold text-gray">Tolak RFP</h5>
          </div>
          <div class="flex-wrap w-full mb-4">
            <ValidationProvider name="Reason" rules="required|max:500" v-slot="{errors}">
              <TextareaGista
                label="Reason"
                placeholder="Leave a comment here"
                v-model="reasonReject"
                :error="!!errors[0]"
                mandatory
              >
                <template #message>
                  <p>{{errors[0]}}</p>
                </template>
              </TextareaGista>
            </ValidationProvider>

          </div>
          <div class="flex justify-center">
            <ButtonGista 
              v-if="menuReject?.canUpdate"
              type="primary"
              color="error"
              @click="handleSubmit(clickReject)"
              customClass="w-full sm:w-56">
              Reject
            </ButtonGista>

          </div>
        </div>
      </ValidationObserver>
		</GistaModal>
		<GistaModal 
      :value="isOpen2"
      customClass="max-w-2xl bg-white"
			@input="toggleApprove">
      <ValidationObserver v-slot="{ handleSubmit }">
        <div class="w-full p-5">
          <div class="flex flex-col items-center w-full">
            <h5 class="text-lg font-semibold text-gray mb-3.5">Setujui</h5>
          </div>
          <div class="flex-wrap w-full mb-4">
            <ValidationProvider name="Reason" rules="max:500" v-slot="{errors}">
              <TextareaGista
                label="Reason"
                placeholder="Leave a comment here"
                v-model="reasonApprove"
                :error="!!errors[0]"
              >
                <template #message>
                  <p>{{errors[0]}}</p>
                </template>
              </TextareaGista>
            </ValidationProvider>
          </div>
          <div class="flex justify-center">
            <ButtonGista 
              v-if="menuApprove?.canUpdate"
              color="success"
              @click="handleSubmit(clickApprove)"
              customClass="w-full sm:w-56">
              Approve
            </ButtonGista>
          </div>
        </div>
      </ValidationObserver>
		</GistaModal>
	</div>
</template>
<script>
import { PATH_ROOT } from '@/core/constant/routeName'
import { MESSAGE_SUCCESS_APPROVE_RFP, MESSAGE_SUCCESS_REJECT_RFP } from '@/core/constant/successMessage'
export default {
	name: "ApprovalRfp",
	data() {
		return {
			isOpen: false,
			isOpen2: false,
      reasonApprove: '',
      reasonReject: '',
		}
	},
  props: ['roleGsitUkkp'],
  computed: {
    modal: {
			get() {
				return this.$store.state.modal.modal
			},
			set(value) {
				this.$store.commit('modal/SET_MODAL', value)
			}
		},
    menuApprove() {
			return this.$store.getters['dashboard/menuDetailsApprove']
    },
    menuReject() {
			return this.$store.getters['dashboard/menuDetailsReject']
    },
    proposalInformation() {
      return this.$store.state.projectDetail.proposalInformation
    },
  },
	methods: {
    async clickReject() {
      try {
				this.modal.modalLoading = true
				const response = await this.$store.dispatch('approval/putProposalInformationReject', { rfpId: this.$route.params.projectId, reason: this.reasonReject })
				console.log(response, 'response reject');
				if (response?.status < 300 && response?.status >= 200) {
          this.toggle()
          this.modal.modalLoading = false
          this.modal.modalSuccessApproved = { value: true, title: MESSAGE_SUCCESS_REJECT_RFP }
          const query = await this.$store.dispatch('dashboard/setQueryBacktoHome')
          this.$store.commit('projectLog/SET_PROJECT_ID', this.proposalInformation.projectId)
          this.$router.push({ path: `/${PATH_ROOT}`, query }).catch(() => ({}))
          this.$emit('refetchProjectList')
        } else {
          this.$store.dispatch('modal/throwError', { response })
        }
			} catch (error) {
				console.log(error);
				this.modal.modalLoading = false
				this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
			}
    },
    async clickApprove() {
      try {
				this.modal.modalLoading = true
				const response = await this.$store.dispatch('approval/putProposalInformationApprove', { rfpId: this.$route.params.projectId, reason: this.reasonApprove })
				console.log(response, 'response submit');
				if (response?.status < 300 && response?.status >= 200) {
          this.toggleApprove()
          this.modal.modalLoading = false
          this.modal.modalSuccessApproved = { value: true, title: MESSAGE_SUCCESS_APPROVE_RFP }
          const query = await this.$store.dispatch('dashboard/setQueryBacktoHome')
          this.$store.commit('projectLog/SET_PROJECT_ID', this.proposalInformation.projectId)
          this.$router.push({ path: `/${PATH_ROOT}`, query }).catch(() => ({}))
          this.$emit('refetchProjectList')
        } else {
          this.$store.dispatch('modal/throwError', { response })
        }
			} catch (error) {
				this.modal.modalLoading = false
				this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
			}
    },
		toggle() {
			this.isOpen = !this.isOpen
      this.reasonReject = ''
		},
		toggleApprove() {
			this.isOpen2 = !this.isOpen2
      this.reasonApprove = ''
		},
	},
}
</script>

<style scoped>
.width-button-submit {
  max-width: 40rem;
}
</style>