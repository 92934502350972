/* eslint-disable */
import { ENDPOINT_KARYAWAN_NEGOTIATOR, ENDPOINT_RFQ_NEGOTIATION, ENDPOINT_RFQ_NEGOTIATION_FPTN_APPROVE, ENDPOINT_RFQ_NEGOTIATION_FPTN_REJECT,
  ENDPOINT_RFQ_NEGOTIATION_BAN_APPROVE, ENDPOINT_RFQ_NEGOTIATION_BAN_REJECT, ENDPOINT_RFQ_NEGOTIATION_OFFER_UPLOAD, ENDPOINT_RFQ_NEGOTIATION_OFFER_SAVE,
  ENDPOINT_RFQ_NEGOTIATION_OFFER_DELETE, ENDPOINT_RFQ_NEGOTIATION_REQUIRE, ENDPOINT_RFQ_NEGOTIATION_SCHEDULE_CONFIRM, ENDPOINT_RFQ_NEGOTIATION_OFFER_REVISION_SEND_EMAIL,
  ENDPOINT_RFQ_NEGOTIATION_OFFER_DEAL, ENDPOINT_RFQ_NEGOTIATION_VERIFICATOR_CHANGE, ENDPOINT_RFQ_NEGOTIATION_FPTN_SUBMIT, ENDPOINT_RFQ_NEGOTIATION_OFFER_BAN,
  ENDPOINT_RFQ_NEGOTIATION_OFFER_REVISION, ENDPOINT_RFQ_NEGOTIATION_FPTN, ENDPOINT_RFQ_NEGOTIATION_SUMMARY_WITH, ENDPOINT_RFQ_NEGOTIATION_OFFER_LAST,
  ENDPOINT_RFQ_NEGOTIATION_OFFER_GET, ENDPOINT_RFQ_NEGOTIATION_FPTN_REPORT, ENDPOINT_RFQ_NEGOTIATION_FPTN_PREVIEW, ENDPOINT_RFQ_NEGOTIATION_BAN_REPORT, ENDPOINT_RFQ_NEGOTIATION_BAN_PREVIEW,
  ENDPOINT_RFQ_NEGOTIATION_RENEGOTIATION, ENDPOINT_USER_APPROVER_NEGOTIATION, ENDPOINT_RFQ_NEGOTIATION_BAN_FPTN, ENDPOINT_RFQ_NEGOTIATION_OFFER_APPROVER, ENDPOINT_RFQ_NEGOTIATION_BAN_REPORT_MENU, 
  ENDPOINT_RFQ_NEGOTIATION_BAN_REPORT_XLSX, ENDPOINT_RFQ_NEGOTIATION_OFFER_DEAL_SUBMIT, ENDPOINT_RFQ_NEGOTIATION_FPTN_VERIFICATOR_CHANGE, ENDPOINT_MASTERDATA_TAX
} from '@/core/constant/menuEndpoint'

export const Store = {
  namespaced: true,
  name: 'negotiationRfq',
  state: {
    negotiationShow:null,
    negotiationDetailBanId: "",
    showReviseOffer: false,
    showDealBan: false,
    showFPTN: false,
    showDetailFPTN: false,
    showDetailBan: false,
    showDetailOffer: false,
    negotiationList: [],
    modalUploadOffer: false,
    negotiationDetail: {},
    showEditOffer: false,
    masterTax:[],
    exportMenu: {},
    negotiator: [],
    approverNegotiation: [],
    negotiationOfferItems:[],
    summaryWith:{},
    negotiationOfferLast: {},
    negotiationBan:[],

    negotiationFPTN: {
      rfqFptnId:"",
      rfqNegotiationId:"",
      projectId:"",
      requestNo:"",
      referenceDoc:"",
      vendorName:"",
      projectName:"",
      procurementType:"",
      projectType:"",
      offerNo:"",
      offerDate:"",
      createdAt:"",
      paymentTerm:"",
      projectTerm:"",
      jangkaWaktuProyek:"",
      isWaiverBg:"",
      internalNote:"",
      itpVerificatorId:"",
      itpVerificatorName:"",
      representUkkpId:"",
      representUkkpName:"",
      representGsitId:"",
      representGsitName:"",
      directApproverItpId:"",
      directApproverItpName:"",
      directApproverGsitId:"",
      directApproverGsitName:"",
      directApproverUkkpId:"",
      directApproverUkkpName:"",
      isDeleted:"",
      fptnStatus:"",
      attachments:[],
    },
    banDetails: {
     rfqBanId:"",
     rfqNegotiationId:"",
     summaryRfqNegotiationId:"",
     projectId:"",
     requestNo:"",
     vendorName:"",
     projectName:"",
     budgetSpent:"",
     requestById:"",
     requestByName:"",
     offerNo:"",
     offerDate:"",
     picGsit:"",
     picUkkp:"",
     createdByName:"",
     projectType:[],
     meeting:"",
     meetingDate:"",
     timeStart:"",
     timeEnd:"",
     location:"",
     viaOnline:"",

     userIdNegotiator1:"",
     nameNegotiator1:"",
     userIdNegotiator2:"",
     nameNegotiator2:"",

     negotiationParticipant:[],
     termOfPayment:"",
     isWaiverBg:"",
     negotiationNote:"",
     internalNote:"",
     proofOfMeetingDocumentNo:"",
     proofOfMeetingFileName:"",
     proofOfMeetingFilePath:"",

     itpVerificatorId:"",
     itpVerificatorName:"",
     directApproverItpId:"",
     directApproverItpName:"",

     attachments:[],
    },
     negotiationOfferTable: {
      items: [],
      finalTotal: null,
      ppn: null,
      ppnPrice: null,
      totalPrice: null,
    },
    negotiationOfferTableUpload: {
      items: [],
      finalTotal: null,
      ppn: null,
      ppnPrice: null,
      totalPrice: null,
    },
    formReviseOffer: {
      rfqBanId: null,
      rfqNegotiationId: null,
      summaryRfqNegotiationId: null,
      projectId: "",
      requestNo: "",
      vendorName: "",
      projectName: "",
      budgetSpent: null,
      requestById: "",
      requestByName: "",
      offerNo: "",
      offerDate: "",
      picGsit: "",
      picUkkp: "",
      createdByName: "",
      projectType: [],
      meeting: null,
      banDate: null,
      timeStart: null,
      timeEnd: null,
      location: null,
      viaOnline: null,
      userIdNegotiator1: null,
      nameNegotiator1: null,
      userIdNegotiator2: null,
      nameNegotiator2: null,
      negotiationParticipant: [],
      termOfPayment: null,
      isWaiverBg: null,
      negotiationNote: null,
      internalNote: null,
      proofOfMeetingDocumentNo: null,
      proofOfMeetingFileName: null,
      proofOfMeetingFilePath: null,
      deletedProofOfNegotiationDocumentNoList: [],
      itpVerificatorId: null,
      itpVerificatorName: null,

      directApproverItpId:"",
      directApproverItpName:"",

      directApproverGsitId:"",
      directApproverGsitName:"",
      directApproverUkkpId:"",
      directApproverUkkpName:"",

      representGsitId:"",
      representGsitName:"",
      representUkkpId:"",
      representUkkpName:"",

      attachments: []
      
    },
    banNegotiationId: 0,
    banVendorSequence: 0,
    detailRFQNegoApprover: {},
    detailRFQNegoApproverInitial: {},
    showEditFptn: false,
    showEditBan: false,
    negotiationOfferGet: {},
    showVendorDetails: false,
    counterInterval: null,
    exportNegotiation: {},
    selectedIdxOffer: ''
  },
  gettters: {

  },
  mutations: {
    SET_NEGOTIATION_SHOW(state, payload){
      state.negotiationShow = payload
    },
    SET_NEGOTIATION_DETAIL_ID(state, payload){
      state.negotiationDetailBanId = payload
    },
    SET_SHOW_REVISE_OFFER(state, payload) {
      state.showReviseOffer = payload
    },
    SET_SHOW_DEAL_BAN(state, payload) {
      state.showDealBan = payload
    },
    SET_SHOW_FPTN(state, payload) {
      state.showFPTN = payload
    },
    SET_SHOW_DETAIL_FPTN(state, payload) {
      state.showDetailFPTN = payload
    },
    SET_SHOW_DETAIL_BAN(state, payload) {
      state.showDetailBan = payload
    },
    SET_NEGOTIATION_LIST(state, payload) {
      state.negotiationList = payload
    },
    SET_MODAL_UPLOAD_OFFER(state, payload) {
      state.modalUploadOffer = payload
    },
    SET_NEGOTIATION_DETAIL(state, payload){
      state.negotiationDetail = payload
    },
    SET_SHOW_EDIT_OFFER(state, payload){
      state.showEditOffer = payload
    },
    SET_SHOW_DETAIL_OFFER(state, payload) {
      state.showDetailOffer = payload
    },
    SET_MASTER_TAX(state, payload){
      state.masterTax = payload
    },
    SET_EXPORT_MENU(state, payload) {
      state.exportMenu = payload
    },
    SET_NEGOTIATION_OFFER_TABLE(state, payload){
      state.negotiationOfferTable = payload
    },
    SET_NEGOTIATION_OFFER_TABLE_UPLOAD(state, payload){
      state.negotiationOfferTableUpload = payload
    },
    SET_NEGOTIATION_REVISE_OFFER_FORM(state, payload){
      state.formReviseOffer = payload
    },
    SET_NEGOTIATOR(state, payload) {
      state.negotiator = payload
    },
    SET_APPROVER_NEGOTIATION(state, payload) {
      state.approverNegotiation = payload
    },
    SET_NEGOTIATION_OFFER_ITEMS(state, payload){
      state.negotiationOfferItems = payload
    },
    SET_NEGOTIATION_OFFER_BAN(state, payload){
      state.banDetails = payload
    },
    SET_SUMMARY_WITH(state, payload) {
      state.summaryWith = payload
    },
    SET_FPTN(state, payload){
      state.negotiationFPTN = payload
    },
    SET_NEGOTIATION_BAN(state, payload){
      state.negotiationBan = payload
    },
    SET_BAN_NEGOTIATION_ID(state, payload) {
      state.banNegotiationId = payload
    },
    SET_NEGOTITATION_OFFER_LAST(state, payload){
      state.negotiationOfferLast = payload
    },
    SET_DETAIL_RFQ_NEGO_APPROVER(state, payload) {
      state.detailRFQNegoApprover = payload
    },
    SET_DETAIL_RFQ_NEGO_APPROVER_INITIAL(state, payload) {
      state.detailRFQNegoApproverInitial = payload
    },
    SET_SHOW_EDIT_BAN(state, payload) {
      state.showEditBan = payload
    },
    SET_SHOW_EDIT_FPTN(state, payload) {
      state.showEditFptn = payload
    },
    SET_NEGOTIATION_OFFER_GET(state, payload) {
      state.negotiationOfferGet = payload
    },
    SET_SHOW_VENDOR_DETAILS(state, payload) {
      state.showVendorDetails = payload
    },
    SET_COUNTER_INTERVAL(state, payload) {
      state.counterInterval = payload
    },
    SET_EXPORT_NEGOTIATION(state, payload) {
      state.exportNegotiation = payload
    },
    SET_SELECTED_IDX_OFFER(state, payload) {
      state.selectedIdxOffer = payload
    },
  },
  actions: {
    //get
    async getNegotiationList({ commit, dispatch}, payload) {
      try {
        const { rfqId } = payload
        const response = await this.$api.get(`${ENDPOINT_RFQ_NEGOTIATION}/${rfqId}`)
        commit('SET_NEGOTIATION_DETAIL', response.data.result)
        return response
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },

    async getNegotiationOfferRevision({ commit, dispatch,}, payload) {
      try {
        const { negotiationId } = payload
        const response = await this.$api.get(`${ENDPOINT_RFQ_NEGOTIATION_OFFER_REVISION}/${negotiationId}`)
        const dataCommit = {...response.data.result, negotiationParticipant: response.data.result.negotiationParticipant||[], deletedProofOfNegotiationDocumentNoList: [] }
        commit('SET_NEGOTIATION_REVISE_OFFER_FORM', dataCommit)
        return response
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async getNegotiationOfferItems({ commit, dispatch}, payload) {
      try {
        const { negotiationId } = payload
        const response = await this.$api.get(`${ENDPOINT_RFQ_NEGOTIATION_OFFER_GET}/${negotiationId}`)
        commit('SET_NEGOTIATION_OFFER_ITEMS', response.data.result)
        return response
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async getNegotiationOfferBan({ commit, dispatch}, payload) {
      try {
        const { negotiationId } = payload
        const response = await this.$api.get(ENDPOINT_RFQ_NEGOTIATION_OFFER_BAN, {negotiationId})
        const dataCommit = {...response.data.result, isWaiverBg:response.data.result.isWaiverBg||"" }
        commit('SET_NEGOTIATION_OFFER_BAN', dataCommit)
        return response
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async getSummaryWith({ commit, dispatch,}, payload) {
      try {
        const { negotiationId, rfqId } = payload
        const response = await this.$api.get(ENDPOINT_RFQ_NEGOTIATION_SUMMARY_WITH, { negotiationId, rfqId })
        commit('SET_SUMMARY_WITH', response.data.result)
        return response
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async getNegotiationOfferDeal({ commit, dispatch,}, payload) {
      try {
        const { rfqId } = payload
        return await this.$api.get(`${ENDPOINT_RFQ_NEGOTIATION_OFFER_DEAL}/${rfqId}`)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async getNegotiationBan({ commit, dispatch,}, payload) {
      try {
        const { quotationId } = payload
        const response = await this.$api.get(`${ENDPOINT_RFQ_NEGOTIATION_BAN_FPTN}/${quotationId}`)
          commit('SET_NEGOTIATION_BAN', response.data.result)
        return response
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },

    async getNegotiationFPTN({ commit, dispatch}, payload) {
      try {
        const {negotiationId} = payload
        const response = await this.$api.get(ENDPOINT_RFQ_NEGOTIATION_FPTN, {negotiationId} )
        commit('SET_FPTN', response.data.result)
        return response
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },

    async getBanGeneratedTemplate({ commit, dispatch}, payload) {
      try {
        return await this.$api.get(ENDPOINT_RFQ_NEGOTIATION_BAN_REPORT)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async getFPTNGeneratedTemplate({ commit, dispatch,}, payload) {
      try {
        return await this.$api.get(ENDPOINT_RFQ_NEGOTIATION_FPTN_REPORT)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async getNegotiationMasterTax({ commit, dispatch }, payload) {
      try {
        const response = await this.$api.get(ENDPOINT_MASTERDATA_TAX)
        commit('SET_MASTER_TAX', response.data.result)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async getNegotiationOffer({ commit, dispatch,}, payload) {
      try {
        const { negotiationId } = payload
        const response = await this.$api.get(`${ENDPOINT_RFQ_NEGOTIATION_OFFER_GET}/${negotiationId}`)
        commit('SET_NEGOTIATION_OFFER_GET', response.data.result)
        return response
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async getNegotiator({ commit, dispatch }, payload) {
      try {
        const { name, isNegotiationParticipant } = payload
        const response = await this.$api.get(ENDPOINT_KARYAWAN_NEGOTIATOR, { name, isNegotiationParticipant })
        commit('SET_NEGOTIATOR', response.data.result)
        return response
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async getUserApproverNegotiation({ commit, dispatch, }, payload) {
      try {
        const { name, project, param, negotiationId } = payload
        commit('SET_APPROVER_NEGOTIATION', [])
        const response = await this.$api.get(ENDPOINT_USER_APPROVER_NEGOTIATION, { name: name, project, param, negotiationId })
        commit('SET_APPROVER_NEGOTIATION', response.data.result)
        return response
      } catch (error) {
        commit('SET_APPROVER_NEGOTIATION', [])
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async getDetailRFQNegoApprover({ commit, dispatch, }, payload) {
      try {
        const { negotiationId } = payload
        const response = await this.$api.get(`${ENDPOINT_RFQ_NEGOTIATION_OFFER_APPROVER}/${negotiationId}`)
        commit('SET_DETAIL_RFQ_NEGO_APPROVER', response.data.result)
        return response
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async getDetailRFQNegoApproverInitial({ commit, dispatch, }, payload) {
      try {
        const { negotiationId } = payload
        const response = await this.$api.get(`${ENDPOINT_RFQ_NEGOTIATION_OFFER_APPROVER}/${negotiationId}`)
        commit('SET_DETAIL_RFQ_NEGO_APPROVER_INITIAL', response.data.result)
        return response
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async refetchDetailRFQNegoApprover({ commit, state, dispatch, rootState }, payload) {
      const { negotiationId } = payload
      await dispatch('getDetailRFQNegoApproverInitial', { negotiationId })
      if (state.counterInterval) {
        clearInterval(state.counterInterval)
        commit('SET_COUNTER_INTERVAL', null)
      }
      commit('SET_COUNTER_INTERVAL', setInterval( async () => {
        await dispatch('getDetailRFQNegoApprover', { negotiationId })
      }, 3000))
      setTimeout(() => {
        clearInterval(state.counterInterval)
        commit('SET_COUNTER_INTERVAL', null)
      }, 30000)
    },
    async getNegotiationExportMenu({ commit, dispatch }, payload) {
      try {
        const { rfqId } = payload
        const response = await this.$api.get(`${ENDPOINT_RFQ_NEGOTIATION_BAN_REPORT_MENU}/${rfqId}`)
        commit('SET_EXPORT_MENU', response.data.result)
        return response
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async getNegotiationOfferLast({ commit, dispatch,}, payload) {
      try { 
        const { rfqId } = payload
        const response = await this.$api.get(`${ENDPOINT_RFQ_NEGOTIATION_OFFER_LAST}/${rfqId}`)
        commit('SET_NEGOTITATION_OFFER_LAST', response.data.result)
        return response
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async getBanReport({ commit, state, dispatch }, payload) {
      try {
        const { negotiationId } = payload
        const response = await this.$api.get(`${ENDPOINT_RFQ_NEGOTIATION_BAN_REPORT}/${negotiationId}`, null, { responseType: 'blob' })
        dispatch('file/fileReaderOpenNewTab', response, { root: true })
        return response
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async getFptnReport({ commit, state, dispatch }, payload) {
      try {
        const { negotiationId } = payload
        const response = await this.$api.get(`${ENDPOINT_RFQ_NEGOTIATION_FPTN_REPORT}/${negotiationId}`, null, { responseType: 'blob' })
        dispatch('file/fileReaderOpenNewTab', response, { root: true })
        return response
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },

    //post
    async postOfferUpload({ commit, dispatch}, payload) {
      try {
        const formData = payload
        const response = await this.$api.post(ENDPOINT_RFQ_NEGOTIATION_OFFER_UPLOAD, formData)
        const result = response.data.result
        if(!result.enableSaveOffer) commit('SET_EXPORT_NEGOTIATION', result.file)
        return response
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async getExportNegotiation({ dispatch, state,}, payload) {
      try {
        const response = state.exportNegotiation
        const byteCharacters = atob(response.content);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {type: 'xlsx' })
        const link = window.URL.createObjectURL(blob)
        const fileLink = document.createElement('a');
        fileLink.href = link;
        fileLink.setAttribute('download', response.originalFilename);
        document.body.appendChild(fileLink);
        fileLink.click()
        return response
      } catch (error) {
        return error.response
      }
    },
    async postSaveOffer({ commit, dispatch }, payload) {
      try {
        const formData = payload
        return await this.$api.post(ENDPOINT_RFQ_NEGOTIATION_OFFER_SAVE, formData)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async postSaveAsDraftOfferRevision({ commit, dispatch,}, payload) {
      try {
        return await this.$api.post(ENDPOINT_RFQ_NEGOTIATION_OFFER_REVISION, payload)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async postBanSaveAsDraft({ commit, dispatch}, payload) {
      try {
        return await this.$api.post(ENDPOINT_RFQ_NEGOTIATION_OFFER_DEAL, payload)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async postNegotiationOfferRevision({ commit, dispatch,}, payload) {
      try {
        return await this.$api.post(ENDPOINT_RFQ_NEGOTIATION_OFFER_REVISION_SEND_EMAIL, payload)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async postSaveAsDraftFPTN({ commit, dispatch,}, payload) {
      try {
        return await this.$api.post(ENDPOINT_RFQ_NEGOTIATION_FPTN, payload)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async postFptnPreview({ commit, state, dispatch }, payload) {
      try {
        const response = await this.$api.post(ENDPOINT_RFQ_NEGOTIATION_FPTN_PREVIEW, payload, { responseType: 'blob' })
        dispatch('file/fileReaderOpenNewTab', response, { root: true })
        return response
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async postNegotiationFPTN({ commit, dispatch,}, payload) {
      try {
        return await this.$api.post(ENDPOINT_RFQ_NEGOTIATION_FPTN_SUBMIT, payload)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },


    async postNegotiationDetailsExportExcel({ commit, dispatch,}, payload) {
      try {
        const response = await this.$api.post(ENDPOINT_RFQ_NEGOTIATION_BAN_REPORT_XLSX, payload, { responseType: 'blob' })
        const blob = new Blob([response.data])
        const link = window.URL.createObjectURL(blob)
        const fileLink = document.createElement('a');
        fileLink.href = link;
        fileLink.setAttribute('download', response.headers['x-customheader-filename']);
        document.body.appendChild(fileLink);
        fileLink.click()
        return response
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async postBanPreview({ commit, state, dispatch }, payload) {
      try {
        const response = await this.$api.post(ENDPOINT_RFQ_NEGOTIATION_BAN_PREVIEW, payload, { responseType: 'blob' })
        dispatch('file/fileReaderOpenNewTab', response, { root: true })
        return response
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },

    //put
    async putNegotiationRequired({ commit, dispatch,}, payload) {
      try {
        const {rfqId, isNegotiationRequired} = payload
        return await this.$api.put(`${ENDPOINT_RFQ_NEGOTIATION_REQUIRE}?rfqId=${rfqId}&isRequiredNegotiation=${isNegotiationRequired}`)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },

   
    async putNegotiationSchedule({ commit, dispatch,}, payload) {
      try {
        const { rfqId } = payload
        return await this.$api.put(`${ENDPOINT_RFQ_NEGOTIATION_SCHEDULE_CONFIRM}/${rfqId}`)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async putNegotiationOfferRevision({ commit, dispatch }, payload) {
      try {
        return await this.$api.put(ENDPOINT_RFQ_NEGOTIATION_OFFER_REVISION)  
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
  
    async putNegotiationOfferDeal({ commit, dispatch}, payload) {
      try {
        return await this.$api.put(ENDPOINT_RFQ_NEGOTIATION_OFFER_DEAL_SUBMIT, payload)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async putChangeVerificator({ commit, dispatch,}, payload) {
      try {
        const data = payload
        return await this.$api.put(ENDPOINT_RFQ_NEGOTIATION_VERIFICATOR_CHANGE, data)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async putFPTNChangeVerificator({ commit, dispatch, }, payload) {
      try {
        return await this.$api.put(ENDPOINT_RFQ_NEGOTIATION_FPTN_VERIFICATOR_CHANGE)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async putFPTNApprove({ commit, dispatch,}, payload) {
      try {
        const { fptnId, reason } = payload
        return await this.$api.put(`${ENDPOINT_RFQ_NEGOTIATION_FPTN_APPROVE}?fptnId=${fptnId}`, { reason })
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async putFPTNReject({ commit, dispatch,}, payload) {
      try {
        const { fptnId, reason } = payload
        return await this.$api.put(`${ENDPOINT_RFQ_NEGOTIATION_FPTN_REJECT}?fptnId=${fptnId}`, { reason })
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async putBanApprove({ commit, dispatch,}, payload) {
      try {
        const { banId, reason } = payload
        return await this.$api.put(`${ENDPOINT_RFQ_NEGOTIATION_BAN_APPROVE}?banId=${banId}`, { reason })
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async putBanReject({ commit, dispatch,}, payload) {
      try {
        const { banId, reason } = payload
        return await this.$api.put(`${ENDPOINT_RFQ_NEGOTIATION_BAN_REJECT}?banId=${banId}`, { reason })
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async putReNegotiation({ commit, dispatch,}, payload) {
      try {
        const { rfqId } = payload
        return await this.$api.put(`${ENDPOINT_RFQ_NEGOTIATION_RENEGOTIATION}?rfqId=${rfqId}`)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },

    //delete
    async deleteNegotiationOffer({ commit, dispatch}, payload) {
      try {
        const {negotiationId} = payload
        return await this.$api.delete(`${ENDPOINT_RFQ_NEGOTIATION_OFFER_DELETE}/${negotiationId}`)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    }



  }
  
}