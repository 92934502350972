<template>
	<div v-if="showChangeApproverScoring && scoringPage && menuScoringTeknis?.canUpdate" class="w-full">
		<div class="w-full px-4 pb-4">
			<div class="">
				<ButtonGista type="secondary"
					color="primary"
					@click="toggle"
					customClass="w-full">
					Change Approver</ButtonGista>
			</div>
		</div>
		<GistaModal :value="isOpen"
			customClass="max-w-2xl bg-white"
			@input="toggle">
      <ValidationObserver v-slot="{ handleSubmit }">
				<div class="w-full p-5">
					<div class="flex justify-center w-full mb-4">
						<h5 class="text-lg font-semibold text-gray">Change Approver</h5>
					</div>
					<div class="w-full mb-4">
						<ValidationProvider name="newApprover"
							rules="required"
							v-slot="{ errors }">
							<AutocompleteNewSolutip
								label="New Approver"
								placeholder="(Search)"
								:options="approverList"
								@updateInput="inputSearchApprover($event)"
								@updateAutoComplete="changeApprover($event)"
								@blur="blurApprover"
								@focus="focusApprover"
								v-model="newApproverNameLocalState"
								:isLoading="isLoading"
								:error="!!errors[0]"
								textField="name"
								textField2="jobTitle"
								iconLeft="icon-search text-xl text-gray-light"
								:mandatory="true"
							>
								<template #message>
									<p class="mt-1 text-xs text-error">{{ errors[0] }}</p>
								</template>
							</AutocompleteNewSolutip>
						</ValidationProvider>

					</div>
					<div class="w-full mb-4">
						<ValidationProvider name="notes"
							rules="required|max:500"
							v-slot="{ errors }">
							<TextareaGista label="Notes"
								placeholder="Leave a comment here"
								v-model="form.notes"
								:error="!!errors[0]"
								:mandatory="true">
								<template #message>
									<p>{{ errors[0] }}</p>
								</template>
							</TextareaGista>
						</ValidationProvider>
					</div>
					<div class="flex justify-end">
						<ButtonGista type="primary"
							color="success"
							@click="handleSubmit(clickChangeApprover)"
							customClass="w-full sm:w-56">
							Change Approver
						</ButtonGista>
					</div>
				</div>
			</ValidationObserver>
		</GistaModal>
	</div>
</template>
<script>
import AutocompleteNewSolutip from '@/core/components/custom/AutocompleteNew'
import { MESSAGE_SUCCESS_CHANGE_APPROVER } from '@/core/constant/successMessage'

export default {
	name: "ChangeApproverScoring",
	data() {
		return {
			isOpen: false,
			form: {
				newApproverName: '',
				newApproverId: '',
				notes: '',
			},
			newApproverNameLocalState: '',
			timeout: null,
			isLoading: false,
      applicationRoles: {
				gsit: 'SOLUTIP_SPV1_GSIT,SOLUTIP_SPV2_GSIT,SOLUTIP_SPV3_GSIT',
				ukkp: 'SOLUTIP_SPV1_KP,SOLUTIP_SPV2_KP,SOLUTIP_SPV3_KP',
				dlog: 'SOLUTIP_SPV3_LOG,SOLUTIP_SPV2_LOG'
			}
		}

	},
	computed: {
		proposalInformation() {
			return this.$store.state.projectDetail.proposalInformation
		},
		menuDetailsActionUpdateApprover() {
			return this.$store.getters['dashboard/menuDetailsActionUpdateApprover']
		},
		menuScoringTeknis() {
			return this.$store.getters['dashboard/menuScoringTeknis']
		},
		modal: {
      get() {
        return this.$store.state.modal.modal
      },
      set(value) {
        this.$store.commit('modal/SET_MODAL', value)
      }
    },
    approverList() {
			return this.$store.state.rfp.approverList.filter(e => ![this.form.newApproverId, this.activeOnReview.userId].includes(e.userName))
		},
    scoringVendorApproval() {
      return this.$store.state.projectDetail.scoringVendorApproval
    },
    approvalGsit() {
      return this.scoringVendorApproval?.find(e => e.unitKerja === 'GSIT')
    },
    approvalUkkp() {
      return this.scoringVendorApproval?.find(e => e.unitKerja === 'UKKP')
    },
    approvalDlog() {
      return this.scoringVendorApproval?.find(e => e.unitKerja === 'ITP')
    },
    showChangeApproverScoring() {
      return this.scoringVendorApproval.some(e => e.approvalStatusLocale?.toLowerCase() === 'on review')
    },
    activeOnReview() {
      return this.scoringVendorApproval.find(e => e.approvalStatusLocale?.toLowerCase() === 'on review')
    },
    scoringPage() {
      return this.$route.path.includes('scoring')
    },
    activeScoring() {
      return this.$store.state.projectDetail.activeScoring;
		},
    activeScoringVendorAndEscrow() {
      return this.activeScoring === 'Vendor' || this.activeScoring === 'Escrow'
    }
	},
	methods: {
		toggle() {
			this.isOpen = !this.isOpen
			this.form = {
				newApproverName: '',
				newApproverId: '',
				notes: '',
			}
			this.newApproverNameLocalState = ''
		},
    async inputSearchApprover(e) {
			this.isLoading = true
			if (this.activeOnReview?.unitKerja === 'GSIT') {
				await this.$store.dispatch('rfp/getApproverList', { applicationRoles: this.applicationRoles.gsit, name: e })
			} else if (this.activeOnReview?.unitKerja === 'UKKP') {
				await this.$store.dispatch('rfp/getApproverList', { applicationRoles: this.applicationRoles.ukkp, name: e })
			} else if (this.activeOnReview?.unitKerja === 'ITP') {
				await this.$store.dispatch('rfp/getApproverList', { applicationRoles: this.applicationRoles.dlog, name: e })
			}
      this.isLoading = false
		},
		async focusApprover() {
			this.isLoading = true
			if (this.activeOnReview?.unitKerja === 'GSIT') {
				await this.$store.dispatch('rfp/getApproverList', { applicationRoles: this.applicationRoles.gsit, name: this.newApproverNameLocalState })
			} else if (this.activeOnReview?.unitKerja === 'UKKP') {
				await this.$store.dispatch('rfp/getApproverList', { applicationRoles: this.applicationRoles.ukkp, name: this.newApproverNameLocalState })
			} else if (this.activeOnReview?.unitKerja === 'ITP') {
				await this.$store.dispatch('rfp/getApproverList', { applicationRoles: this.applicationRoles.dlog, name: this.newApproverNameLocalState })
			}
			this.isLoading = false
		},
		changeApprover(event) {
			this.form.newApproverId = event.userName
			this.form.newApproverName = event.name
			this.newApproverNameLocalState = event.name
		},
		blurApprover() {
			if (!this.newApproverNameLocalState) {
				this.form.newApproverName = ''
				this.form.newApproverId = ''
			}
			this.newApproverNameLocalState = this.form.newApproverName
		},
		async clickChangeApprover() {
			try {
				this.modal.modalLoading = true
        const data = {
          username: this.form.newApproverId,
          name: this.form.newApproverName,
          notes: this.form.notes
        }
        const response = await this.$store.dispatch('projectDetail/putChangeScoringApproval', { data, rfpId: this.$route.params.projectId })
        if (response?.status < 300 && response?.status >= 200) {
					this.modal.modalLoading = false
					this.isOpen = !this.isOpen
					this.form =  {
						newApproverName: '',
						newApproverId: '',
						notes: '',
					}
					this.newApproverNameLocalState = ''
					this.modal.modalSuccessSaved = { value: true, title: MESSAGE_SUCCESS_CHANGE_APPROVER }
					this.$emit('refetch')
					this.$store.dispatch('projectDetail/refetchScoringVendorApproval', { rfpId: this.$route.params.projectId })
				} else {
					this.$store.dispatch('modal/throwError', { response })
				}
			} catch (error) {
				this.modal.modalLoading = false
				this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
			}
		}

	},
	components: {
		AutocompleteNewSolutip
	}

}
</script>