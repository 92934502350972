<template>
	<div>
		<gista-modal 
      :value="isOpenComputed"
			:customClass="`max-w-2xl bg-white ${customClass}`"
			:scrollable="scrollable"
      :width="width"
      :hideClose="hideClose"
			@input="closeModal">
			<div class="w-full h-min p-6 flex flex-col justify-center">
        <div class="flex flex-auto flex-col justify-center">
          <div class="flex justify-center flex-1 mb-6">
            <img v-if="icon === 'submitted'" src="@/core/assets/icons/message/submitted.svg" alt="icon" class="w-28"/>
            <img v-if="icon === 'saved'" src="@/core/assets/icons/message/saved.svg" alt="icon" class="w-28"/>
            <img v-if="icon === 'rejected'" src="@/core/assets/icons/message/rejected.svg" alt="icon" class="w-28"/>
            <img v-if="icon === 'approved'" src="@/core/assets/icons/message/approved.svg" alt="icon" class="w-28"/>
          </div>
          <div v-if="title" class="flex flex-1 justify-center mb-6">
            <p class="text-xl text-center font-semibold" :class="icon === 'rejected'? 'text-error' : 'text-gray'">{{title}}</p>
          </div>
          <div v-if="message" class="flex flex-1 justify-center mb-6">
            <p class="text-sm text-center break-words whitespace-pre-wrap text-gray">{{message}}</p>
          </div>
          <slot name="optional"></slot>
          <div class="flex flex-none justify-center flex-wrap-reverse gap-6">
            <div v-if="isBackButton" class="sm:w-max w-full">
              <button-gista 
                type="tertiary"
                color="gray"
                customClass="w-full sm:w-56 border border-gray-lightest shadow-gray-sm"
                @click="closeModal">Back</button-gista>
            </div>
            <div class="sm:w-max w-full">
              <button-gista 
                :type="typeButton"
                customClass="w-full sm:w-56"
                :color="colorButton"
                @click="click">{{ textButton }}</button-gista>
            </div>
          </div>
        </div>
      </div>
		</gista-modal>
	</div>
</template>

<script>
export default {
	name: "ModalConfirm",
  props: {
    title: String,
    message: String,
    textButton: String,
    icon: {
      type: String,
      default: 'saved'
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: 'full'
    },
    scrollable: {
      type: Boolean,
      default: true,
    },
    typeButton: {
      type: String,
      default: 'primary'
    },
    colorButton: {
      type: String,
      default: 'primary'
    },
    isBackButton: {
      type: Boolean,
      default: false,
    },
    hideClose: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    isOpenComputed: {
      get() {
        return this.isOpen
      },
      set(val) {
        this.$emit('changeIsOpen', val)
      }
    },
  },
  methods: {
    click() {
			this.isOpenComputed = false
			this.$emit("click")
		},
		closeModal() {
			this.isOpenComputed = false
		}
  },
  beforeDestroy() {
    this.isOpenComputed = false
  },
}
</script>

<style #scoped>
.h-min {
  min-height: 20rem;
}
</style>