export const WF_RFP_GSIT = 'RFP_GSIT'
export const WF_RFP_GSIT_UKKP = 'RFP_GSIT_UKKP'
export const WF_RFP_UKKP = 'RFP_UKKP'


export const ITP_VERIFICATOR = 'ITP_VERIFICATOR'
export const SPV_ITP_APPROVAL = 'SPV_ITP_APPROVAL'
export const REPRESENTATIVE_GSIT = 'REPRESENTATIVE_GSIT'
export const SPV_GSIT_APPROVAL = 'SPV_GSIT_APPROVAL'
export const REPRESENTATIVE_UKKP = 'REPRESENTATIVE_UKKP'
export const SPV_UKKP_APPROVAL = 'SPV_UKKP_APPROVAL'


// option send email revise offer rfp -> none, local partner, both
export const OPTION_SEND_EMAIL =  {
  none: 'NONE',
  localPartner: 'LOCAL_PARTNER',
  both: 'BOTH'
}

// constant for upload file project type
export const PROJECT_TYPE_RFP = 'RFP'
export const PROJECT_TYPE_RFQ = 'RFQ'