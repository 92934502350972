/* eslint-disable */
import { ENDPOINT_RFP_DETAILS_VENDOR_CANDIDATE, ENDPOINT_DETAILS_UPDATE_APPROVER, ENDPOINT_RFP_SCORING_CHANGE_APPROVAL,
  ENDPOINT_SCORING_TEKNIS, ENDPOINT_SCORING_ESCROW, ENDPOINT_SCORING_DECISION, ENDPOINT_SCORING_ATTACHMENTS, ENDPOINT_SCORING_VENDOR_TEKNIS_TREE, ENDPOINT_RFP_PROPOSAL_INFORMATION,
  ENDOPINT_PROJECT_INFORMATION, ENDPOINT_PROJECT_REQUIREMENT, ENDPOINT_PROJECT_SCORING_INFORMATION, ENDPOINT_RFP_LOG_APPROVAL, ENDPOINT_SCORING_VENDOR_APPROVAL, ENDPOINT_SCORING_ESCROW_RISK_FACTOR, 
  ENDPOINT_SCORING_ESCROW_RISK_FACTOR_POINT, ENDPOINT_RFP_SCORING_APPROVE, ENDPOINT_RFP_SCORING_REJECT, ENDPOINT_RFP_SCORING_VENDOR_REVIEW } from '@/core/constant/menuEndpoint'
import { formatThousandSeparate } from '@/core/components/helpers/formatRupiah'


export const Store = {
  namespaced: true,
  name: 'projectDetail',
  state: {
    activePage: 'project-detail',
    paramProjectId: 0,
    activeScoring: 'Vendor',
    proposalInformation: {},
    projectInformation: {},
    projectRequirement: {},
    vendorCandidate: [],
    scoringTeknisCriteria: [],
    rfpLogApproval: {},
    scoringEscrow: {},
    scoringEscrowRiskFactor: [],
    scoringEscrowRiskFactorPoint: [],
    scoringVendorTeknisTree: [],
    scoringVendorTeknisTree2: [],
    scoringVendorDecision: [],
    collapse: {
      proposalInformation: true,
      projectInformation: true,
      projectRequirement: true,
      vendorCandidate: true,
      scoringTeknis: true,
    },
    attachments: [],
    attachments2: [],
    scoringVendorApproval: [],
    approverScoringDecision: {
			approverGsit: {},
			approverUkkp: {},
			approverDlog: {},
    },
    isActiveLocal: [],
    counterInterval: null
  },
  gettters: {
  },
  mutations: {
    SET_ACTIVE_PAGE(state, payload) {
      state.activePage = payload
    },
    SET_ACTIVE_SCORING(state, payload) {
      state.activeScoring = payload
    },
    SET_PROPOSAL_INFORMATION(state, payload) {
      state.proposalInformation = payload
    },
    SET_PROJECT_INFORMATION(state, payload) {
      state.projectInformation = payload
    },
    SET_PROJECT_REQUIREMENT(state, payload) {
      state.projectRequirement = payload
    },
    SET_VENDOR_CANDIDATE(state, payload) {
      state.vendorCandidate = payload
    },
    SET_SCORING_TEKNIS_CRITERIA(state, payload) {
      state.scoringTeknisCriteria = payload
    },
    SET_RFP_LOG_APPROVAL(state, payload) {
      state.rfpLogApproval = payload
    },
    SET_SCORING_ESCROW(state, payload) {
      state.scoringEscrow = payload
    },
    SET_SCORING_ESCROW_RISK_FACTOR(state, payload) {
      state.scoringEscrowRiskFactor = payload
    },
    SET_SCORING_ESCROW_RISK_FACTOR_POINT(state, payload) {
      state.scoringEscrowRiskFactorPoint = payload
    },
    SET_PARAM_PROJECTID(state, payload) {
      state.paramProjectId = payload
    },
    SET_SCORING_VENDOR_TEKNIS_TREE(state, payload) {
      state.scoringVendorTeknisTree = payload
    },
    SET_SCORING_VENDOR_TEKNIS_TREE2(state, payload) {
      state.scoringVendorTeknisTree2 = payload
    },
    SET_SCORING_VENDOR_DECISION(state, payload) {
      state.scoringVendorDecision = payload
    },
    SET_COLLAPSE(state, payload) {
      state.collapse = payload
    },
    SET_ATTACHMENTS(state, payload) {
      state.attachments = payload
    },
    SET_ATTACHMENTS2(state, payload) {
      state.attachments2 = payload
    },
    SET_SCORING_VENDOR_APPROVAL(state, payload) {
      state.scoringVendorApproval = payload
    },
    SET_APPROVER_SCORING_DECISION(state, payload) {
      state.approverScoringDecision = payload
    },
    SET_ISACTIVELOCAL(state, payload) {
      state.isActiveLocal = payload
    },
    SET_COUNTER_INTERVAL(state, payload) {
      state.counterInterval = payload
    }

  },
  actions: {
    //Api Get

    //Get scoring vendor teknis
    async getScoringVendorTeknisTree({ commit, state }, payload) {
      try {
        const rfpId = payload
        const response = await this.$api.get(`${ENDPOINT_SCORING_VENDOR_TEKNIS_TREE}/${rfpId}`)
        console.log(response, 'response get scoring vendor teknis tree');
        commit('SET_SCORING_VENDOR_TEKNIS_TREE', response.data.result.criterias)
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    //get scoring vendor teknis clone
    async getScoringVendorTeknisTree2({ commit, state }, payload) {
      try {
        const rfpId = payload
        const response = await this.$api.get(`${ENDPOINT_SCORING_VENDOR_TEKNIS_TREE}/${rfpId}`)
        console.log(response, 'response get scoring vendor teknis tree');
        commit('SET_SCORING_VENDOR_TEKNIS_TREE2', response.data.result.criterias)
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    /** start get continue draft rfp */
    async getProposalInformation({ commit}, payload) {
      try {
        const rfpId = payload
        const response = await this.$api.get(`${ENDPOINT_RFP_PROPOSAL_INFORMATION}/${rfpId}`)
        console.log(response, 'get proposal information rfpId');
        const result = response.data.result
        //format budgetSpent to currency 1.000.000.000
        const dataCommit = {
          ...result,
          ext: result.ext || '',
          budgetSpent: formatThousandSeparate(result.budgetSpent?.toString(), 'idr'),
        }
        commit('SET_PROPOSAL_INFORMATION', dataCommit)
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    async getProjectInformation({ commit }, payload) {
      try {
        const rfpId = payload
        const response = await this.$api.get(`${ENDOPINT_PROJECT_INFORMATION}/${rfpId}`)
        console.log(response, 'get project information project id');
        const result = response.data.result
        const dataCommit = {
          projectInformationId: result.projectInformationId || 0,
          background: result.background || '',
          tujuanProyek: result.tujuanProyek ||'',
          rfpId: result.rfpId ||0,
          jangkaWaktuProyek: result.jangkaWaktuProyek ||''
        }
        commit('SET_PROJECT_INFORMATION', dataCommit)
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    async getProjectRequirement({ commit, state }, payload) {
      try {
        const rfpId = payload
        const response = await this.$api.get(`${ENDPOINT_PROJECT_REQUIREMENT}/${rfpId}`)
        console.log(response, 'get project requirement project id');
        if (!response.data.result) throw new Error('fail to get project requirement')
        const result = response.data.result
        const dataCommit = { ...result, rfpAttachments: result.rfpAttachments.map(item => {
          return {
            documentNo: item.documentNo,
            fileName: item.fileName,
            filePath: item.filePath,
            fileRef: item.fileRef,
            isDeleted: false,
            rfpAttachmentId: item.rfpAttachmentId
          }
        })}
        commit('SET_PROJECT_REQUIREMENT', dataCommit)
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    async getProjectVendorCandidate({ commit, state }, payload) {
      try {
        const rfpId = payload
        const response = await this.$api.get(`${ENDPOINT_RFP_DETAILS_VENDOR_CANDIDATE}/${rfpId}`)
        console.log(response, 'get vendor candidate project id');
        if (response.data.result) {
          const result = response.data.result
          const dataCommit = result.map((e, i) => {
            return {
              rfpVendorCandidateId: e.rfpVendorCandidateId,
              vendorId: e.vendorId,
              vendorName: e.vendorName,
              vendorEmail: e.vendorEmail,
              reason: e.reason,
              isActive: e.isActive,
              isApprove: e.isApprove,
              isPerluPoc: e.isPerluPoc,
              disqualifiedReason: e.disqualifiedReason,
              isDeleted: e.isDeleted,
              isNewVendor: e.isNewVendor,
              disabled: true,
              isLoading: false,
              localPartners: e.localPartners?.map(el => ({ ...el, isLoading: false }))
            }
          })
          commit('SET_VENDOR_CANDIDATE', dataCommit)

        }
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    async getProjectScoringInformation({ commit, state }, payload) {
      try {
        const rfpId = payload
        const response = await this.$api.get(`${ENDPOINT_PROJECT_SCORING_INFORMATION}/${rfpId}`)
        console.log(response, 'response get project scoring');
        const dataResponse = response.data.result
        const dataCommit = dataResponse.map((e,i) => {
          const rfpScoringTeknis = e.rfpScoringTeknis.map((el,j) => {
            return {
              bobot: el.bobot,
              name: el.name,
              rfpScoringTeknisId: el.rfpScoringTeknisId,
              sequence: el.sequence,
            }
          })
          const scoreTotal = rfpScoringTeknis.reduce((a,b) => +a + +b.bobot, 0)
          return { ...e, scoreTotal: scoreTotal, rfpScoringTeknis: rfpScoringTeknis }
        })
        commit('SET_SCORING_TEKNIS_CRITERIA', dataCommit)
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    async getLogApproval({ commit, state }, payload) {
      try {
        const { rfpId } = payload
        const response = await this.$api.get(`${ENDPOINT_RFP_LOG_APPROVAL}/${rfpId}`)
        console.log(response, 'response get rfp log approval');
        commit('SET_RFP_LOG_APPROVAL', response.data.result)
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    async getScoringVendorApproval({ commit, state }, payload) {
      try {
        const { rfpId } = payload
        const response = await this.$api.get(`${ENDPOINT_SCORING_VENDOR_APPROVAL}/${rfpId}`)
        console.log(response, 'response get rfp scoring vendor approval');
        commit('SET_SCORING_VENDOR_APPROVAL', response.data.result)
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    async refetchScoringVendorApproval({ commit, state, dispatch }, payload) {
      const { rfpId } = payload
      await dispatch('getScoringVendorApproval', { rfpId })
      if (state.counterInterval) {
        clearInterval(state.counterInterval)
        commit('SET_COUNTER_INTERVAL', null)
      }
      commit('SET_COUNTER_INTERVAL', setInterval( async () => {
        await dispatch('getScoringVendorApproval', { rfpId })
      }, 3000))
      setTimeout(() => {
        clearInterval(state.counterInterval)
        commit('SET_COUNTER_INTERVAL', null)
      }, 30000)
    },
    /** end get continue draft rfp */
    //Get scoring vendor Escrow
    async getScoringVendorEscrow({ commit, state }, payload) {
      try {
        const rfpId = payload
        const response = await this.$api.get(`${ENDPOINT_SCORING_ESCROW}/${rfpId}`)
        console.log(response, 'response get scoring vendor escrow')
        //parse data response [data,data] to form multi dimensional array [[data],[data]]
        // const data = response.data.result
        // const ObjDataParse = data.reduce((group, data) => {
        //   const { scoringEscrowPointId } = data
        //   group[scoringEscrowPointId] = group[scoringEscrowPointId] ?? []
        //   group[scoringEscrowPointId].push(data)
        //   return group
        // }, {})
        //result ObjDataParse formed object {'345': [data]} 
        //parse again to form multi dimensional array [[data]]
        // const dataParse = Object.values(ObjDataParse)
        // console.log(dataParse, 'dataParse');
        commit('SET_SCORING_ESCROW', response.data.result)
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },

    //Get scoring escrow risk factor
    async getScoringEscrowRiskFactor({ commit, state }, payload) {
      try {
        const response = await this.$api.get(ENDPOINT_SCORING_ESCROW_RISK_FACTOR)
        console.log(response, 'response get scoring escrow risk factor');
        commit('SET_SCORING_ESCROW_RISK_FACTOR', response.data.result)
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    //Get scoring escrow risk factor point
    async getScoringEscrowRiskFactorPoint({ commit, state }, payload) {
      try {
        const response = await this.$api.get(ENDPOINT_SCORING_ESCROW_RISK_FACTOR_POINT)
        console.log(response, 'response get scoring escrow risk factor point');
        commit('SET_SCORING_ESCROW_RISK_FACTOR_POINT', response.data.result)
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },

    //Get scoring vendor decision
    async getScoringVendorDecision({ commit, state }, payload) {
      try {
        const rfpId = payload
        const response = await this.$api.get(`${ENDPOINT_SCORING_DECISION}/${rfpId}`)
        console.log(response, 'response get scoring vendor decision');
        const result = response.data.result
        commit('SET_SCORING_VENDOR_DECISION', result)
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    async getScoringAttachments({ commit, state }, payload) {
      try {
        const rfpId = payload
        const response = await this.$api.get(`${ENDPOINT_SCORING_ATTACHMENTS}/${rfpId}`)
        console.log(response, 'response get scoring attachments');
        const result = response.data.result
        commit('SET_ATTACHMENTS', result)
        commit('SET_ATTACHMENTS2', JSON.parse(JSON.stringify(result)))
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },

    //Api Put
    //used for edit scoring in scoring vendor
    async putProjectScoringInformation({ commit, state, rootState }, payload ) {
      try {
        const rfpId = payload
        let rfpScoringTeknisList = []
        state.scoringTeknisCriteria.forEach(e => {
          let rfpScoringTeknis = [] 
          e.rfpScoringTeknis.forEach(el => {
            if (el.name) {
              rfpScoringTeknis.push({ ...el, bobot: +el.bobot })
            }
          })
          rfpScoringTeknisList.push({ ...e, bobot: +e.bobot, rfpScoringTeknis: rfpScoringTeknis })
        })
        const rfpScoringTeknisPayload = {
          rfpId: +rfpId,
          rfpScoringTeknisList: rfpScoringTeknisList
        }
        console.log(rfpScoringTeknisPayload, 'payload scoring teknis');
        const response = await this.$api.put(ENDPOINT_PROJECT_SCORING_INFORMATION, rfpScoringTeknisPayload)
        console.log(response, 'response put project scoring teknis');
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    //put scoring vendor teknis
    async putScoringVendorTeknis({ commit, state }, payload) {
      try {
        const rfpId = payload
        const scores2 = []
        const remarks = []
        state.scoringVendorTeknisTree.forEach((e,i) => {
          remarks.push({ scoringTeknisCriteriaId: state.scoringVendorTeknisTree2[i].scoringTeknisCriteriaId, remarks: state.scoringVendorTeknisTree2[i].remarks })
          e.points.forEach(el => {
            el.scores.forEach(ele => {
              scores2.push({ ...ele, skalaNilai: +ele.skalaNilai, rfpScoringTeknisId: el.rfpScoringTeknisId })
            })
          })
        })
        const data = {
          rfpId: rfpId,
          scores: scores2,
          remarks: remarks,
          attachments: state.attachments,
        }
        console.log(data, 'data payload put scoring vendor teknis');
        const response = await this.$api.put(`${ENDPOINT_SCORING_TEKNIS}/${rfpId}`, data)
        console.log(response, 'response put scoring vendor teknis');
        return response

      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    //put save remarks
    async putScoringSaveRemarks({ commit, state }, payload) {
      try {
        const rfpId = payload
        const scores = []
        const remarks = []
        state.scoringVendorTeknisTree2.forEach(e => {
          remarks.push({ scoringTeknisCriteriaId: e.scoringTeknisCriteriaId, remarks: e.remarks })
          e.points.forEach(el => {
            el.scores.forEach(ele => {
              scores.push({ ...ele, skalaNilai: +ele.skalaNilai, rfpScoringTeknisId: el.rfpScoringTeknisId })
            })
          })
        })
        const data = {
          rfpId: rfpId,
          scores: scores,
          remarks: remarks,
          attachments: state.attachments2,
        }
        console.log(data, 'data payload put scoring vendor teknis');
        const response = await this.$api.put(`${ENDPOINT_SCORING_TEKNIS}/${rfpId}`, data)
        console.log(response, 'response put scoring vendor teknis');
        return response

      } catch (error) {
        console.log(error);
        return error.response
      }
    },

    // put scoring vendor escrow
    async putScoringVendorEscrow({ commit, state }, payload) {
      try {
        const { rfpId, scoringEscrowNotes } = payload
        const scores = []
        state.scoringEscrow.candidates.forEach(e => {
          e.riskFactors.forEach(el => {
            el.riskFactorPoints.forEach(ele => {
              scores.push({ 
                rfpVendorCandidateId: e.rfpVendorCandidateId,
                rfpScoringVendorEscrowId: ele.rfpScoringVendorEscrowId,
                scoringEscrowPointId: ele.scoringEscrowPointId,
                skalaNilai: ele.skalaNilai,
               })
            })
          })
        })
        const data = {
          rfpId: rfpId,
          scores: scores,
          scoringEscrowNotes: scoringEscrowNotes,
          attachments: state.attachments,
        }
        console.log(data, 'data payload put scoring vendor escrow');
        const response = await this.$api.put(`${ENDPOINT_SCORING_ESCROW}/${rfpId}`, data)
        console.log(response, 'response put scoring vendor escrow');
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },

    //put scoring vendor decision
    async putScoringVendorDecision({ commit, state }, payload) {
      try {
        const { rfpId, approvalList } = payload
        const decisions = state.scoringVendorDecision.map((e,i) => {
          return {
            rfpVendorCandidateId: e.rfpVendorCandidateId,
            isActive: state.isActiveLocal?.[i]
          }
        })
        const data = {
          rfpId: rfpId,
          decisions: decisions,
          approvalList,
        }
        console.log(data, 'data payload scoring vendor decision');
        const response = await this.$api.put(`${ENDPOINT_SCORING_DECISION}/${rfpId}`, data)
        console.log(response);
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },

    //put change approver
    async putChangeApprover({ commit, state }, payload) {
      try {
        const data = payload
        const response = await this.$api.put(ENDPOINT_DETAILS_UPDATE_APPROVER, data)
        console.log(response);
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },

    async putScoringApprove({ commit, state,}, payload) {
      try {
        const { rfpId, notes } = payload
        const response = await this.$api.put(`${ENDPOINT_RFP_SCORING_APPROVE}/${rfpId}`, { notes })
        console.log(response, 'response put scoring approve');
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },

    async putScoringReject({ commit, state,}, payload) {
      try {
        const { rfpId, notes } = payload
        const response = await this.$api.put(`${ENDPOINT_RFP_SCORING_REJECT}/${rfpId}`, { notes })
        console.log(response, 'response put scoring reject');
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },

    async putChangeScoringApproval({ commit, state }, payload) {
      try {
        const { data, rfpId } = payload
        const response = await this.$api.put(`${ENDPOINT_RFP_SCORING_CHANGE_APPROVAL}/${rfpId}`, data)
        console.log(response);
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    async putReviewScoringApproval({ commit, state, }, payload) {
      try {
        const { data, rfpId } = payload
        const response = await this.$api.put(`${ENDPOINT_RFP_SCORING_VENDOR_REVIEW}/${rfpId}`, data)
        console.log(response);
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },

    async resetApproverScoringDecision({ commit, state }, payload) {
      const resetVal = {
        approverGsit: {},
        approverUkkp: {},
        approverDlog: {}, 
      }
      commit('SET_APPROVER_SCORING_DECISION', resetVal)
      commit('SET_ISACTIVELOCAL', [])
    },
  }
  
}