// constant for condition approve button color in ProjectLog
// example for ITP primary success and GSIT/UKKP secondary success
// example using applicationRoleName if LOG includes in that name means ITP

export const GSIT = 'GSIT'
export const KP = 'KP'
export const LOG = 'LOG'
export const OPR = 'OPR'
export const SPV = 'SPV'

//for param approver ukkp
export const SOLUTIP_SPV_KP = 'SOLUTIP_SPV_KP'

export const SOLUTIP_OPR_KP = 'SOLUTIP_OPR_KP'
export const SOLUTIP_OPR_LOG = 'SOLUTIP_OPR_LOG'
export const SOLUTIP_SPV_LOG = 'SOLUTIP_SPV_LOG'
export const SOLUTIP_SPV1_LOG = 'SOLUTIP_SPV1_LOG'
export const SOLUTIP_SPV2_LOG = 'SOLUTIP_SPV2_LOG'
export const SOLUTIP_SPV3_LOG = 'SOLUTIP_SPV3_LOG'
export const SOLUTIP_SUPPORT2 = 'SOLUTIP_SUPPORT2'


