<template>
	<div>
		<div class="w-full" v-if="menuRfqSpp?.canCreate && !sppProcess && !isRejected">
			<div class="w-full p-4" v-if="quotationProjectPage">
				<div class="flex justify-between w-full">
					<div class="w-full">
						<ButtonGista type="secondary"
							color="error"
							:isDark="false"
							@click="cancelProject"
							customClass="w-full">
							{{!dataSpp.lastModifiedAt ? 'Cancel Quotation' : 'Continue Draft SPP'}}
						</ButtonGista>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>

export default {
	name: "CancelEditRfq",
	data() {
		return {
		}
	},
	computed: {
		quotationProjectPage() {
			return this.$route.path.includes('quotation-detail')
		},
		dataSpp() {
			return this.$store.state.sppRfq.dataSpp
		},
		menuRfqSpp() {
			return this.$store.getters['dashboard/menuRfqSpp']
		},
		sppProcess() {
			return this.$store.state.sppRfq.sppProcess
		},
		isRejected() {
			return this.dataSpp.approvals?.some(e => e.approvalStatus === 'REJECT')
		},
	},
	methods: {
		cancelProject() {
			this.$store.commit('sppRfq/SET_SHOW_SPP', true)
			this.$router.push({ name: 'QuotiationSpp'}).catch(() => ({}))
		}
	},
}
</script>