/* eslint-disable */
import { ENDPOINT_RFP_SKT_LOI, ENDPOINT_RFP_SKT_LOI_SUBMIT, ENDPOINT_RFP_SKT_LOI_APPROVAL, ENDPOINT_RFP_SKT_LOI_CHANGE_APPROVAL, ENDPOINT_RFP_SKT_LOI_CANCEL, ENDPOINT_RFP_SKT_LOI_SECURITY_CODE,
  ENDPOINT_RFP_SKT_PDF_PREVIEW, ENDPOINT_RFP_LOI_PREVIEW, ENDPOINT_RFP_SKT_LOI_CHANGE_REQUESTER, ENDPOINT_RFP_SKT_LOI_CONFIRMATION, ENDPOINT_USER_REQUESTER_SKT_LOI
 } from '@/core/constant/menuEndpoint'
 import { deepCloneAdvanced } from "@/core/components/helpers";

export const Store = {
  namespaced: true,
  name: 'sktLoi',
  state: {
    draftSktLoi: {},
    draftSktLoiDeepClone: {},
    approvals: {
      REVIEWER_DLOG: { search: '' },
      APPROVER_DLOG: { search: '' },
      APPROVER_GSIT: { search: '' },
      REVIEWER_GSIT: { search: '' },
    },
    attachments: [],
    mainPage: '',
    activeButton: 'Create SKT',
    activeButtonEdit: 'Edit SKT',
    showNeedToCreateLoi: false,
    needLoiOption: true,
  },
  mutations: {
    SET_DRAFT_SKT_LOI(state, payload) {
      state.draftSktLoi = payload
    },
    SET_DRAFT_SKT_LOI_DEEP_CLONE(state, payload) {
      state.draftSktLoiDeepClone = payload
    },
    SET_APPROVALS(state, payload) {
      state.approvals = payload
    },
    SET_ATTACHMENTS(state, payload) {
      state.attachments = payload
    },
    SET_MAIN_PAGE(state, payload) {
      state.mainPage = payload
    },
    SET_ACTIVE_BUTTON(state, payload) {
      state.activeButton = payload
    },
    SET_ACTIVE_BUTTON_EDIT(state, payload) {
      state.activeButtonEdit = payload
    },
    SET_SHOW_NEED_LOI(state, payload) {
      state.showNeedToCreateLoi = payload
    },
    SET_NEED_LOI_OPTION(state, payload) {
      state.needLoiOption = payload
    },
  },
  actions: {
    async getSkt({ commit, state, dispatch }, payload) {
      try {
        const { rfpId } = payload
        const response = await this.$api.get(`${ENDPOINT_RFP_SKT_LOI}/${rfpId}`)
        const dataCommit = { ...response.data.result, 
          needLoi: response.data.result.isCompleted || response.data.result.sktVendors?.length > 0? response.data.result.needLoi : true,
          error: {
            'Approver ITP': '',
            'Approver GSIT': ''
          },
          loiVendors: response.data.result.loiVendors?.map((e,i) => ({
            ...e,
            securityCode: state.draftSktLoi?.loiVendors?.[i]?.securityCode ?? '',
            openSecurityCode: state.draftSktLoi?.loiVendors?.[i]?.openSecurityCode !== undefined? state.draftSktLoi?.loiVendors?.[i]?.openSecurityCode : false,
            error: {
              'Note Internal BCA': '',
              'Term of Payment': '',
              'Note Negotiation / BCA Request': '',
              'Note for Bank Guarantee': '',
              'Email Vendor Principal': '',
              'PIC Vendor Principal': '',
              'Email Local Partner': '',
              'PIC Local Partner': ''
            }, 
            collapse: state.draftSktLoi?.loiVendors?.[i]?.collapse !== undefined? state.draftSktLoi?.loiVendors?.[i]?.collapse : false
          })),
          sktVendors: response.data.result.sktVendors?.map((e,i) => ({ 
            ...e, 
            securityCode: state.draftSktLoi?.sktVendors?.[i]?.securityCode ?? '',
            openSecurityCode: state.draftSktLoi?.sktVendors?.[i]?.openSecurityCode !== undefined? state.draftSktLoi?.sktVendors?.[i]?.openSecurityCode : false,
            error: {
              'Email Vendor Principal': '',
              'PIC Vendor Principal': '',
              'Email Local Partner': '',
              'PIC Local Partner': ''
            }, 
            collapse: state.draftSktLoi?.sktVendors?.[i]?.collapse !== undefined? state.draftSktLoi?.sktVendors?.[i]?.collapse : false
          }))
        }
        commit('SET_DRAFT_SKT_LOI', dataCommit)
        commit('SET_DRAFT_SKT_LOI_DEEP_CLONE', deepCloneAdvanced(dataCommit))
        dispatch('setCommit', { result: response.data.result })
        return response
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    setCommit({ commit, state, dispatch }, payload) {
      const { result } = payload
      commit('SET_APPROVALS', {
        REVIEWER_DLOG: { search: '' },
        APPROVER_DLOG: { search: '' },
        APPROVER_GSIT: { search: '' },
        REVIEWER_GSIT: { search: '' },
      })
      for (const iterator of result.approvals) {
        commit('SET_APPROVALS', { ...state.approvals, [iterator.approvalType]: { ...iterator, search: iterator.name} })
      }
      if (result.sktVendors?.length === 0) {
        commit('SET_ACTIVE_BUTTON', 'Create LOI')
        commit('SET_ACTIVE_BUTTON_EDIT', 'Edit LOI')
        if (!result.lastModifiedAt) {
          commit('SET_SHOW_NEED_LOI', true)
        }
      }
      if (result.needLoi) {
        commit('SET_NEED_LOI_OPTION', !result.needLoi)
        commit('SET_ACTIVE_BUTTON', 'Create LOI')
        commit('SET_ACTIVE_BUTTON_EDIT', 'Edit LOI')
      }
    },
    dataPayload({ commit, state, dispatch }, payload) {
      const arrayApprovals = []
      for (const key in state.approvals) {
        if (state.approvals[key].username) {
          const { search, approvalDate, approvalStatus, rfpId, ...res } = state.approvals[key]
          arrayApprovals.push(res)
        }
      }
      const { isNew, lastModifiedAt, error, statusDocument, ...data } = { 
        ...state.draftSktLoi, 
        needLoi: state.draftSktLoi.needLoi,
        sktVendors: state.draftSktLoi.sktVendors === null? null : state.draftSktLoi.sktVendors?.map(e => {
          return {
            rfpVendorCandidateId: e.rfpVendorCandidateId,
            vendorId: e.vendorId,
            vendorName: e.vendorName,
            vendorPic: e.vendorPic,
            vendorEmail: e.vendorEmail,
            localPartnerName: e.localPartnerName,
            localPartnerPic: e.localPartnerPic,
            localPartnerEmail: e.localPartnerEmail,
            status: e.status,
            sktNo: e.sktNo,
            sktDateLabel: e.sktDateLabel,
            sktDate: e.sktDate,
            sendSkt: e.sendSkt
          }
        }),
        loiVendors: state.draftSktLoi.loiVendors === null? null : state.draftSktLoi.loiVendors?.map(e => {
            return {
              rfpVendorCandidateId: e.rfpVendorCandidateId,
              vendorId: e.vendorId,
              vendorName: e.vendorName,
              vendorPic: e.vendorPic,
              vendorEmail: e.vendorEmail,
              localPartnerName: e.localPartnerName,
              localPartnerPic: e.localPartnerPic,
              localPartnerEmail: e.localPartnerEmail,
              status: e.status,
              loiNo: e.loiNo,
              loiDateLabel: e.loiDateLabel,
              loiDate: e.loiDate,
              sendLoi: e.sendLoi,
              negotiationNotes: e.negotiationNotes,
              negotiationNotesInternal: e.negotiationNotesInternal,
              termOfPayment: e.termOfPayment,
              guaranteeNotes: e.guaranteeNotes, 
            }
        }),
        attachments: state.draftSktLoi.attachments,
        approvals: arrayApprovals
      }
      return data
    },
    async saveAsDraftSkt({ commit, state, dispatch }, payload) {
      try {
        const data = await dispatch('dataPayload')
        return await this.$api.post(ENDPOINT_RFP_SKT_LOI, data)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async updateDraftSkt({ commit, state, dispatch }, payload) {
      try {
        const data = await dispatch('dataPayload')
        return await this.$api.put(ENDPOINT_RFP_SKT_LOI, data)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async submitDraftSkt({ commit, state, dispatch }, payload) {
      try {
        const data = await dispatch('dataPayload')
        return await this.$api.post(ENDPOINT_RFP_SKT_LOI_SUBMIT, data)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async updateSubmitSkt({ commit, state, dispatch }, payload) {
      try {
        const data = await dispatch('dataPayload')
        return await this.$api.put(ENDPOINT_RFP_SKT_LOI_SUBMIT, data)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async getPreviewSkt({ commit, state, dispatch }, payload) {
      try {
        const { rfpId, vendorCandidateId } = payload
        const response = await this.$api.get(ENDPOINT_RFP_SKT_PDF_PREVIEW, { rfpId, vendorCandidateId }, { responseType: 'blob' })
        dispatch('file/fileReaderOpenNewTab', response, { root: true })
        return response
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async getPreviewLoi({ commit, state, dispatch }, payload) {
      try {
        const { rfpId, vendorCandidateId } = payload
        const response = await this.$api.get(ENDPOINT_RFP_LOI_PREVIEW, { rfpId, vendorCandidateId }, { responseType: 'blob' })
        dispatch('file/fileReaderOpenNewTab', response, { root: true })
        return response
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    dataPayloadSkt({ commit, state, dispatch }, payload) {
      const { indexSkt } = payload
      const arrayApprovals = []
      for (const key in state.approvals) {
        if (state.approvals[key].username) {
          const { search, ...res } = state.approvals[key]
          arrayApprovals.push(res)
        }
      }
      const sktVendorTemp = state.draftSktLoi.sktVendors[indexSkt]
      const sktVendor = {
        rfpVendorCandidateId: sktVendorTemp.rfpVendorCandidateId,
        vendorId: sktVendorTemp.vendorId,
        vendorName: sktVendorTemp.vendorName,
        vendorPic: sktVendorTemp.vendorPic,
        vendorEmail: sktVendorTemp.vendorEmail,
        localPartnerName: sktVendorTemp.localPartnerName,
        localPartnerPic: sktVendorTemp.localPartnerPic,
        localPartnerEmail: sktVendorTemp.localPartnerEmail,
        status: sktVendorTemp.status
      }
      const { isNew, lastModifiedAt, error, sktVendors, loiVendors, needLoi, attachments, statusDocument, ...data } = { 
        ...state.draftSktLoi, 
        sktVendor,
        approvals: arrayApprovals
      }
      return data
    },
    async postPreviewSkt({ commit, state, dispatch }, payload) {
      try {
        const { indexSkt } = payload
        const data = await dispatch('dataPayloadSkt', { indexSkt })
        const response = await this.$api.post(ENDPOINT_RFP_SKT_PDF_PREVIEW, data, { responseType: 'blob' })
        dispatch('file/fileReaderOpenNewTab', response, { root: true })
        return response
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    dataPayloadLoi({ commit, state, dispatch }, payload) {
      const { indexLoi } = payload
      const arrayApprovals = []
      for (const key in state.approvals) {
        if (state.approvals[key].username) {
          const { search, ...res } = state.approvals[key]
          arrayApprovals.push(res)
        }
      }
      const loiVendorsTemp = state.draftSktLoi.loiVendors[indexLoi]
      const loiVendor = {
        rfpVendorCandidateId: loiVendorsTemp.rfpVendorCandidateId,
        vendorId: loiVendorsTemp.vendorId,
        vendorName: loiVendorsTemp.vendorName,
        vendorPic: loiVendorsTemp.vendorPic,
        vendorEmail: loiVendorsTemp.vendorEmail,
        localPartnerName: loiVendorsTemp.localPartnerName,
        localPartnerPic: loiVendorsTemp.localPartnerPic,
        localPartnerEmail: loiVendorsTemp.localPartnerEmail,
        status: loiVendorsTemp.status,
        statusDocument: loiVendorsTemp.statusDocument,
        lastModifiedAt: loiVendorsTemp.lastModifiedAt,
        loiNo: loiVendorsTemp.loiNo,
        loiDate: loiVendorsTemp.loiDate,
        offerNo: loiVendorsTemp.offerNo,
        offerDate: loiVendorsTemp.offerDate,
        offerFinalDate: loiVendorsTemp.offerFinalDate,
        sendLoi: loiVendorsTemp.sendLoi,
        negotiationNotes: loiVendorsTemp.negotiationNotes,
        negotiationNotesInternal: loiVendorsTemp.negotiationNotesInternal,
        termOfPayment: loiVendorsTemp.termOfPayment,
        guaranteeNotes: loiVendorsTemp.guaranteeNotes,
        negotiationResultDetails: loiVendorsTemp.negotiationResultDetails
      }
      const { isNew, lastModifiedAt, error, needLoi, sktVendors, loiVendors, attachments, statusDocument, ...data } = { 
        ...state.draftSktLoi, 
        loiVendor,
        approvals: arrayApprovals
      }
      return data
    },
    async postPreviewLoi({ commit, state, dispatch }, payload) {
      try {
        const { indexLoi } = payload
        const data = await dispatch('dataPayloadLoi', { indexLoi })
        const response = await this.$api.post(ENDPOINT_RFP_LOI_PREVIEW, data, { responseType: 'blob' })
        dispatch('file/fileReaderOpenNewTab', response, { root: true })
        return response
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async approvalSkt({ commit, state, dispatch }, payload) {
      try {
        const { rfpId, data } = payload
        return await this.$api.put(`${ENDPOINT_RFP_SKT_LOI_APPROVAL}/${rfpId}`, data)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async changeApproval({ commit, state, dispatch }, payload) {
      try {
        const { rfpId, data } = payload
        return await this.$api.put(`${ENDPOINT_RFP_SKT_LOI_CHANGE_APPROVAL}/${rfpId}`, data)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async changeRequester({ commit, state, dispatch }, payload) {
      try {
        const { rfpId, requesterUsername, requesterName, reason } = payload
        return await this.$api.put(ENDPOINT_RFP_SKT_LOI_CHANGE_REQUESTER, {rfpId, requesterUsername, requesterName, reason})
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async cancelSkt({ commit, state, dispatch }, payload) {
      try {
        const { rfpId } = payload
        const response = await this.$api.put(`${ENDPOINT_RFP_SKT_LOI_CANCEL}/${rfpId}`)
        dispatch('resetState')
        return response
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async getSktLoiSecurityCode({ commit, state, dispatch }, payload) {
      try {
        const { type, vendorCandidateId } = payload
        return await this.$api.get(ENDPOINT_RFP_SKT_LOI_SECURITY_CODE, { type,vendorCandidateId })
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async needToCreateLoi({ commit, state, dispatch }, payload) {
      try {
        const { rfpId } = payload
        return await this.$api.put(`${ENDPOINT_RFP_SKT_LOI_CONFIRMATION}/${rfpId}`)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async getApprovalUserRequesterSktLoi({ commit, state, dispatch }, payload) {
      try {
        const { rfxId, project, nameFilter } = payload
        return await this.$api.get(ENDPOINT_USER_REQUESTER_SKT_LOI, { rfxId, project, nameFilter })
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async resetState({ commit, state, dispatch }, payload) {
      commit('SET_APPROVALS', {
        REVIEWER_DLOG: { search: '' },
        APPROVER_DLOG: { search: '' },
        APPROVER_GSIT: { search: '' },
        REVIEWER_GSIT: { search: '' },
      })
      commit('SET_MAIN_PAGE', '')
      commit('SET_SHOW_NEED_LOI', false)
      commit('SET_NEED_LOI_OPTION', true)
      commit('SET_ACTIVE_BUTTON', 'Create SKT')
      commit('SET_ACTIVE_BUTTON_EDIT', 'Edit SKT')
    }
  }
  
}