import "./set-public-path";
import Vue from "vue";
import singleSpaVue from "single-spa-vue";

import App from "./App.vue";

import Router from "vue-router";
import Vuex from "vuex";

import "./plugins/vee-validate";

import { run } from "./bootstrap";
import service from "./service";
import { registerGlobalComponents } from "./componentRegister";

import "@/core/assets/styles/theme.css";

import "@/core/assets/styles/custom.css";
import "@/core/assets/styles/style.css";

/* Start of : Register global component from styleguide */
import components from "@logistic-assistant/styleguide";

for (const index in components) {
  const component = components[index];
  Vue.component(component.name, component);
}
/* End of :Register global component from styleguide */

/* Start of : Register global component from folder core/components */
registerGlobalComponents(service);
const appComponents = service.Component.getComponents();
appComponents.base.forEach((base) => {
  Vue.component(base.name, base.component);
});
appComponents.layout.forEach((layout) => {
  Vue.component(layout.name, layout.component);
});
/* End of : Register global component from folder core/components */

/* Start of : register all service in global variable */
Vue.prototype.$service = service;
/* End of : register all service in global variable */

Vue.use(Router);
Vue.use(Vuex);

Vue.config.productionTip = false;

/* Start of : run methods bootstrap to register all router & store in all modules */
(async () => {
  await run(service);
})();
/* End of : run methods bootstrap to register all router & store in all modules */

/* Start of : register all router from all modules */
const routes = service.Router.getRoutes();
const router = new Router({
  mode: "history",
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
});


/* End of : register all router from all modules */

/* Start of : register all store from all modules */
const modules = service.Store.getModules();
const generalState = service.Store.getGeneralState();
const store = new Vuex.Store({
  modules,
  state: generalState.state,
  getters: generalState.getters,
  mutations: generalState.mutations,
});
store.$api = service.Api; // register api variables in store
/* End of : register all store from all modules */

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render(h) {
      return h(App, {
        props: {
          keycloak: this.keycloak,
        },
      });
    },
    router,
    store,
  },
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
