/* eslint-disable */
import { ENDPOINT_RFQ_SPP, ENDPOINT_RFQ_SPP_SUBMIT, ENDPOINT_RFQ_SPP_APPROVAL, ENDPOINT_RFQ_SPP_CHANGE_APPROVAL, ENDPOINT_RFQ_SPP_CANCEL, ENDPOINT_RFQ_SPP_PREVIEW, ENDPOINT_RFQ_SPP_SECURITY_CODE } from '@/core/constant/menuEndpoint'

export const Store = {
  namespaced: true,
  name: 'sppRfq',
  state: {
    showSpp: false,
    mainPage: '',
    dataSpp: {
      statusDocument: 'DRAFT',
      openSecurityCode:false,
      lastModifiedAt: '11 Jul',
      approvals: [],
    },
    sppProcess: false,
    approvals: {
      REVIEWER_DLOG: { search: '' },
      APPROVER_DLOG: { search: '' },
      APPROVER_GSIT: { search: '' },
      REVIEWER_GSIT: { search: '' },
    },
  },
  mutations: {
    SET_SHOW_SPP(state, payload) {
      state.showSpp = payload
    },
    SET_MAIN_PAGE(state, payload) {
      state.mainPage = payload
    },
    SET_DATA_SPP(state, payload) {
      state.dataSpp = payload
    },
    SET_APPROVALS(state, payload) {
      state.approvals = payload
    },
    SET_SPP_PROCESS(state, payload) {
      state.sppProcess = payload
    },
  },
  actions: {
    resetState({ commit, state, dispatch }, payload) {
      const {resetAll} = payload
      commit('SET_APPROVALS', {
        REVIEWER_DLOG: { search: '' },
        APPROVER_DLOG: { search: '' },
        APPROVER_GSIT: { search: '' },
        REVIEWER_GSIT: { search: '' },
      })
      if(resetAll) {
        commit('SET_SHOW_SPP', false)
        commit('SET_SPP_PROCESS', false)
      }
    },
    dataPayloadSpp({ commit, state, dispatch }, payload) {
      const arrayApprovals = []
      for (const key in state.approvals) {
        if (state.approvals[key].username) {
          const { search, ...res } = state.approvals[key]
          arrayApprovals.push(res)
        }
      }
      const { isNew, lastModifiedAt, error, ...data } = { 
        ...state.dataSpp,
        approvals: arrayApprovals
      }
      return data
    },
    async dataPayloadPreviewSpp({ commit, state, dispatch }, payload) {
      const dataPayload = await dispatch('dataPayloadSpp')
      return dataPayload
    },
    setCommit({ commit, state, dispatch }, payload) {
      const { result } = payload
      commit('SET_APPROVALS', {
        REVIEWER_DLOG: { search: '' },
        APPROVER_DLOG: { search: '' },
        APPROVER_GSIT: { search: '' },
        REVIEWER_GSIT: { search: '' },
      })
      for (const iterator of result.approvals) {
        commit('SET_APPROVALS', { ...state.approvals, [iterator.approvalType]: { ...iterator, search: iterator.name} })
      }
    },
    async getSpp({ commit, state, dispatch }, payload) {
      try {
        const {rfqId} = payload
        const response = await this.$api.get(`${ENDPOINT_RFQ_SPP}/${rfqId}`)
        const dataCommit = { ...response.data.result,
          securityCode: state.dataSpp?.securityCode ?? '',
          openSecurityCode: state.dataSpp?.openSecurityCode !== undefined? state.dataSpp?.openSecurityCode : false,
          error: {
            'Email Vendor': '',
            'PIC Vendor': '',
            'Cancellation Request': false,
            'Approver DLOG': '',
            'Approver GSIT': ''
          }, 
          collapse: state.dataSpp?.collapse !== undefined? state.dataSpp?.collapse : false
        }
        // to check whether the spp is in progress or not
        commit('SET_SPP_PROCESS', dataCommit.statusDocument !== 'SPP_DRAFT' && dataCommit.statusDocument !== null)
        commit('SET_DATA_SPP', dataCommit)
        dispatch('setCommit', { result: response.data.result })
        return response
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async saveAsDraftSpp({ commit, state, dispatch }, payload) {
      try {
        const data = await dispatch('dataPayloadSpp')
        return await this.$api.post(ENDPOINT_RFQ_SPP, data)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async updateDraftSpp({ commit, state, dispatch }, payload) {
      try {
        const data = await dispatch('dataPayloadSpp')
        return await this.$api.put(ENDPOINT_RFQ_SPP, data)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async submitSpp({ commit, state, dispatch }, payload) {
      try {
        const data = await dispatch('dataPayloadSpp')
        return await this.$api.post(ENDPOINT_RFQ_SPP_SUBMIT, data)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async updateSubmitSpp({ commit, state, dispatch }, payload) {
      try {
        const data = await dispatch('dataPayloadSpp')
        return await this.$api.put(ENDPOINT_RFQ_SPP_SUBMIT, data)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async approvalSpp({ commit, state, dispatch }, payload) {
      try {
        const { rfqId, data } = payload
        return await this.$api.put(`${ENDPOINT_RFQ_SPP_APPROVAL}/${rfqId}`, data)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async changeApprovalSpp({ commit, state, dispatch }, payload) {
      try {
        const { rfqId, data } = payload
        return await this.$api.put(`${ENDPOINT_RFQ_SPP_CHANGE_APPROVAL}/${rfqId}`, data)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async cancelApprovalSpp({ commit, state, dispatch }, payload) {
      try {
        const {rfqId} = payload
        return await this.$api.put(`${ENDPOINT_RFQ_SPP_CANCEL}/${rfqId}`)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async previewSpp({ commit, state, dispatch }, payload) {
      try {
        const data = await dispatch('dataPayloadPreviewSpp')
        const response = await this.$api.post(ENDPOINT_RFQ_SPP_PREVIEW, data, { responseType: 'blob' })
        dispatch('file/fileReaderOpenNewTab', response, { root: true })
        return response
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async getSppSecurityCode({ commit, state, dispatch }, payload) {
      try {
        const { rfqId } = payload
        return await this.$api.get(ENDPOINT_RFQ_SPP_SECURITY_CODE, { rfqId })
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    }, 
  }
}