<template>
  <div>
    <modal-success nameModal="modalSuccessSubmitted"></modal-success>
    <modal-success nameModal="modalSuccessSaved" kind="saved"></modal-success>
    <modal-success nameModal="modalSuccessApproved" kind="approved"></modal-success>
    <modal-error nameModal="modalError"></modal-error>
		<modal-error nameModal="modalErrorValidate"></modal-error>
    <modal-loading></modal-loading>
    <ModalProjectAccessDenied/>
  </div>
</template>

<script>
  import ModalProjectAccessDenied from '@/core/components/modal/ProjectAccessDenied.vue'
  import ModalSuccess from '@/core/components/modal/Success'
  import ModalError from '@/core/components/modal/Error'
  import ModalLoading from '@/core/components/modal/Loading.vue'
  export default {
    name: "Modal",
    components: {
      ModalSuccess,
      ModalError,
      ModalLoading,
      ModalProjectAccessDenied,
    }
  }
</script>