export default class Store {
  constructor() {
    this.name = "Store";
    this.generalState = {
      state: {
        modalDialog: false,
        stateDialog: "",
        message: "",
        errorMessages: [],
        traceId: "",
        isLoading: false,
      },

      getters: {
        getModalDialog(state) {
          return state.modalDialog;
        },

        getStateDialog(state) {
          return state.stateDialog;
        },

        getMessage(state) {
          return state.message;
        },

        getErrorMessages(state) {
          return state.errorMessages;
        },

        getTraceId(state) {
          return state.traceId;
        },

        getLoadingState(state) {
          return state.isLoading;
        },
      },

      mutations: {
        setSuccessDialog(state, payload) {
          state.modalDialog = true;
          state.stateDialog = "success";
          state.message = payload.message;
        },

        setErrorDialog(state, payload) {
          state.modalDialog = true;
          state.stateDialog = "error";
          state.message = payload.message;
          state.errorMessages = payload.errorMessages
            ? payload.errorMessages
            : [];
          state.traceId = payload.traceId;
        },

        closeDialog(state) {
          state.modalDialog = false;
          state.stateDialog = "";
          state.message = "";
          state.errorMessages = [];
          state.traceId = "";
        },

        setLoadingState(state, payload) {
          state.isLoading = payload;
        },
      },
    };
    this.modules = {};
  }

  registerModuleStore(module = {}) {
    this.modules[module.name] = module;
  }

  getModules() {
    return this.modules;
  }

  getGeneralState() {
    return this.generalState;
  }
}
