<template>
	<div class="w-full">
		<div class="w-full p-4">
      <div class="flex flex-col justify-between gap-6 sm:flex-row">
        <ButtonGista 
          v-if="menuRfqDetailsReject?.canUpdate"
          type="secondary"
          color="error"
          :isDark="false"
          @click="toggle"
          customClass="w-full">
          Reject RFQ
        </ButtonGista>
        <ButtonGista 
          v-if="menuRfqDetailsApprove?.canUpdate"
          :type="`${roleGsitUkkp?'secondary':'primary'}`"
          color="success"
          @click="toggleApprove"
          class="w-full">
          Approve RFQ</ButtonGista>
      </div>
		</div>
		<GistaModal 
      :value="isOpen"
      customClass="max-w-2xl bg-white"
			@input="toggle">
			<ValidationObserver v-slot="{ handleSubmit }">
        <div class="w-full p-5">
          <div class="flex justify-center w-full">
            <h5 class="text-lg font-semibold text-gray">Tolak RFQ</h5>
          </div>
          <div class="flex-wrap w-full mb-4">
            <ValidationProvider name="Reason" rules="required|max:500"  v-slot="{errors}">
              <TextareaGista
                label="Reason"
                placeholder="Leave a comment here"
                v-model="reason"
                :error="!!errors[0]"
                mandatory
              >
                <template #message>
                  <p>{{errors[0]}}</p>
                </template>
              </TextareaGista>
            </ValidationProvider>

          </div>
          <div class="flex justify-center">
            <ButtonGista 
              v-if="menuRfqDetailsReject?.canUpdate"
              type="primary"
              color="error"
              @click="handleSubmit(clickReject)"
              customClass="w-full sm:w-56">
              Reject
            </ButtonGista>

          </div>
        </div>
      </ValidationObserver>
		</GistaModal>
		<GistaModal 
      :value="isOpen2"
      customClass="max-w-2xl bg-white"
			@input="toggleApprove">
      <ValidationObserver v-slot="{ handleSubmit }">
        <div class="w-full p-5">
          <div class="w-full mb-1">
            <h4 class="mb-8 text-3xl text-center font-medium">Setujui</h4>
            <div class="flex-wrap w-full mb-4">
              <ValidationProvider name="Reason" rules="max:500" v-slot="{errors}">
                <TextareaGista
                  label="Reason"
                  placeholder="Leave a comment here"
                  v-model="reason"
                  :error="!!errors[0]"
                >
                  <template #message>
                    <p>{{errors[0]}}</p>
                  </template>
                </TextareaGista>
              </ValidationProvider>

            </div>
          </div>
          <div class="flex justify-center">
            <ButtonGista 
              color="success"
              customClass="w-full sm:w-56"
              @click="handleSubmit(clickApprove)"
              >
              Approve
            </ButtonGista>

          </div>
        </div>
      </ValidationObserver>
		</GistaModal>
	</div>
</template>
<script>
import { PATH_ROOT } from '@/core/constant/routeName'
import { MESSAGE_SUCCESS_APPROVE_RFQ, MESSAGE_SUCCESS_REJECT_RFQ } from '@/core/constant/successMessage'

export default {
  name: "ApprovalRfq",
	data() {
		return {
			isOpen: false,
			isOpen2: false,
      reason: ''
		}
	},
  props: ['roleGsitUkkp'],
  computed: {
    menuRfqDetailsApprove() {
			return this.$store.getters['dashboard/menuRfqDetailsApprove']
		},
		menuRfqDetailsReject() {
			return this.$store.getters['dashboard/menuRfqDetailsReject']
		},
    quotationInformation() {
			return this.$store.state.quotationDetail.quotationInformation
		},
    modal: {
			get() {
				return this.$store.state.modal.modal
			},
			set(value) {
				this.$store.commit('modal/SET_MODAL', value)
			}
		},
  },
	methods: {
    async clickReject() {
      try {
				this.modal.modalLoading = true
				const response = await this.$store.dispatch('quotationDetail/putRejectQuotation', { rfqId: this.$route.params.quotationId, reason: this.reason })
				if (response?.status < 300 && response?.status >= 200) {
          this.toggle()
          this.modal.modalLoading = false
          this.modal.modalSuccessApproved = { value: true, title: MESSAGE_SUCCESS_REJECT_RFQ }
          const query = await this.$store.dispatch('dashboard/setQueryBacktoHome')
          this.$store.commit('projectLog/SET_PROJECT_ID', this.quotationInformation.projectId)
          this.$router.push({ path: `/${PATH_ROOT}`, query }).catch(() => ({}))
          this.$emit('refetchProjectList')
        } else {
          this.$store.dispatch('modal/throwError', { response })
        }
			} catch (error) {
				this.modal.modalLoading = false
				this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
			}
    },
    async clickApprove() {
			try {
				this.modal.modalLoading = true
				const response = await this.$store.dispatch('quotationDetail/putApproveQuotation', { rfqId: this.$route.params.quotationId, reason: this.reason })
				if (response?.status < 300 && response?.status >= 200) {
          this.toggleApprove()
          this.modal.modalLoading = false
          this.modal.modalSuccessApproved = { value: true, title: MESSAGE_SUCCESS_APPROVE_RFQ }
          const query = await this.$store.dispatch('dashboard/setQueryBacktoHome')
          this.$router.push({ path: `/${PATH_ROOT}`, query }).catch(() => ({}))
          this.$store.commit('projectLog/SET_PROJECT_ID', this.quotationInformation.projectId)
          this.$emit('refetchProjectList')
				} else {
          this.$store.dispatch('modal/throwError', { response })
        }
			} catch (error) {
				this.modal.modalLoading = false
				this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
			}
		},
		toggle() {
			this.isOpen = !this.isOpen
      this.reason = ''
		},
		toggleApprove() {
			this.isOpen2 = !this.isOpen2
      this.reason = ''
		},
	},
}
</script>

<style scoped>
.width-button-submit {
  max-width: 40rem;
}
</style>