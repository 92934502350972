<template>
  <div>
    <div class="mb-1">
      <p class="block text-gray text-sm font-medium truncate">{{ label }} <span v-if="mandatory" class="text-error">*</span></p>
    </div>
    <div class="bg-gray-lightest text-gray px-3.5 flex items-center rounded-lg" :class="{ 'h-10': size === 'small', 'h-12': size === 'medium', 'h-14': size === 'large'}">
      <p :title="value" :class="`text-${color} truncate`">{{ value }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "InputDisabled",
  props: {
    value: String,
    label: String,
    size: {
      type: String,
      default: 'large'
    },
    color: {
      type: String,
      default: 'gray'
    },
    mandatory: {
      type: Boolean,
      default: false,
    },
  }
};
</script>
