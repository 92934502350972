<template>
	<div class="w-full" v-if="menuRfpSpp?.canCreate && !isSubmitted">
		<div class="w-full p-4" v-if="detailProjectPage">
			<div class="flex justify-between w-full">
				<div class="w-full">
					<ButtonGista type="secondary"
						color="error"
						:isDark="false"
						@click="cancelProject"
						customClass="w-full">
						{{dataSpp.isNew ? 'Cancel Project' : 'Continue Draft SPP'}}
					</ButtonGista>
				</div>
			</div>
		</div>
	</div>
</template>
<script>

export default {
	name: "CancelEditRfp",
	data() {
		return {
		}
	},
	computed: {
		detailProjectPage() {
			return this.$route.path.includes('project-detail')
		},
		menuRfpSpp() {
			return this.$store.getters['dashboard/menuRfpSpp']
		},
		dataSpp() {
			return this.$store.state.spp.dataSpp
		},
		projectLogRfp() {
			return this.$store.state.projectLog.projectLogRfp
		},
		isSubmitted() {
			return this.dataSpp.statusDocument !== 'SPP_DRAFT' && this.dataSpp.statusDocument !== null
		},
	},
	methods: {
		cancelProject() {
			if(this.dataSpp.isNew) this.$store.commit('spp/SET_SHOW_SPP', true)
			this.$router.push({ name: 'projectSpp'}).catch(() => ({}))
		}
	},
}
</script>