<template>
	<div class="flex w-full">
		<div class="relative flex w-full min-h-layout">
			<div :class="`${minimizeLog || windowWidth < 1024? 'w-full' : 'w-2/3 pr-6'}`">
				<div class="flex items-center w-full title my-11">
					<router-link :to="`/${PATH_ROOT}`">
					</router-link>
					<div @click="clickBackToProject">
						<p class="text-2xl font-bold cursor-pointer icon-arrow-left text-gray"></p>
					</div>
					<div class="flex flex-col items-center justify-start w-full gap-3 sm:justify-between sm:flex-row">
						<h4 class="pl-3 text-3xl font-medium">Quotation details</h4>
						<div>
							<p v-if="windowWidth < 1024" class="block underline cursor-pointer lg:hidden text-tertiary"
								@click="changeShowLog">Project Log</p>
							<div v-else class="flex items-center">
								<p v-if="minimizeLog" class="block pr-6 underline cursor-pointer text-tertiary"
									@click="changeShowLog">Project Log</p>
								<span v-if="windowWidth >= 1024 && minimizeLog" class="mr-3 text-lg cursor-pointer icon-maximize text-gray hover:text-gray-dark" @click=changeMinimizeLog></span>
							</div>
						</div>
					</div>
				</div>
				<div id="quotation-detail-container">
					<div class="w-full px-6 pb-6 mb-6 bg-white rounded-xl shadow-gray-sm">
						<div class="w-full border-b rounded-t-xl border-gray-lightest">
							<div class="flex justify-center w-full">
								<div class="flex overflow-x-auto">
									<router-link :to="`/${PATH_ROOT}/${QUOTATION_DETAILS}/${paramQuotationId}`">
										<h4 class="px-6 py-3 text-base text-center cursor-pointer"
											:class="{ 'border-b-2 border-primary text-gray': activePage === QUOTATION_DETAILS, 'text-gray-light hover:text-gray': activePage !== QUOTATION_DETAILS }"
											>Details</h4>
									</router-link>
									<router-link :to="`/${PATH_ROOT}/${QUOTATION_NEGOTIATION}/${paramQuotationId}`">
										<h4 v-if="negotiationShow" class="px-6 py-3 text-base text-center cursor-pointer"
											:class="{ 'border-b-2 border-primary text-gray': activePage === QUOTATION_NEGOTIATION, 'text-gray-light hover:text-gray': activePage !== QUOTATION_NEGOTIATION }"
											>Negotiation</h4>
									</router-link>
									<router-link :to="`/${PATH_ROOT}/${QUOTATION_APPROVAL}/${paramQuotationId}`">
										<h4 v-if="memoDetailShow"
											class="px-6 py-3 text-base text-center cursor-pointer"
											:class="{ 'border-b-2 border-primary text-gray': activePage === QUOTATION_APPROVAL, 'text-gray-light hover:text-gray': activePage !== QUOTATION_APPROVAL }"
											>Approval</h4>
									</router-link>
									<router-link :to="`/${PATH_ROOT}/${QUOTATION_LOI}/${paramQuotationId}`">
										<h4 v-if="loiDetailShow"
											class="px-6 py-3 text-base text-center cursor-pointer"
											:class="{ 'border-b-2 border-primary text-gray': activePage === QUOTATION_LOI, 'text-gray-light hover:text-gray': activePage !== QUOTATION_LOI }"
											>LOI</h4>
									</router-link>
									<router-link :to="`/${PATH_ROOT}/${QUOTATION_SPP}/${paramQuotationId}`">
										<h4 
											v-if="sppShow"
											class="px-6 py-3 text-base text-center cursor-pointer"
											:class="{ 'border-b-2 border-primary text-gray': activePage === QUOTATION_SPP, 'text-gray-light hover:text-gray': activePage !== QUOTATION_SPP }"
											>SPP</h4>
									</router-link>
								</div>
							</div>
						</div>
						<slot></slot>
					</div>
				</div>
			</div>
			<div :class="{'hidden': !showLog && minimizeLog, 'w-full': minimizeLog || windowWidth < 1024, 'w-1/3': !minimizeLog && windowWidth >= 1024}">
				<div :class="{
					'bg-black-60 backdrop-filter backdrop-blur-sm h-full overflow-auto': showLog ,
					'sticky top-4 z-50': !showLog,
					'fixed left-0 top-0 z-50': showLog,
					'w-full': minimizeLog,
				}">
					<div
						class="flex justify-center mt-0"
						:class="{
							'small-h-log': windowHeight >= 800 && !minimizeLog,
							'large-h-log': windowHeight < 800,
							'h-full': showLog,
						}">
						<div 
							:class="{
								'w-full': windowWidth > 1024 && !minimizeLog,
								'w-11/12 my-6': showLog,
							}">
							<ProjectLogRfq :windowWidth="windowWidth" :showLog="showLog" :minimizeLog="minimizeLog"
								@click="changeShowLog" @clickMinimize="changeMinimizeLog"/>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Spp :isOpen="isOpenModal" @toggleModal="closeModalSpp" :detailProject="detailProject" />
	</div>
</template>

<script>
import ProjectLogRfq from '@/core/components/project-log/ProjectLogRfq.vue';
import { PATH_ROOT, QUOTATION_DETAILS, QUOTATION_NEGOTIATION, QUOTATION_APPROVAL, QUOTATION_LOI, QUOTATION_SPP } from '@/core/constant/routeName'
import { STAFF_ITP_02_AWAITING_QUOTATION, PROCUREMENT_PROCESS, LOI_AWAITING_FOR_CREATION, LOI_SUBMITTED, LOI_AWAITING_FOR_REVISION, CANCELED, COMPLETED } from '@/core/constant/statusProject'
import Spp from '@/core/components/dashboard/Spp.vue';

export default {
	name: "LayoutQuotationDetail",
	data() {
		return {
			showLog: false,
			minimizeLog: false, 
			windowWidth: window.innerWidth,
			windowHeight: window.innerHeight,
			QUOTATION_DETAILS, 
			QUOTATION_NEGOTIATION, 
			QUOTATION_APPROVAL,
			QUOTATION_LOI,
			QUOTATION_SPP,
			PATH_ROOT,
			detailProject: {},
			isOpenModal: false
		}
	},
	
	computed: {
		activePage: {
			get() {
				return this.$store.state.quotationDetail.activePage
			},
			set(value) {
				this.$store.commit('quotationDetail/SET_ACTIVE_PAGE', value)
			}
		},
		paramQuotationId: {
			get() {
				return this.$store.state.quotationDetail.paramQuotationId
			},
			set(value) {
				this.$store.commit('quotationDetail/SET_PARAM_QUOTATION_ID', value)
			}
		},
		quotationDetailContainerWidth: {
			get() {
				return this.$store.state.width.quotationDetailContainerWidth
			},
			set(value) {
				this.$store.commit('width/SET_QUOTATION_DETAIL_CONTAINER_WIDTH', value)
			}
		},
		filterProject: {
			get() {
				return this.$store.state.dashboard.filterProject
			},
			set(val) {
				this.$store.commit('dashboard/SET_FILTER_PROJECT', val)
			}
		},
		projectLogRfq() {
			return this.$store.state.projectLog.projectLogRfq
		},
		projectCanceledByLogs() {
			return this.projectLogRfq.rfqLogs.some(e => e.statusProject === CANCELED)
		},
		projectCanceled() {
			return this.sppProcess || this.projectCanceledByLogs
		},
		projectCanceledCompletedbyLogs() {
			return this.projectLogRfq.rfqLogs.some(e => e.statusProject === CANCELED || e.statusProject === COMPLETED)
		},
		projectCanceledCompleted() {
			return this.sppProcess || this.projectCanceledCompletedbyLogs
		},
		sppProcess() {
			return this.$store.state.sppRfq.sppProcess
		},
		pradetailShow() {
			const statusProject = [STAFF_ITP_02_AWAITING_QUOTATION]
			return this.projectLogRfq?.rfqLogs.some(e => statusProject.includes(e.statusProject))
		},
		negotiationShow() {
			const statusProject = [ STAFF_ITP_02_AWAITING_QUOTATION]
			const statusProjectNegotiation = this.projectLogRfq?.rfqLogs.some(e => statusProject.includes(e.statusProject))
			return this.pradetailShow && (!this.projectCanceled || statusProjectNegotiation)
		},
		memoDetailShow() {
			const statusProject = [ PROCUREMENT_PROCESS ]
			const statusProjectMemo = this.projectLogRfq?.rfqLogs.some(e => statusProject.includes(e.statusProject))
			return this.pradetailShow && (!this.projectCanceled || statusProjectMemo)
		},
		loiDetailShow() {
			const statusProjectLoi = [ LOI_AWAITING_FOR_CREATION, LOI_AWAITING_FOR_REVISION ]
			const statusProjectLoiSubmitted = [ LOI_SUBMITTED ]
			const statusProjectLoiDetailShow = this.projectLogRfq?.rfqLogs?.filter(e => statusProjectLoi.includes(e.statusProject))?.some(e => e.additionalLogs?.some(el => statusProjectLoiSubmitted.includes(el.statusProject)))
			return this.pradetailShow && (!this.projectCanceled || statusProjectLoiDetailShow)
		},
		sppShow() {
			return ((!this.$store.state.sppRfq.dataSpp?.isNew && this.menuRfqSpp?.canCreate) || this.$store.state.sppRfq.showSpp || this.sppProcess) && !this.projectLogRfq.rfqLogs.some(e => e.statusProject === COMPLETED)
		},
		menuRfqSpp() {
			return this.$store.getters['dashboard/menuRfqSpp']
		},
	},
	watch: {
		showLog(value) {
			if (value) {
				return document.querySelector("body").classList.add("overflow-hidden");
			}

			document.querySelector("body").classList.remove("overflow-hidden");
		},
		windowWidth() {
			this.settingLogByWidth()
		},
	},
	methods: {
		changeShowLog() {
			this.showLog = !this.showLog
		},
		changeMinimizeLog() {
			this.minimizeLog = !this.minimizeLog
			setTimeout(() => {
				this.quotationDetailContainerWidth = document.getElementById('quotation-detail-container')?.offsetWidth
			}, 100)
		},
		settingLogByWidth() {
			if (this.windowWidth >= 1024) {
				this.showLog = false
			} else {
				this.minimizeLog = true
			}
		},
		switchActivePage() {
			const paramQuotationId = this.$route.params.quotationId
			this.paramQuotationId = paramQuotationId
			switch (this.$route.path) {
				case `/${PATH_ROOT}/${QUOTATION_DETAILS}/${paramQuotationId}`:
					this.activePage = QUOTATION_DETAILS
					break;
				case `/${PATH_ROOT}/${QUOTATION_NEGOTIATION}/${paramQuotationId}`:
					this.activePage = QUOTATION_NEGOTIATION
					break;
				case `/${PATH_ROOT}/${QUOTATION_APPROVAL}/${paramQuotationId}`:
					this.activePage = QUOTATION_APPROVAL
					break;
				case `/${PATH_ROOT}/${QUOTATION_LOI}/${paramQuotationId}`:
					this.activePage = QUOTATION_LOI
					break;
				case `/${PATH_ROOT}/${QUOTATION_SPP}/${paramQuotationId}`:
					this.activePage = QUOTATION_SPP
					break;
			}
		},
		async clickBackToProject() {
			const query = await this.$store.dispatch('dashboard/setQueryBacktoHome')
			this.$router.push({ path: `/${PATH_ROOT}`, query }).catch(() => ({}))
		},
		setWidthHeight() {
			this.quotationDetailContainerWidth = document.getElementById('quotation-detail-container')?.offsetWidth
			this.windowWidth = window.innerWidth
			this.windowHeight = window.innerHeight
		},
		async checkAccess() {
			const res = await this.$store.dispatch('projectLog/getCheckAccess', { projectRequestId: this.$route.params.quotationId, projectRequestType: 'rfq' })
			if (res?.data.code === 400) {
				this.detailProject = JSON.parse(res?.data.message)
				this.isOpenModal = true
				return
			}
		},
		closeModalSpp() {
			this.isOpenModal = false
			this.routerPushQuery()
		},
		async routerPushQuery() {
			const query = await this.$store.dispatch('dashboard/setQueryBacktoHome')
			this.$router.push({ path: `/${PATH_ROOT}`, query }).catch(() => ({}))
		},
	},
	mounted() {
		this.quotationDetailContainerWidth = document.getElementById('quotation-detail-container')?.offsetWidth
		window.addEventListener('resize', () => this.setWidthHeight())
		this.settingLogByWidth()
		this.switchActivePage()
		this.checkAccess()
	},
	beforeUpdate() {
		this.switchActivePage()
	},
	destroyed() {
		this.activePage = QUOTATION_DETAILS
		this.$store.commit('loiRfq/SET_SHOW_NEED_LOI', false)
		this.$store.dispatch('procurementApprovalRfq/resetStateProcurement')
		window.removeEventListener('resize', () => this.setWidthHeight)
	},
	components: { 
		ProjectLogRfq, 
		Spp
	}
}
</script>

<style scoped>
.large-h-log {
	height: calc(100vh - 10rem);
}
.small-h-log {
	height: 40rem;
}
.min-h-layout {
	min-height: 42rem;
}
</style>