<template>
	<div class="w-full h-full">
		<div class="w-full h-full bg-white rounded-2xl shadow-gray-sm">
			<div
				class="relative flex items-center justify-center w-full h-12 border-b border-gray-lightest shadow-gray-sm">
				<h5 class="text-base font-semibold text-gray">PROJECT LOG</h5>
				<div class="absolute right-6">
					<span v-if="windowWidth >= 1024 && !showLog" class="mr-3 text-lg cursor-pointer icon-minimize text-gray hover:text-gray-dark" @click=clickMinimizeLog></span>
					<span v-if="minimizeLog"
						@click="closeProjectLog"
						class="text-xl font-semibold cursor-pointer icon-x"></span>
				</div>
			</div>
			<div class="flex flex-col justify-between height-log-status">

				<div class="flex flex-col-reverse w-full overflow-auto" :style="`height: calc(100% - ${heightApproval}px)`">
					<div class="w-full p-4 ">
						<ul v-if="!loading.loadingProjectLog">
							<div>
								<li v-for="(n, i) in projectLogRfq?.rfqLogs"
									:key="i" class="h-full transition duration-500 transform ease-custom">
									<div v-if="i === projectLogRfq?.rfqLogs.length - 1 "
										class="flex h-full">
										<StepLine :lastIndex="true" :lineFull="false" :isInProgress="n.isInProgress"></StepLine>
										<div class="ml-4 mt-4 border border-primary shadow-primary-lg p-2.5 rounded-xl items-start w-full">
											<h5 class="text-sm font-semibold text-primary">{{ n.createdAt }}</h5>
											<div class="">
												<label class="font-medium break-words text-success">{{ n.currentStatus }}</label>
											</div>
											<div v-if="n.notes" class="mt-1">
												<p v-if="n.notes.length > 34" class="text-sm font-medium break-words whitespace-pre-wrap text-gray">Reason: {{n.notes.slice(0,20)}}... <span class="cursor-pointer text-secondary" @click="readMore(n.notes)">Read More</span></p>
												<p v-else class="text-sm font-medium break-words whitespace-pre-wrap text-gray">Reason: {{n.notes}}</p>
											</div>
											<div v-if="n?.additionalLogs?.length > 0">
												<button @click="changeCollapseNegotiation(i)" class="text-tertiary hover:text-secondary">{{ collapseText(i) }}</button>
											</div>
										</div>
									</div>
									<div v-else
										class="flex h-full"
										>
										<StepLine :lastIndex="false" :firstIndex="i === 0" :lineFull="i !== 0" :isInProgress="n.isInProgress"></StepLine>
										<div class="w-full ml-4 py-2.5 border-b border-gray-lightest">
											<h5 class="text-sm font-semibold text-gray-light">{{ n.createdAt }}</h5>
											<div class="">
												<label class="font-medium break-words text-gray">{{ n.currentStatus }}</label>
											</div>
											<div v-if="n.notes" class="mt-1">
												<p v-if="n.notes.length > 34" class="text-sm font-medium break-words whitespace-pre-wrap text-gray">Reason: {{n.notes.slice(0,20)}}... <span class="cursor-pointer text-secondary" @click="readMore(n.notes)">Read More</span></p>
												<p v-else class="text-sm font-medium break-words whitespace-pre-wrap text-gray">Reason: {{n.notes}}</p>
											</div>
											<div v-if="n?.additionalLogs?.length > 0">
												<button @click="changeCollapseNegotiation(i)" class="text-tertiary hover:text-secondary">{{ collapseText(i) }}</button>
											</div>
										</div>
									</div>
									<transition
										enter-active-class="transition duration-500 ease-in transform"
										enter-class="scale-y-0 -translate-y-1/2 opacity-0"
										enter-to-class="scale-y-100 translate-y-0 opacity-100"
										leave-active-class="transition duration-300 ease-in transform"
										leave-class="scale-y-100 translate-y-0 opacity-100"
										leave-to-class="scale-y-0 -translate-y-1/2 opacity-0"
										>
										<div v-if="n?.additionalLogs?.length > 0 && n?.toggleExpand">
											<div v-for="(add, j) in n.additionalLogs" :key="j">
												<div class="flex h-full pl-6">
													<StepLine :lastIndex="false" :firstIndex="j === 0" :lineFull="j !== 0 && j !== n.additionalLogs.length - 1" :noLine="n.additionalLogs.length === 1" :isInProgress="add.isInProgress"></StepLine>
													<div class="w-full ml-4 py-2.5 border-b border-gray-lightest">
														<h5 class="text-sm font-semibold text-gray-light">{{ add?.modifiedAt }}</h5>
														<div class="">
															<label class="font-medium break-words text-gray">{{ add?.description }}</label>
															<div v-if="add?.notes" class="mt-1">
																<p v-if="add?.notes.length > 34" class="text-sm font-medium break-words whitespace-pre-wrap text-gray">Reason: {{add.notes.slice(0,20)}}... <span class="cursor-pointer text-secondary" @click="readMore(add.notes)">Read More</span></p>
																<p v-else class="text-sm font-medium break-words whitespace-pre-wrap text-gray">Reason: {{add.notes}}</p>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</transition>
									<div v-if="i !== projectLogRfq?.rfqLogs.length - 1 && n.textApproval"
										class="flex h-full">
										<StepLine :lastIndex="false" :firstIndex="i === 0" :lineFull="i !== 0" :isInProgress="n.isInProgress"></StepLine>
										<div class="w-full ml-4 py-2.5 border-b border-gray-lightest">
											<h5 class="text-sm font-semibold text-gray-light">{{ n?.textApproval.split('\n')[0] }}</h5>
											<div class="">
												<label class="font-medium break-words text-gray">{{ n?.textApproval.split('\n')[1] }}</label>
											</div>
											<div v-if="n.textApprovalReason" class="mt-1">
												<p v-if="n.textApprovalReason.length > 34" class="text-sm font-medium break-words whitespace-pre-wrap text-gray">Reason: {{n.textApprovalReason.slice(0,20)}}... <span class="cursor-pointer text-secondary" @click="readMore(n.textApprovalReason)">Read More</span></p>
												<p v-else class="text-sm font-medium break-words whitespace-pre-wrap text-gray">Reason: {{n.textApprovalReason}}</p>
											</div>
										</div>
									</div>
								</li>
							</div>
						</ul>
						<LoadingProjectLog v-else/>
					</div>
				</div>
				<ModalReadMore @click="closeModal" :isOpen="readMoreShow" :text="notes"/>
				<div class="flex flex-col justify-end" id="heightApproval">
					<ApprovalRfq v-if="menuRfqDetailsApprove?.canUpdate && currentStatusProjectRFQ && usernameRFQ && !projectCanceledCompleted && detailPage && !someInProgress && !loading.loadingProjectLog"
						:roleGsitUkkp="roleGsitUkkp"
						@refetchProjectList="refetchProjectList" />
					<CancelEditRfq v-if="menuRFQ?.canUpdate && !projectCanceledCompleted && detailPage && !someInProgress && !loading.loadingProjectLog"
						@refetchProjectList="refetchProjectList"/>
					<CancelRfq v-if="!projectCanceledCompleted && !someInProgress && !loading.loadingProjectLog"
						@refetchProjectList="refetchProjectList"/>
					<UpdateApprove v-if="updateApproverShow && !projectCanceledCompleted && menuRfqDetailsUpdateApprover?.canUpdate && detailPage && !someInProgress && !loading.loadingProjectLog" 
						@refetchProjectList="refetchProjectList"/>
					<RfqFptnApproval @rejected="getNegotiationList" @refetch="refetchProjectLog" v-if="!someInProgress && !projectCanceledCompleted && !loading.loadingProjectLog"/>
					<RfqBanApproval @rejected="getNegotiationList" @refetch="refetchProjectLog" v-if="!someInProgress && !projectCanceledCompleted && !loading.loadingProjectLog"/>
					<RfqChangeVerificator @refetch="refetchProjectLog" v-if="!someInProgress && !projectCanceledCompleted && !loading.loadingProjectLog"/>
					<RfqEditLoi v-if="!someInProgress && !projectCanceledCompleted && !loading.loadingProjectLog"/>
					<RfqLoiApproval v-if="!someInProgress && !projectCanceledCompleted && !loading.loadingProjectLog"/>
					<RfqLoiChangeApprover v-if="!someInProgress && !projectCanceledCompleted && !loading.loadingProjectLog"/>
					<RfqLoiChangeRequester v-if="!someInProgress && !projectCanceledCompleted && !loading.loadingProjectLog"/>
					<EditProcurementApprovalRfq v-if="!someInProgress && !projectCanceledCompleted && !loading.loadingProjectLog" />
					<ApprovalChangeApproverRfq v-if="!someInProgress && !projectCanceledCompleted && !loading.loadingProjectLog" />
					<UploadApprovalRejectRfq v-if="!someInProgress && !projectCanceledCompleted && !loading.loadingProjectLog" />
					<ApprovalChangeRequesterRfq v-if="!someInProgress && !projectCanceledCompleted && !loading.loadingProjectLog" />
					<ProcurementApproveRejectRfq v-if="!someInProgress && !projectCanceledCompleted && !loading.loadingProjectLog" />
					<SppChangeApproverRfq v-if="!someInProgress && !projectCanceledCompletedbyLogs && !loading.loadingProjectLog" />
					<SppApprovalRfq v-if="!someInProgress && !projectCanceledCompletedbyLogs && !loading.loadingProjectLog" />
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import CancelRfq from '@/core/components/project-log/canceledit/CancelRfq.vue';
import CancelEditRfq from '@/core/components/project-log/canceledit/CancelEditRfq.vue';
import ApprovalRfq from '@/core/components/project-log/approval/ApprovalRfq.vue';
import UpdateApprove from '@/core/components/project-log/canceledit/UpdateApprove.vue';
import RfqFptnApproval from '@/core/components/project-log/negotiation/RfqFptnApproval.vue'
import RfqBanApproval from '@/core/components/project-log/negotiation/RfqBanApproval.vue'
import RfqChangeVerificator from '@/core/components/project-log/negotiation/RfqChangeVerificator.vue'
import LoadingProjectLog from '@/core/components/project-log/LoadingProjectLog.vue';
import StepLine from '@/core/components/project-log/StepLine.vue'
import { GSIT, KP, LOG, OPR, SOLUTIP_SPV_LOG, SOLUTIP_SPV1_LOG, SOLUTIP_SPV2_LOG, SOLUTIP_SPV3_LOG } from '@/core/constant/applicationRoleName.js'
import { GSIT_01_AWAITING_APPROVAL, UKKP_01_AWAITING_APPROVAL, USER_01_AWAITING_APPROVAL, SPV_ITP_01_AWAITING_APPROVAL, STAFF_ITP_01_AWAITING_APPROVAL, CANCELED, COMPLETED, SPP_PROCESS} from '@/core/constant/statusProject'
import ModalReadMore from '@/core/components/modal/ReadMore.vue'
import RfqEditLoi from '@/core/components/project-log/sktLoi/RfqEditLoi.vue';
import RfqLoiApproval from '@/core/components/project-log/sktLoi/RfqLoiApproval.vue';
import RfqLoiChangeApprover from '@/core/components/project-log/sktLoi/RfqLoiChangeApprover.vue';
import RfqLoiChangeRequester from '@/core/components/project-log/sktLoi/RfqLoiChangeRequester.vue';
import EditProcurementApprovalRfq from '@/core/components/project-log/procurementApproval/EditProcurementApprovalRfq.vue'
import ApprovalChangeApproverRfq from '@/core/components/project-log/procurementApproval/ApprovalChangeApproverRfq.vue'
import UploadApprovalRejectRfq from '@/core/components/project-log/procurementApproval/UploadApprovalRejectRfq.vue'
import ApprovalChangeRequesterRfq from '@/core/components/project-log/procurementApproval/ApprovalChangeRequesterRfq.vue'
import ProcurementApproveRejectRfq from '@/core/components/project-log/procurementApproval/ProcurementApproveRejectRfq.vue';
import SppChangeApproverRfq from '@/core/components/project-log/spp/SppChangeApproverRfq.vue'
import SppApprovalRfq from '@/core/components/project-log/spp/SppApprovalRfq.vue'
export default {
	name: "ProjectLogRfq",
	props: ['windowWidth', 'showLog', 'minimizeLog'],
	data() {
		return {
			heightApproval: document.getElementById('heightApproval')?.offsetHeight,
			notes: '',
			readMoreShow: false,
		}
	},
	computed: {
		modal: {
			get() {
				return this.$store.state.modal.modal
			},
			set(value) {
				this.$store.commit('modal/SET_MODAL', value)
			}
		},
		userDetail() {
				return this.$store.state.dashboard.userProfile;
		},
		//showing button/component approve based roleName
		roleGsitUkkp() {
      const roleName = this.menu[0]?.applicationRoleName
			return (roleName?.includes(GSIT) || roleName?.includes(KP)) && !roleName?.includes(OPR) && !roleName?.includes(LOG)
    },
		roleGsit() {
			const roleName = this.menu[0]?.applicationRoleName
			return roleName?.includes(GSIT) && !roleName?.includes(OPR)
		},
		roleUkkp() {
			const roleName = this.menu[0]?.applicationRoleName
			return roleName?.includes(KP) && !roleName?.includes(OPR)
		},
    roleSpvItp() {
      const roleName = this.menu[0]?.applicationRoleName
      return roleName?.includes(LOG) && !roleName?.includes(OPR)
    },
		roleOprItp() {
			const roleName = this.menu[0]?.applicationRoleName
      return roleName?.includes(LOG) && roleName?.includes(OPR)
		},
		roleUser() {
			const roleName = this.menu[0]?.applicationRoleName
			return roleName?.includes(OPR) && !roleName?.includes(LOG)
		},
		currentStatusProjectRFQ() {
			const roleName = this.menu[0]?.applicationRoleName
			const spvItp = [SOLUTIP_SPV_LOG, SOLUTIP_SPV1_LOG, SOLUTIP_SPV2_LOG, SOLUTIP_SPV3_LOG]
			if (this.roleGsit) {
				return this.rfqLogsCurrentStatus === USER_01_AWAITING_APPROVAL 
			} else if (this.roleUkkp) {
				return this.rfqLogsCurrentStatus === USER_01_AWAITING_APPROVAL
			} else if (this.roleSpvItp) {
				return this.rfqLogsCurrentStatus === SPV_ITP_01_AWAITING_APPROVAL && spvItp.includes(roleName)
			} else if (this.roleOprItp) {
				return this.rfqLogsCurrentStatus === STAFF_ITP_01_AWAITING_APPROVAL
			} else {
				return false
			}
		},
		updateApproverShow() {
			const statusProject = [GSIT_01_AWAITING_APPROVAL, UKKP_01_AWAITING_APPROVAL, USER_01_AWAITING_APPROVAL]
			return statusProject.includes(this.rfqLogsCurrentStatus)
		},
		projectCanceledCompletedbyLogs() {
			return this.projectLogRfq.rfqLogs.some(e => e.statusProject === CANCELED || e.statusProject === COMPLETED)
		},
		projectCanceledCompleted() {
			return this.sppProcess || this.projectCanceledCompletedbyLogs
		},
		sppProcess() {
			return this.$store.state.sppRfq.sppProcess
		},
		usernameRFQ() {
			const approverUsername = this.rfqLogsCurrentFlow?.approverUsername
			return this.userDetail?.username?.toLowerCase() === approverUsername?.toLowerCase() || this.roleOprItp || this.roleSpvItp
		},
		menu() {
			return this.$store.state.dashboard.menu
		},
		menuRFQ() {
			return this.$store.getters['dashboard/menuRFQ']
		},
		menuRfqDetailsApprove() {
			return this.$store.getters['dashboard/menuRfqDetailsApprove']
		},
		menuRfqDetailsReject() {
			return this.$store.getters['dashboard/menuRfqDetailsReject']
		},
		menuRfqDetailsUpdateApprover() {
			return this.$store.getters['dashboard/menuRfqDetailsUpdateApprover']
		},
		detailPage() {
      return this.$route.path.includes('detail')
    },
		projectLogRfq: {
			get() {
				return this.$store.state.projectLog.projectLogRfq
			},
			set(val) {
				this.$store.commit('projectLog/SET_PROJECT_LOG_RFQ', val)
			}
		},
		projectLogRfqInitial() {
			return this.$store.state.projectLog.projectLogRfqInitial
		},
		rfqLogId() {
      return this.$store.state.projectLog.rfqLogId
		},
		rfqLogsCurrentStatus() {
			return this.rfqLogsCurrentFlow?.statusProject
		},
		rfqLogsCurrentFlow() {
			const rfqLogs = this.projectLogRfq?.rfqLogs
			const logsLength = rfqLogs.length
			if (logsLength > 0) {
				if(rfqLogs[logsLength - 1]?.statusProject === SPP_PROCESS) return rfqLogs[logsLength - 2]
				return rfqLogs[logsLength - 1]
			}
			return false
		},
		someInProgress() {
			return this.projectLogRfq?.rfqLogs?.some(e => e.isInProgress || e.additionalLogs?.some(el => el.isInProgress))
		},
		projectLogStatus() {
      return this.$store.state.projectLog.projectLogStatus
    },
		projectId() {
      return this.$store.state.projectLog.projectId
		},
		loading: {
			get() {
				return this.$store.state.modal.loading
			},
			set(value) {
				this.$store.commit('modal/SET_LOADING', value)
			}
		},
		negotiationDetail(){
			return this.$store.state.negotiationRfq.negotiationDetail
		},
		counterInterval() {
			return this.$store.state.projectLog.counterInterval
		},
	},
	methods: {
		closeProjectLog() {
			this.$emit('click')
		},
		clickMinimizeLog() {
			this.$emit('clickMinimize')
		},
		changeCollapseNegotiation(i) {
			this.projectLogRfq.rfqLogs[i].toggleExpand = !this.projectLogRfq.rfqLogs[i].toggleExpand
		},
		collapseText(i) {
			return this.projectLogRfq?.rfqLogs[i].toggleExpand? 'collapse': 'extend'
		},
		closeModal() {
			this.readMoreShow = false
		},
		readMore(notes) {
			this.notes = notes
			this.readMoreShow = true
		},
		refetchProjectList() {
			this.$store.dispatch('projectLog/refetchProjectLogStatus')
    },
		refetchProjectLog() {
			this.$store.dispatch('projectLog/refetchProjectLogByRfqId', this.$route.params.quotationId)
		},
		getNegotiationList() {
      setTimeout(() => {
				console.log('hit set timeout getNegotiationList');
        this.$store.dispatch('negotiationRfq/getNegotiationList', {rfqId:this.$route.params.quotationId})
			}, 5000);
		}
	},
	destroyed() {
		clearInterval(this.counterInterval)
		this.$store.commit('projectLog/SET_COUNTER_INTERVAL', null)
	},
	components: {
    CancelEditRfq,
    ApprovalRfq,
    UpdateApprove,
		StepLine,
		RfqFptnApproval,
		RfqBanApproval,
		RfqChangeVerificator,
		LoadingProjectLog,
		ModalReadMore,
		RfqEditLoi,
		RfqLoiApproval,
		RfqLoiChangeApprover,
		RfqLoiChangeRequester,
		EditProcurementApprovalRfq,
		ApprovalChangeApproverRfq,
		ApprovalChangeRequesterRfq,
		ProcurementApproveRejectRfq,
		UploadApprovalRejectRfq,
		CancelRfq,
		SppChangeApproverRfq,
		SppApprovalRfq
},
}
</script>

<style scoped>
.height-log-status {
	height: calc(100% - 3rem)
}
</style>