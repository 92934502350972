<template>
  <div
    class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-200 overflow-hidden bg-gray opacity-75 flex flex-col items-center justify-center"
    v-if="(loading = true)"
  >
    <div
      class="loader ease-linear rounded-full border-4 border-t-4 border-gray h-12 w-12 mb-4"
    ></div>
    <h2 class="text-center text-white text-xl font-semibold">
      {{ label }}
    </h2>
  </div>
</template>

<script>
export default {
  name: "LoadingSolutip",
  props: {
    label: {
      type: String,
      default: "Loading Data...",
    },
  },
};
</script>

<style scoped>
.z-200 {
  z-index: 200;
}
.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
