/* ===Base Components=== */
function registerBaseComponents(service) {
  const requireComponent = require.context("./components", false, /\.vue$/);
  requireComponent.keys().forEach((fileName) => {
    const componentConfig = requireComponent(fileName);
    service.Component.register(
      componentConfig.default || componentConfig,
      "base"
    );
  });
}

/* ===Layouts=== */
function registerLayouts(service) {
  const requireLayout = require.context("./layouts", true, /\.vue$/);
  requireLayout.keys().forEach((fileName) => {
    const componentConfig = requireLayout(fileName);
    service.Component.register(
      componentConfig.default || componentConfig,
      "layout"
    );
  });
}

export function registerGlobalComponents(service) {
  registerBaseComponents(service);
  registerLayouts(service);
}
