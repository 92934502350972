<template>
  <gista-modal
    width="1/2"
    :value="modal[nameModal].value"
    customClass="max-w-2xl bg-white"
    :scrollable="true"
    @input="closeModal"
  >
    <div class="w-full h-80 p-6 flex flex-col justify-center">
      <div class="flex justify-center mb-6">
        <img v-if="kind === 'submitted'" src="@/core/assets/icons/message/submitted.svg" alt="error" class="w-28"/>
        <img v-if="kind === 'saved'" src="@/core/assets/icons/message/saved.svg" alt="error" class="w-28"/>
        <img v-if="kind === 'approved'" src="@/core/assets/icons/message/approved.svg" alt="error" class="w-28"/>
      </div>
      <div class="flex justify-center">
        <p class="text-gray text-xl font-semibold text-center">{{modal[nameModal].title}}</p>
      </div>
    </div>
  </gista-modal>
</template>

<script>

  export default {
    name: "ModalSuccess",
    props: {
      nameModal: String,
      kind: {
        type: String,
        default: 'submitted'
      },
    },
    data() {
      return {
        isOpen: false,
      }
    },
    computed: {
      modal: {
        get() {
          return this.$store.state.modal.modal
        },
        set(value) {
          this.$store.commit('modal/SET_MODAL', value)
        }
      }
    },
    watch: {
    },
    methods: {
      click() {
        this.$emit("click")
        this.modal[this.nameModal].value = false
      },
      closeModal() {
        this.modal[this.nameModal].value = false
        this.modal[this.nameModal].title = ''
      }
    }
  }
</script>
