/* eslint-disable */
import { ENDPOINT_LIST_VENDORS, ENDPOINT_VENDORS, ENDPOINT_SEND_RFI } from '@/core/constant/menuEndpoint'
export const Store = {
	namespaced: true,

	// nama module, untuk call di component menggunakan "example/<nama_method>"
	name: 'rfi',

	state: {
    formRfi: {
      noRfi: "",
      date: '',
      vendorId: "",
      vendorName: "",
      vendorEmail: "",
      isNewVendor: false,
      staffItpId: "",
      staffItpName: "",
      staffItpEmail: "",
      phone: "",
      ext: "",
      isSendItp01: true,
      isSendItp02: true,
      isSendItp03: true,
      isSendChecklist: true,
      isSendPendaftaran: true,
      isSendPernyataan: true,
      isSendDueDilligence: true,
      isEnglish: false,
      notes: "",
      reference: "",
      noRfp: "",
      projectName: ""
    },
    vendor: [],
    vendorRfiList: {
      vendorList: [],
      pageSize: 10,
      pageNumber: 0,
      totalElements: 10,
      totalPages: 1
    }
	},

	getters: {},

	mutations: {
    SET_FORM_RFI(state, payload) {
      state.formRfi = payload
    },
    SET_VENDOR(state, payload) {
      state.vendor = payload
    },
    SET_VENDOR_RFI_LIST(state, payload) {
      state.vendorRfiList = payload
    }
	},

	actions: {
    async getVendor({commit, dispatch}, payload) {
      try {
        const { vendorId } = payload
        const response = await this.$api.get(ENDPOINT_VENDORS, { vendorId })
        commit('SET_VENDOR', response.data.result)
        return response
      } catch (error) {
        commit('SET_VENDOR', [])
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async getVendorRfiList({commit, dispatch}, payload) {
      try {
        const page = payload?.page || 0
        const size = payload?.size || 10
        const sort = payload?.sort || ''
        const anySearch = payload?.anySearch || ''
        const itp01 = payload?.itp01 || ''
        const response = await this.$api.get(ENDPOINT_LIST_VENDORS, { page, size, sort, anySearch, itp01 })
        commit('SET_VENDOR_RFI_LIST', response.data.result)
        return response
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async postSendRfi({ dispatch, state }, payload) {
      try {
        const data = {
          noRfi: state.formRfi.noRfi,
          date: state.formRfi.date,
          vendorId: state.formRfi.vendorId,
          vendorName: state.formRfi.vendorName,
          vendorEmail: state.formRfi.vendorEmail,
          isNewVendor: state.formRfi.isNewVendor,
          staffItpId: state.formRfi.staffItpId,
          staffItpName: state.formRfi.staffItpName,
          staffItpEmail: state.formRfi.staffItpEmail,
          phone: state.formRfi.phone,
          ext: state.formRfi.ext,
          isSendItp01: state.formRfi.isSendItp01,
          isSendItp02: state.formRfi.isSendItp02,
          isSendItp03: state.formRfi.isSendItp03,
          isSendChecklist: state.formRfi.isSendChecklist,
          isSendPendaftaran: state.formRfi.isSendPendaftaran,
          isSendPernyataan: state.formRfi.isSendPernyataan,
          isSendDueDilligence: state.formRfi.isSendDueDilligence,
          isEnglish: state.formRfi.isEnglish,
          notes: state.formRfi.notes,
          reference: state.formRfi.reference,
          noRfp: state.formRfi.noRfp,
          projectName: state.formRfi.projectName
        }
        return await this.$api.post(ENDPOINT_SEND_RFI, data)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    }
	}
}