<template>
  <div v-if="(showChangeVerificator) && menuRfqNegotiationVerificatorChange">
		<div class="w-full p-4">
			<div class="">
				<ButtonGista type="secondary"
					color="primary"
					@click="handleOpen"
					customClass="w-full">
					Change Verificator</ButtonGista>
			</div>
		</div>
		<GistaModal :value="isOpen"
			customClass="max-w-2xl bg-white"
			@input="handleClose">
			<ValidationObserver v-slot="{ handleSubmit }">
				<div class="w-full p-5">
					<div class="flex justify-center w-full mb-4">
						<h5 class="text-lg font-semibold text-gray">Change Verificator</h5>
					</div>
					<div class="w-full mb-4">
						<ValidationProvider name="newVerificator"
							rules="required"
							v-slot="{ errors }">
							<AutocompleteNewSolutip
								label="New Verificator"
								placeholder="(Search)"
								:options="approverNegotiation"
								@updateInput="inputSearchVerificator($event)"
								@updateAutoComplete="changeVerificator($event)"
								@blur="blurVerificator"
								@focus="focusVerficator"
								v-model="newVerificatorNameLocalState"
								:isLoading="isLoading"
								:error="!!errors[0]"
								textField="name"
                textField2="jobTitle"
								iconLeft="icon-search text-xl text-gray-light"
                :mandatory="true"
							>
								<template #message>
									<p class="mt-1 text-xs text-error">{{ errors[0] }}</p>
								</template>
							</AutocompleteNewSolutip>
						</ValidationProvider>
					</div>
          <div class="w-full mb-4">
						<ValidationProvider name="notes"
							rules="required|max:500"
							v-slot="{ errors }">
							<TextareaGista label="Notes"
								placeholder="Leave a comment here"
								v-model="form.notes"
								:error="!!errors[0]"
								:mandatory="true">
								<template #message>
									<p>{{ errors[0] }}</p>
								</template>
							</TextareaGista>
						</ValidationProvider>
					</div>
					<div class="flex justify-end">
						<ButtonGista type="primary"
							color="success"
							@click="handleSubmit(clickChangeVerificator)"
							customClass="w-full sm:w-56">
							Change Verificator
						</ButtonGista>

					</div>
				</div>
			</ValidationObserver>
		</GistaModal>
	</div>
</template>
<script>
import AutocompleteNewSolutip from '@/core/components/custom/AutocompleteNew'
import { MESSAGE_SUCCESS_CHANGE_VERIFICATOR } from '@/core/constant/successMessage'
 
export default {
  name: "RfqChangeVerificator",
  props: ["refetch"],
  data() {
    return {
      isOpen: false,
      newVerificatorNameLocalState: '',
      isLoading: false,
      form: {
				newVerificatorName: '',
				newVerificatorId: '',
        notes: '',
			},
    }
  },
  computed: {
    showChangeVerificator() {
      return (this.showDetailBan || this.showVendorDetails || this.showDetailFPTN) && !this.renegotiationProcess && this.isFinalDeal && this.isEnableChangeVerificator
    },
    isEnableChangeVerificator() {
      return this.negotiationDetail?.isEnableChangeVerificator
    },
    approverNegotiation() {
      return this.$store.state.negotiationRfq.approverNegotiation
    },
    banDetails() {
      return this.$store.state.negotiationRfq.banDetails
    },
    negotiationFPTN() {
      return this.$store.state.negotiationRfq.negotiationFPTN
    },
    showDetailBan() {
			return this.$store.state.negotiationRfq.showDetailBan
		},
    showDetailFPTN() {
			return this.$store.state.negotiationRfq.showDetailFPTN
		},
    showVendorDetails() {
			return this.$store.state.negotiationRfq.showVendorDetails
		},
    negotiationDetail() {
      return this.$store.state.negotiationRfq.negotiationDetail
    },
    renegotiationProcess() {
      return this.negotiationDetail?.isEnableRenegotiation
    },
    isFinalDeal() {
      return this.negotiationDetail?.negotiationStatus  === 'FINAL_DEAL'
    },
    isApproved() {
      return this.negotiationDetail?.logApprovalStatus  === 'Approved'
    },
    menu() {
			return this.$store.state.dashboard.menu
		},
    menuRfqNegotiationVerificatorChange() {
			return this.$store.getters['dashboard/menuRfqNegotiationVerificatorChange'].canUpdate
    },
    rfqApprover() {
      return this.$store.state.negotiationRfq.detailRFQNegoApprover
    },
    getCurrentApproval() {
      const {
        directApproverGsitStatus,
        directApproverItpStatus,
        directApproverUkkpStatus,
        itpVerificatorStatus,
        representGsitStatus,
        representUkkpStatus
      } = this.rfqApprover
      const approver = {
        directApproverGsitStatus,
        directApproverItpStatus,
        directApproverUkkpStatus,
        itpVerificatorStatus,
        representGsitStatus,
        representUkkpStatus
      }
      let current = ''
      for(let key in approver) {
        if(approver[key] === 'On Review') {
          current = this.getCurrentApproverParam(key)
        }
      }
      return current
    },
    projectLogRfq() {
			return this.$store.state.projectLog.projectLogRfq
		},
    modal: {
      get() {
        return this.$store.state.modal.modal
      },
      set(value) {
        this.$store.commit('modal/SET_MODAL', value)
      }
    },
  },
  methods: {
    async handleOpen() {
      this.isOpen = true
    },
    handleClose() {
      this.resetForm()
      this.isOpen = false
    },
    async inputSearchVerificator(e) {
      this.isLoading = true
      const offers = this.negotiationDetail?.offers
      const negotiationId = offers[offers.length - 1].negotiationId
      await this.$store.dispatch('negotiationRfq/getUserApproverNegotiation', { name: e, project: 'RFQ', negotiationId })
      this.isLoading = false
    },
    getCurrentApproverParam (key) {
      switch (key) {
        case 'itpVerificatorStatus': return 'ITP_VERIFICATOR';
        case 'directApproverItpStatus': return 'SPV_ITP_APPROVAL';
        case 'representGsitStatus': return 'REPRESENTATIVE_GSIT';
        case 'representUkkpStatus': return 'REPRESENTATIVE_UKKP';
        case 'directApproverGsitStatus': return 'SPV_GSIT_APPROVAL';
        case 'directApproverUkkpStatus': return 'SPV_UKKP_APPROVAL';
        default: return '';
      }
    },
    changeVerificator(e) {
      this.form.newVerificatorId = e.userName
      this.form.newVerificatorName = e.name
      this.newVerificatorNameLocalState = e.name
    },
    blurVerificator() {
      this.newVerificatorNameLocalState = this.form.newVerificatorName
    },
    async focusVerficator() {
      this.isLoading = true
      const offers = this.negotiationDetail?.offers
      const negotiationId = offers[offers.length - 1].negotiationId
      await this.$store.dispatch('negotiationRfq/getUserApproverNegotiation', { name: this.newVerificatorNameLocalState, project: 'RFQ', negotiationId })
      this.isLoading = false
    },
    resetForm() {
      this.form = {
        newVerificatorName: '',
				newVerificatorId: '',
        notes: '',
      }
      this.newVerificatorNameLocalState = ''
    },
    async clickChangeVerificator() {
      try {
        this.modal.modalLoading = true
        const newVerificator = this.form.newVerificatorId

        let negotiationId
        const isFptn = !this.negotiationDetail?.isNegotiationRequired
        if(this.showVendorDetails) {
          const offers = this.negotiationDetail?.offers
          negotiationId = offers[offers.length - 1].negotiationId
        } else negotiationId = isFptn ? this.negotiationFPTN.rfqNegotiationId : this.banDetails.rfqNegotiationId
        const response = await this.$store.dispatch('negotiationRfq/putChangeVerificator', { negotiationId, newVerificator, isFptn, note: this.form.notes })
        if (response?.status < 300 && response?.status >= 200) {
          this.modal.modalLoading = false
          this.handleClose()
          this.resetForm()
          this.$store.commit('projectLog/SET_RFQ_LOG_ID', this.projectLogRfq?.rfqLogs?.at(-1)?.rfqLogId)
          this.$emit("refetch")
          setTimeout(() => {
            this.$store.dispatch('negotiationRfq/getDetailRFQNegoApprover', { negotiationId })
          }, 3000)
          this.modal.modalSuccessSaved = { value: true, title: MESSAGE_SUCCESS_CHANGE_VERIFICATOR }
        } else {
          this.$store.dispatch('modal/throwError', { response })
        }
      } catch (error) {
        console.log(error);
        this.modal.modalLoading = false
        this.modal.modalError = { value: true, traceid: error.traceid, title: error.title, message: error.message }
      }
    }
  },
  components: {
    AutocompleteNewSolutip
  }
}
</script>