//konstanta menu Id
export const MENU_REPORT_CHART                                = 'SOL0010001'
export const MENU_REPORT_DOWNLOAD                             = 'SOL0010002'
export const MENU_DRAFT                                       = 'SOL0010003'


export const MENU_DASHBOARD                                   = 'SOL0010006'

export const MENU_RFP                                         = 'SOL0020001'
export const MENU_RFP_ACTION_SUBMIT                           = 'SOL0020002'
export const MENU_DETAILS_APPROVE                             = 'SOL0020003'
export const MENU_DETAILS_CANCEL                              = 'SOL0020004'
export const MENU_DETAILS_REJECT                              = 'SOL0020005'
export const MENU_RFP_DETAILS_VENDOR_CANDIDATE                = 'SOL0020006'
export const MENU_DETAILS_UPDATE_APPROVER                     = 'SOL0020007'

export const MENU_QUALIFICATION                               = 'SOL0020008'
export const MENU_QUALIFICATION_ACTION_DISQUALIFIED           = 'SOL0020009'
export const MENU_QUALIFICATION_ACTION_APPROVE                = 'SOL0020010'
export const MENU_QUALIFICATION_ACTION_REVISION               = 'SOL0020011'
export const MENU_QUALIFICATION_ACTION_ASK_REVISION           = 'SOL0020012'
export const MENU_QUALIFICATION_ACTION_SEND_RFP               = 'SOL0020013'
export const MENU_QUALIFICATION_ACTION_SEND_PROPOSAL          = 'SOL0020014'

export const MENU_SCORING_DECISION                            = 'SOL0020015'
export const MENU_SCORING_ESCROW                              = 'SOL0020016'
export const MENU_SCORING_TEKNIS                              = 'SOL0020017'

export const MENU_RFP_NEGOTIATION_SCORING_PRICE               = 'SOL0020018'  // used
export const MENU_RFP_NEGOTIATION_SCORING_PRICE_APPROVE       = 'SOL0020019'  // used
export const MENU_RFP_NEGOTIATION_SCORING_PRICE_LOG_APPROVAL  = 'SOL0020020'  // used

export const MENU_RFP_NEGOTIATION_SCORING_PRICE_SUBMIT        = 'SOL0020022'  // used

export const MENU_RFP_NEGOTIATION                             = 'SOL0020023'  // used
export const MENU_RFP_NEGOTIATION_DETAILS                     = 'SOL0020024'  // used
export const MENU_RFP_NEGOTIATION_DETAILS_BAN                 = 'SOL0020025'  // used

export const MENU_RFP_NEGOTIATION_EXPORT_XLSX                 = 'SOL0020027'  // used
export const MENU_RFP_NEGOTIATION_OFFER_DELETE                = 'SOL0020028'  // used
export const MENU_RFP_NEGOTIATION_OFFER_LIST                  = 'SOL0020029'  // used

export const MENU_RFP_NEGOTIATION_OFFER_REVISION              = 'SOL0020031'  // used
export const MENU_RFP_NEGOTIATION_UPLOAD_OFFER                = 'SOL0020032'  // used
export const MENU_RFP_NEGOTIATION_SCHEDULE_CONFIRM            = 'SOL0020033'  // used
export const MENU_RFP_NEGOTIATION_OFFER_GET                   = 'SOL0020034'  // used

export const MENU_RFP_NEGOTIATION_OFFER_SAVE                  = 'SOL0020036'  // used

export const MENU_RFP_NEGOTIATION_BAN                         = 'SOL0020038'  // used
export const MENU_RFP_NEGOTIATION_BAN_APPROVE                 = 'SOL0020039'  // used
export const MENU_RFP_NEGOTIATION_BAN_VERIFICATOR_CHANGE      = 'SOL0020040'  // used
export const MENU_RFP_NEGOTIATION_BAN_PREVIEW                 = 'SOL0020041'  // used

export const MENU_RFP_NEGOTIATION_BAN_REPORT                  = 'SOL0020043'  // used
export const MENU_RFP_NEGOTIATION_BAN_SUBMIT                  = 'SOL0020044'  // used
export const MENU_RFP_NEGOTIATION_RENEGOTIATION               = 'SOL0020045'  // used

export const MENU_RFP_SCORING_ATTACHMENT                      = 'SOL0020046'
export const MENU_RFP_SCORING_VENDOR_APPROVE                  = 'SOL0020047'
export const MENU_RFP_SCORING_VENDOR_REJECT                   = 'SOL0020048'
export const MENU_RFP_SCORING_VENDOR_APPROVAL                 = 'SOL0020049'
export const MENU_RFP_SCORING_VENDOR_CHANGE_APPROVAL          = 'SOL0020050'

export const MENU_RFP_SKT_LOI                                 = 'SOL0020052'
export const MENU_RFP_SKT_LOI_CANCEL                          = 'SOL0020053'
export const MENU_RFP_SKT_LOI_SUBMIT                          = 'SOL0020054'
export const MENU_RFP_SKT_LOI_SECURITY_CODE                   = 'SOL0020055'
export const MENU_RFP_SKT_LOI_APPROVAL                        = 'SOL0020056'
export const MENU_RFP_SKT_LOI_CHANGE_APPROVAL                 = 'SOL0020057'
export const MENU_RFP_SKT_LOI_CHANGE_REQUESTER                = 'SOL0020058'
export const MENU_RFP_SKT_PDF_PREVIEW                         = 'SOL0020059'
export const MENU_RFP_LOI_PDF_PREVIEW                         = 'SOL0020060'
export const MENU_RFP_SKT_LOI_CONFIRMATION                    = 'SOL0020061'

export const MENU_RFP_APPROVAL_PROCUREMENT                    = 'SOL0020062'
export const MENU_RFP_APPROVAL_LOG                            = 'SOL0020063'
export const MENU_RFP_APPROVAL_DELETE_DRAFT                   = 'SOL0020064'
export const MENU_RFP_APPROVAL_APPROVAL                       = 'SOL0020065'
export const MENU_RFP_APPROVAL_APPROVE_WITH_AGREEMENT         = 'SOL0020066'
export const MENU_RFP_APPROVAL_PREVIEW                        = 'SOL0020067'
export const MENU_RFP_APPROVAL_CHANGE_APPROVER                = 'SOL0020068'
export const MENU_RFP_APPROVAL_CHANGE_REQUESTER               = 'SOL0020069'
export const MENU_RFP_APPROVAL_SECURITY_CODE                  = 'SOL0020070'
export const MENU_RFP_APPROVAL_DETAIL                         = 'SOL0020071'

export const MENU_RFP_SPP                                     = 'SOL0020072'
export const MENU_RFP_SPP_APPROVAL                            = 'SOL0020073'
export const MENU_RFP_SPP_CANCEL                              = 'SOL0020074'
export const MENU_RFP_SPP_CHANGE_APPROVER                     = 'SOL0020075'
export const MENU_RFP_SPP_PREVIEW                             = 'SOL0020076'
export const MENU_RFP_SPP_SECURITY_CODE                       = 'SOL0020077'

export const MENU_RFQ                                         = 'SOL0030001'
export const MENU_RFQ_DETAILS_APPROVE                         = 'SOL0030003'  // used
export const MENU_RFQ_DETAILS_CANCEL                          = 'SOL0030004'  // used
export const MENU_RFQ_DETAILS_UPDATE_APPROVER                 = 'SOL0030005'  // used
export const MENU_RFQ_DETAILS_REJECT                          = 'SOL0030006'  // used
export const MENU_RFQ_DETAILS_SEND_RFQ                        = 'SOL0030007'  // used
export const MENU_RFQ_ACTION_SUBMIT                           = 'SOL0030008'  // used

export const MENU_RFQ_NEGOTIATION_REQUIRE                     = 'SOL0030010'  // used
export const MENU_RFQ_NEGOTIATION_SCHEDULE_CONFIRM            = 'SOL0030011'  // used
export const MENU_RFQ_NEGOTIATION_OFFER_UPLOAD                = 'SOL0030012'  // used

export const MENU_RFQ_NEGOTIATION_OFFER_GET                   = 'SOL0030014'  // used
export const MENU_RFQ_NEGOTIATION_OFFER_DELETE                = 'SOL0030015'  // used

export const MENU_RFQ_NEGOTIATION_OFFER_SAVE                  = 'SOL0030017'  // used
export const MENU_RFQ_NEGOTIATION_OFFER_DEAL                  = 'SOL0030018'  // used
export const MENU_RFQ_NEGOTIATION_OFFER_REVISION              = 'SOL0030019'  // used


export const MENU_RFQ_NEGOTIATION_BAN_APPROVE                 = 'SOL0030022'  // used

export const MENU_RFQ_NEGOTIATION_BAN                         = 'SOL0030021'  // used
export const MENU_RFQ_NEGOTIATION_BAN_PREVIEW                 = 'SOL0030024'  // used
export const MENU_RFQ_NEGOTIATION_BAN_REPORT                  = 'SOL0030025'  // used 
export const MENU_RFQ_NEGOTIATION_FPTN                        = 'SOL0030026'  // used
export const MENU_RFQ_NEGOTIATION_FPTN_APPROVE                = 'SOL0030027'  // used

export const MENU_RFQ_NEGOTIATION_FPTN_REPORT                 = 'SOL0030029'  // used
export const MENU_RFQ_NEGOTIATION_FPTN_SUBMIT                 = 'SOL0030030'  // used
export const MENU_RFQ_NEGOTIATION_RENEGOTIATION               = 'SOL0030031'  // used

export const MENU_RFQ_NEGOTIATION_VERIFICATOR_CHANGE          = 'SOL0030033'  // used

export const MENU_RFQ_LOI                                     = 'SOL0030035'
export const MENU_RFQ_LOI_SUBMIT                              = 'SOL0030036'
export const MENU_RFQ_LOI_CANCEL                              = 'SOL0030037'
export const MENU_RFQ_LOI_APPROVAL                            = 'SOL0030038'
export const MENU_RFQ_LOI_CHANGE_REQUESTER                    = 'SOL0030039'
export const MENU_RFQ_LOI_CHANGE_APPROVAL                     = 'SOL0030040'
export const MENU_RFQ_LOI_SECURITY_CODE                       = 'SOL0030041'
export const MENU_RFQ_LOI_PDF_PREVIEW                         = 'SOL0030042'
export const MENU_RFQ_LOI_CONFIRMATION                        = 'SOL0030043'

export const MENU_RFQ_APPROVAL_PROCUREMENT                    = 'SOL0030044'
export const MENU_RFQ_APPROVAL_LOG                            = 'SOL0030045'
export const MENU_RFQ_APPROVAL_DELETE_DRAFT                   = 'SOL0030046'
export const MENU_RFQ_APPROVAL_APPROVAL                       = 'SOL0030047'
export const MENU_RFQ_APPROVAL_APPROVE_WITH_AGREEMENT         = 'SOL0030048'
export const MENU_RFQ_APPROVAL_PREVIEW                        = 'SOL0030049'
export const MENU_RFQ_APPROVAL_CHANGE_APPROVER                = 'SOL0030050'
export const MENU_RFQ_APPROVAL_CHANGE_REQUESTER               = 'SOL0030051'
export const MENU_RFQ_APPROVAL_SECURITY_CODE                  = 'SOL0030052'
export const MENU_RFQ_APPROVAL_DETAIL                         = 'SOL0030053'

export const MENU_RFQ_SPP                                     = 'SOL0030054'
export const MENU_RFQ_SPP_APPROVAL                            = 'SOL0030055'
export const MENU_RFQ_SPP_CANCEL                              = 'SOL0030056'
export const MENU_RFQ_SPP_CHANGE_APPROVER                     = 'SOL0030057'
export const MENU_RFQ_SPP_PREVIEW                             = 'SOL0030058'
export const MENU_RFQ_SPP_SECURITY_CODE                       = 'SOL0030059'

export const MENU_SEND_RFI                                    = 'SOL0050001'
export const MENU_LIST_VENDORS                                = 'SOL0050002'
export const MENU_VENDOR_DETAILS_DOCUMENT_BY_VENDOR           = 'SOL0050003'
export const MENU_VENDOR_DETAILS_DOCUMENT_BY_PROJECT          = 'SOL0050004'
export const MENU_VENDOR_DETAILS_PROJECTS                     = 'SOL0050005' 
export const MENU_VENDORS                                     = 'SOL0050006'
export const MENU_OFFICIAL_SIGNATURE                          = 'SOL0050012'