<template>
	<div class="w-full h-full">
		<div class="w-full h-full bg-white rounded-2xl shadow-gray-sm">
			<div
				class="relative flex items-center justify-center w-full h-12 border-b border-gray-lightest shadow-gray-sm">
				<h5 class="text-base font-semibold text-gray">PROJECT LOG</h5>
				<div class="absolute right-6">
					<span v-if="windowWidth >= 1024 && !showLog" class="mr-3 text-lg cursor-pointer icon-minimize text-gray hover:text-gray-dark" @click=clickMinimizeLog></span>
					<span v-if="minimizeLog"
						@click="closeProjectLog"
						class="text-xl font-semibold cursor-pointer icon-x"></span>
				</div>
			</div>
			<div class="flex flex-col justify-between height-log-status">
				<div class="flex flex-col-reverse w-full overflow-auto" :style="`height: calc(100% - ${heightApproval}px)`">
					<div class="w-full p-4 " >
						<ul v-if="!loading.loadingProjectLog">
							<div>
								<li v-for="(n, i) in projectLogRfp?.rfpLogs"
									:key="i" class="h-full transition duration-500 transform ease-custom">
									<div v-if="i === projectLogRfp?.rfpLogs.length - 1"
										class="flex h-full">
										<StepLine :lastIndex="true" :lineFull="false" :isInProgress="n.isInProgress"></StepLine>
										<div class="ml-4 mt-4 border border-primary shadow-primary-lg p-2.5 rounded-xl items-start w-full">
											<h5 class="text-sm font-semibold text-primary">{{ n.createdAt }}</h5>
											<div class="">
												<label class="font-medium break-words text-success">{{ n.currentStatus }}</label>
											</div>
											<div v-if="n.notes" class="mt-1">
												<p v-if="n.notes.length > 34" class="text-sm font-medium break-words whitespace-pre-wrap text-gray">Reason: {{n.notes.slice(0,20)}}... <span class="cursor-pointer text-secondary" @click="readMore(n.notes)">Read More</span></p>
												<p v-else class="text-sm font-medium break-words whitespace-pre-wrap text-gray">Reason: {{n.notes}}</p>
											</div>
											<div v-if="n?.additionalLogs?.length > 0">
												<button @click="changeCollapseNegotiation(i)" class="text-tertiary hover:text-secondary">{{ collapseText(i) }}</button>
											</div>
										</div>
									</div>
									<div v-else
										class="flex h-full"
										>
										<StepLine :lastIndex="false" :firstIndex="i === 0" :lineFull="i !== 0" :isInProgress="n.isInProgress"></StepLine>
										<div class="w-full ml-4 py-2.5 border-b border-gray-lightest">
											<h5 class="text-sm font-semibold text-gray-light">{{ n.createdAt }}</h5>
											<div class="">
												<label class="font-medium break-words text-gray">{{ n.currentStatus }}</label>
											</div>
											<div v-if="n.notes" class="mt-1">
												<p v-if="n.notes.length > 34" class="text-sm font-medium break-words whitespace-pre-wrap text-gray">Reason: {{n.notes.slice(0,20)}}... <span class="cursor-pointer text-secondary" @click="readMore(n.notes)">Read More</span></p>
												<p v-else class="text-sm font-medium break-words whitespace-pre-wrap text-gray">Reason: {{n.notes}}</p>
											</div>
											<div v-if="n?.additionalLogs?.length > 0">
												<button @click="changeCollapseNegotiation(i)" class="text-tertiary hover:text-secondary">{{ collapseText(i) }}</button>
											</div>
										</div>
									</div>
									<transition
										enter-active-class="transition duration-500 ease-in transform"
										enter-class="scale-y-0 -translate-y-1/2 opacity-0"
										enter-to-class="scale-y-100 translate-y-0 opacity-100"
										leave-active-class="transition duration-300 ease-in transform"
										leave-class="scale-y-100 translate-y-0 opacity-100"
										leave-to-class="scale-y-0 -translate-y-1/2 opacity-0"
										>
										<div v-if="n?.additionalLogs?.length > 0 && n?.toggleExpand">
											<div v-for="(add, j) in n.additionalLogs" :key="j">
												<div class="flex h-full pl-6">
													<StepLine :lastIndex="false" :firstIndex="j === 0" :lineFull="j !== 0 && j !== n.additionalLogs.length - 1" :noLine="n.additionalLogs.length === 1" :isInProgress="add.isInProgress"></StepLine>
													<div class="w-full ml-4 py-2.5 border-b border-gray-lightest">
														<h5 class="text-sm font-semibold text-gray-light">{{ add?.modifiedAt }}</h5>
														<div class="">
															<label class="font-medium break-words text-gray">{{ add?.description }}</label>
															<div v-if="add?.notes" class="mt-1">
																<p v-if="add?.notes.length > 34" class="text-sm font-medium break-words whitespace-pre-wrap text-gray">Reason: {{add.notes.slice(0,20)}}... <span class="cursor-pointer text-secondary" @click="readMore(add.notes)">Read More</span></p>
																<p v-else class="text-sm font-medium break-words whitespace-pre-wrap text-gray">Reason: {{add.notes}}</p>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</transition>
									<div v-if="i !== projectLogRfp?.rfpLogs.length - 1 && n.textApproval"
										class="flex h-full">
										<StepLine :lastIndex="false" :firstIndex="i === 0" :lineFull="i !== 0" :isInProgress="n.isInProgress"></StepLine>
										<div class="w-full ml-4 py-2.5 border-b border-gray-lightest">
											<h5 class="text-sm font-semibold text-gray-light">{{ n?.textApproval.split('\n')[0] }}</h5>
											<div class="">
												<label class="font-medium break-words text-gray">{{ n?.textApproval.split('\n')[1] }}</label>
											</div>
											<div v-if="n.notes" class="mt-1">
												<p v-if="n.notes.length > 34" class="text-sm font-medium break-words whitespace-pre-wrap text-gray">Reason: {{n.notes.slice(0,20)}}... <span class="cursor-pointer text-secondary" @click="readMore(n.notes)">Read More</span></p>
												<p v-else class="text-sm font-medium break-words whitespace-pre-wrap text-gray">Reason: {{n.notes}}</p>
											</div>
										</div>
									</div>
								</li>
							</div>
						</ul>
						<LoadingProjectLog v-else/>
					</div>
				</div>
				<ModalReadMore @click="closeModal" :isOpen="readMoreShow" :text="notes" />
				<div class="flex flex-col justify-end" id="heightApproval">
					<ApprovalRfp v-if="menuApprove?.canUpdate && currentApprover && detailPage && !someInProgress&& !projectCanceledCompleted && !loading.loadingProjectLog"
						:roleGsitUkkp="roleGsitUkkp"
						@refetchProjectList="refetchProjectList"
						/>
					<CancelEditRfp v-if="menuRFP?.canUpdate && !projectCanceledCompleted && detailPage  && !someInProgress && !loading.loadingProjectLog"
						@refetchProjectList="refetchProjectList"/>
					<CancelRfp v-if="!projectCanceledCompleted && !someInProgress && !loading.loadingProjectLog"
						@refetchProjectList="refetchProjectList"/>
					<UpdateApprove v-if="updateApproverShow && !projectCanceledCompleted && menuDetailsActionUpdateApprover?.canUpdate && detailPage && !someInProgress && !loading.loadingProjectLog" 
						@refetchProjectList="refetchProjectList"
						/>
					<ApprovalScoring @refetch="refetchProjectLog" v-if="!someInProgress && !projectCanceledCompleted && !loading.loadingProjectLog"/>
					<ChangeApproverScoring @refetch="refetchProjectLog" v-if="!someInProgress && !projectCanceledCompleted && !loading.loadingProjectLog"/>
					<RfpBanApproval @refetch="refetchProjectLog" v-if="!someInProgress && !projectCanceledCompleted && !loading.loadingProjectLog"/>
					<RfpScoringPriceApproval @refetch="refetchProjectLog" v-if="!someInProgress && !projectCanceledCompleted && !loading.loadingProjectLog"/>
					<RfpChangeVerificator @refetch="refetchProjectLog" v-if="!someInProgress && !projectCanceledCompleted && !loading.loadingProjectLog"/>
					<EditProcurementApproval v-if="!someInProgress && !projectCanceledCompleted && !loading.loadingProjectLog" />
					<ApprovalChangeApprover v-if="!someInProgress && !projectCanceledCompleted && !loading.loadingProjectLog" />
					<ApprovalChangeRequester v-if="!someInProgress && !projectCanceledCompleted && !loading.loadingProjectLog" />
					<SktLoiChangeApprover v-if="!someInProgress && !projectCanceledCompleted && !loading.loadingProjectLog" />
					<UploadApprovalReject v-if="!someInProgress && !projectCanceledCompleted && !loading.loadingProjectLog" />
					<ProcurementApproveReject v-if="!someInProgress && !projectCanceledCompleted && !loading.loadingProjectLog" />
					<SktLoiApproval v-if="!someInProgress && !projectCanceledCompleted && !loading.loadingProjectLog" />
					<EditSktLoi v-if="!someInProgress && !projectCanceledCompleted && !loading.loadingProjectLog" />
					<SktLoiChangeRequester v-if="!someInProgress && !projectCanceledCompleted && !loading.loadingProjectLog" />
					<SppChangeApprover v-if="!someInProgress && !projectCanceledCompletedbyLogs && !loading.loadingProjectLog" />
					<SppApproval v-if="!someInProgress && !projectCanceledCompletedbyLogs && !loading.loadingProjectLog" />
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import ApprovalRfp from '@/core/components/project-log/approval/ApprovalRfp.vue';
import CancelRfp from '@/core/components/project-log/canceledit/CancelRfp.vue';
import CancelEditRfp from '@/core/components/project-log/canceledit/CancelEditRfp.vue';
import UpdateApprove from '@/core/components/project-log/canceledit/UpdateApprove.vue';
import ApprovalScoring from '@/core/components/project-log/approvalScoring/ApprovalScoring.vue';
import ChangeApproverScoring from '@/core/components/project-log/approvalScoring/ChangeApproverScoring.vue';
import RfpBanApproval from '@/core/components/project-log/negotiation/RfpBanApproval.vue'
import RfpChangeVerificator from '@/core/components/project-log/negotiation/RfpChangeVerificator.vue';
import LoadingProjectLog from '@/core/components/project-log/LoadingProjectLog.vue';
import RfpScoringPriceApproval from '@/core/components/project-log/negotiation/RfpScoringPriceApproval.vue'
import EditProcurementApproval from '@/core/components/project-log/procurementApproval/EditProcurementApproval.vue'
import ApprovalChangeApprover from '@/core/components/project-log/procurementApproval/ApprovalChangeApprover.vue'
import SktLoiChangeApprover from '@/core/components/project-log/sktLoi/SktLoiChangeApprover.vue'
import ApprovalChangeRequester from '@/core/components/project-log/procurementApproval/ApprovalChangeRequester.vue'
import SktLoiApproval from '@/core/components/project-log/sktLoi/SktLoiApproval.vue'
import EditSktLoi from '@/core/components/project-log/sktLoi/EditSktLoi.vue'
import SktLoiChangeRequester from '@/core/components/project-log/sktLoi/SktLoiChangeRequester.vue'
import StepLine from '@/core/components/project-log/StepLine.vue'
import { GSIT, KP, LOG, OPR, SOLUTIP_OPR_LOG } from '@/core/constant/applicationRoleName.js'
import { STAFF_ITP_01_AWAITING_APPROVAL, CANCELED, COMPLETED, SPP_PROCESS } from '@/core/constant/statusProject'
import UploadApprovalReject from '@/core/components/project-log/procurementApproval/UploadApprovalReject.vue';
import ProcurementApproveReject from '@/core/components/project-log/procurementApproval/ProcurementApproveReject.vue';
import ModalReadMore from '@/core/components/modal/ReadMore.vue'
import SppChangeApprover from '@/core/components/project-log/spp/SppChangeApprover.vue'
import SppApproval from '@/core/components/project-log/spp/SppApproval.vue'

export default {
	name: "ProjectLog",
	props: ['windowWidth', 'showLog', 'minimizeLog'],
	data() {
		return {
			heightApproval: document.getElementById('heightApproval')?.offsetHeight,
			notes: '',
			readMoreShow: false,
			collapseNegotiation: false,
		}
	},
	computed: {
		modal: {
			get() {
				return this.$store.state.modal.modal
			},
			set(value) {
				this.$store.commit('modal/SET_MODAL', value)
			}
		},
		loading: {
			get() {
				return this.$store.state.modal.loading
			},
			set(value) {
				this.$store.commit('modal/SET_LOADING', value)
			}
		},
		userDetail() {
			return this.$store.state.dashboard.userProfile;
		},
		rfpLogApproval() {
			return this.$store.state.projectDetail.rfpLogApproval
		},
		currentApprover() {
			if (this.rfpLogsCurrentStatus === STAFF_ITP_01_AWAITING_APPROVAL) {
				return this.menu[0]?.applicationRoleName === SOLUTIP_OPR_LOG
			} else if (this.rfpLogApproval?.reviewerGsitStatus?.toLowerCase() === 'on review') {
				return this.userDetail.username.toLowerCase() === this.rfpLogApproval?.reviewerGsitId?.toLowerCase()
			} else if (this.rfpLogApproval?.reviewerKpStatus?.toLowerCase() === 'on review') {
				return this.userDetail.username.toLowerCase() === this.rfpLogApproval?.reviewerKpId?.toLowerCase()
			} else if (this.rfpLogApproval?.directApproverGsitStatus?.toLowerCase() === 'on review') {
				return this.userDetail.username.toLowerCase() === this.rfpLogApproval?.directApproverGsitId?.toLowerCase()
			} else if (this.rfpLogApproval?.directApproverKpStatus?.toLowerCase() === 'on review') {
				return this.userDetail.username.toLowerCase() === this.rfpLogApproval?.directApproverKpId?.toLowerCase()
			} else if (this.rfpLogApproval?.firstApproverDlogStatus?.toLowerCase() === 'on review') {
				return this.userDetail.username.toLowerCase() === this.rfpLogApproval?.firstApproverDlogId?.toLowerCase()
			} else if (this.rfpLogApproval?.secondApproverDlogStatus?.toLowerCase() === 'on review') {
				return this.userDetail.username.toLowerCase() === this.rfpLogApproval?.secondApproverDlogId?.toLowerCase()
			} else {
				return false
			}
		},
		updateApproverShow() {
			return Object.values(this.rfpLogApproval).some(e => e?.toString()?.toLowerCase() === 'on review')
		},
		//showing button/component approve based roleName
		roleGsitUkkp() {
      const roleName = this.menu[0]?.applicationRoleName
			return (roleName?.includes(GSIT) || roleName?.includes(KP)) && !roleName?.includes(OPR) && !roleName?.includes(LOG)
    },
		projectCanceledCompletedbyLogs() {
			return this.projectLogRfp.rfpLogs.some(e => e.statusProject === CANCELED || e.statusProject === COMPLETED)
		},
		projectCanceledCompleted() {
			return this.sppProcess || this.projectCanceledCompletedbyLogs
		},
		sppProcess() {
			return this.$store.state.spp.sppProcess
		},
		menu() {
			return this.$store.state.dashboard.menu
		},
		menuRFP() {
			return this.$store.getters['dashboard/menuRFP']
		},
		menuApprove() {
			return this.$store.getters['dashboard/menuDetailsApprove']
		},
		menuReject() {
			return this.$store.getters['dashboard/menuDetailsReject']
		},
		menuDetailsActionUpdateApprover() {
			return this.$store.getters['dashboard/menuDetailsActionUpdateApprover']
		},
		detailPage() {
      return this.$route.path.includes('detail')
    },
		projectLogRfp: {
			get() {
				return this.$store.state.projectLog.projectLogRfp
			},
			set(val) {
				this.$store.commit('projectLog/SET_PROJECT_LOG_RFP', val)
			}
		},
		rfpLogsCurrentStatus() {
			const rfpLogs = this.projectLogRfp?.rfpLogs
			const logsLength = rfpLogs.length
			if (logsLength > 0) {
				if(rfpLogs[logsLength - 1]?.statusProject === SPP_PROCESS) return rfpLogs[logsLength - 2]?.statusProject
				return rfpLogs[logsLength - 1]?.statusProject
			}
			return false
		},
		someInProgress() {
			return this.projectLogRfp?.rfpLogs.some(e => e.isInProgress || e.additionalLogs?.some(el => el.isInProgress))
		},
		projectLogStatus() {
      return this.$store.state.projectLog.projectLogStatus
    },
		projectId() {
      return this.$store.state.projectLog.projectId
		},
		rfpLogId() {
      return this.$store.state.projectLog.rfpLogId
		},
		filterProject() {
			return this.$store.state.dashboard.filterProject
		},
		counterInterval() {
			return this.$store.state.projectLog.counterInterval
		}
	},
	methods: {
		closeProjectLog() {
			this.$emit('click')
		},
		clickMinimizeLog() {
			this.$emit('clickMinimize')
		},
		changeCollapseNegotiation(i) {
			this.projectLogRfp.rfpLogs[i].toggleExpand = !this.projectLogRfp.rfpLogs[i].toggleExpand
		},
		collapseText(i) {
			return this.projectLogRfp?.rfpLogs[i].toggleExpand? 'collapse': 'extend'
		},
		refetchProjectList() {
			this.$store.dispatch('projectLog/refetchProjectLogStatus')
    },
		refetchProjectLog() {
			this.$store.dispatch('projectLog/refetchProjectLogByRfpId', this.$route.params.projectId)
		},
		closeModal() {
			this.readMoreShow = false
		},
		readMore(notes) {
			this.notes = notes
			this.readMoreShow = true
		},
	},
	destroyed() {
		clearInterval(this.counterInterval)
		this.$store.commit('projectLog/SET_COUNTER_INTERVAL', null)
	},
	components: {
    ApprovalRfp,
    CancelEditRfp,
    UpdateApprove,
		ApprovalScoring,
		ChangeApproverScoring,
    StepLine,
    RfpBanApproval,
    RfpChangeVerificator,
    RfpScoringPriceApproval,
    LoadingProjectLog,
		ModalReadMore,
    EditProcurementApproval,
    ApprovalChangeApprover,
    SktLoiChangeApprover,
    UploadApprovalReject,
		ApprovalChangeRequester,
		SktLoiApproval,
		EditSktLoi,
		SktLoiChangeRequester,
		ProcurementApproveReject,
		CancelRfp,
		SppChangeApprover,
		SppApproval
	},
}
</script>

<style scoped>
.height-log-status {
	height: calc(100% - 3rem)
}
</style>