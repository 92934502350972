/* eslint no-undef: 0 */
/* eslint no-unused-vars: 0 */

import axios from "axios";
import { stringify } from "query-string";
import EventEmitter from "events";

export default class Api {
  constructor() {
    this.name = "Api";

    this.config = {
      baseURL: window.solutipConfig.API_URL
    };

    this.axios = axios.create(this.config);

    this.configureAxios();

    this.event = new EventEmitter();
  }

  // async get(urlPath, queryParams) {
  //   const hasQueryParams = queryParams !== undefined;
  //   const path = !hasQueryParams
  //     ? urlPath
  //     : urlPath + "?" + stringify(queryParams);
  //   return this.axios.get(path);
  // }

  async get(urlPath, queryParams, headers) {
    const hasQueryParams =
      queryParams !== undefined ||
      JSON.stringify(queryParams) !== JSON.stringify({});
    const path = !hasQueryParams
      ? urlPath
      : urlPath + "?" + stringify(queryParams);
    if (headers) {
      return this.axios.get(path, headers);
    } else {
      return this.axios.get(path);
    }
  }

  async post(urlPath, body, headers) {
    if (headers) {
      return this.axios.post(urlPath, body, headers)
    } else {
      return this.axios.post(urlPath, body)
    }
  }

  async put(urlPath, body) {
    return this.axios.put(urlPath, body);
  }

  async delete(urlPath, body) {
    return this.axios.delete(urlPath, body);
  }

  // async patch(id, params, body = {}) {
  //   const path = this._buildUrlById(id, params, "patch");
  //   return this.axios.patch(path, body);
  // }

  onSuccess(cb) {
    this.event.on("success", (msg) => {
      return cb(msg);
    });
  }

  onError(cb) {
    this.event.on("error", (msg, header) => {
      return cb(msg, header);
    });
  }

  configureAxios() {
    this.axios.interceptors.request.use(
      (config) => {
        let token = "";
        token = window.keycloak.token;
        if (token) {
          config.headers = {
            Authorization: `Bearer ${token}`,
          };
        }
        if (window.sessionStorage.getItem("isDownload")) {
          config.responseType = "blob";
        }
        // Do something before request is sent
        return config;
      },
      (error) => {
        // Do something with request error
        return Promise.reject(error);
      }
    );

    this.axios.interceptors.response.use(
      (response) => {
        if (response.config.method != "get") {
          this.event.emit("success", response.data);
        }

        return response;
      },
      async (error) => {
        if (error.response) {
          if (error.response.request.responseType === "blob") {
            let headers = error.response.headers;
            try {
              error.response.data = JSON.parse(
               await error.response.data.text()
              );
            } catch (err) {
              let errorMsg = {
                message: "File not found",
              };
              this.event.emit("error", errorMsg, headers);
              throw err;
            }
          }
          this.event.emit("error", error.response.data, error.response.headers);
          throw error;
        }
        this.event.emit("error", error);
        throw error;
      }
    );
  }
}
