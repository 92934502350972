export const formatCurrency = (number, currency, showCurrency, minimumFractionDigits, maximumFractionDigits) => {
  if (!currency) currency = 'idr'
  const currencyLower = currency.toLowerCase()
  const mapCurrency = {
    idr: { locale: 'id-ID', currency: 'IDR' },
    usd: { locale: 'en-US', currency: 'USD' },
    sgd: { locale: 'en-SG', currency: 'SGD' },
    hkd: { locale: 'en-HK', currency: 'HKD' },
  }
  let selected = mapCurrency[currencyLower]
  if (!selected) selected = mapCurrency['idr']
  const int = new Intl.NumberFormat(selected.locale, {
    style: "currency",
    currency: selected.currency,
    minimumFractionDigits, 
    maximumFractionDigits,
    roundingMode: 'floor'
  }).formatToParts(number);
  let value = ''
  int.forEach(e => {
    if(e.type !== "literal" && e.type !== "currency") value += e.value
  })
  if(showCurrency) return `${selected.currency} ${value}`
  return value
}

export const formatRupiah = (number) => {
  if (!number) return 'Rp 0'
  return new Intl.NumberFormat('id-ID', {
    style: "currency",
    currency: 'IDR'
  }).format(number);
}

export const formatThousandSeparate = (string, currencyParam, minimumFractionDigits, maximumFractionDigits) => {
  if (!string) return ''
  const currency = currencyParam || 'usd'
  const currencyLower = currency.toLowerCase()
  const mapCurrency = {
    idr: { locale: 'id-ID', currency: 'IDR' },
    usd: { locale: 'en-US', currency: 'USD' },
    sgd: { locale: 'en-SG', currency: 'SGD' },
    hkd: { locale: 'en-HK', currency: 'HKD' },
  }
  return new Intl.NumberFormat(mapCurrency[currencyLower].locale, { 
    maximumFractionDigits,
    minimumFractionDigits
  }).format(string);
}

export const parseLocaleNumber = (number, currencyParam) => {
  const stringNumber = String(number)
  if (!stringNumber) return 0
  const currency = currencyParam || 'usd'
  const currencyLower = currency.toLowerCase()
  const mapCurrency = {
    idr: { locale: 'id-ID', currency: 'IDR' },
    usd: { locale: 'en-US', currency: 'USD' },
    sgd: { locale: 'en-SG', currency: 'SGD' },
    hkd: { locale: 'en-HK', currency: 'HKD' },
  }
  const thousandSeparator = Intl.NumberFormat(mapCurrency[currencyLower].locale).format(11111).replace(/\p{Number}/gu, '');
  const decimalSeparator = Intl.NumberFormat(mapCurrency[currencyLower].locale).format(1.1).replace(/\p{Number}/gu, '');

  return parseFloat(stringNumber
      .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
      .replace(new RegExp('\\' + decimalSeparator), '.')
  );
}

export const reverseFormatNumber = (stringNumber,currencyParam) => {
  if (!stringNumber) return 0
  const currency = currencyParam || 'usd'
  const currencyLower = currency.toLowerCase()
  const mapCurrency = {
    idr: { locale: 'id-ID', currency: 'IDR' },
    usd: { locale: 'en-US', currency: 'USD' },
    sgd: { locale: 'en-SG', currency: 'SGD' },
    hkd: { locale: 'en-HK', currency: 'HKD' },
  }
  const group = new Intl.NumberFormat(mapCurrency[currencyLower].locale).format(1111).replace(/1/g, '');
  const decimal = new Intl.NumberFormat(mapCurrency[currencyLower].locale).format(1.1).replace(/1/g, '');
  let reversedVal = stringNumber.replace(new RegExp('\\' + group, 'g'), '');
  reversedVal = reversedVal.replace(new RegExp('\\' + decimal, 'g'), '.');
  return Number.isNaN(reversedVal)?0:reversedVal;
}
