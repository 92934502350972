export const vatIncludedChecker = (e) => {
  return e?.toLowerCase().includes('included')
}

export const capitalizeFirstLetter = (string) => {
  return string[0].toUpperCase() + string.slice(1);
}

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(/^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/);
};
const isObject = (object) => {
  return object != null && typeof object === "object";
};

export const isDeepEqual = (object1, object2) => {

  const objKeys1 = Object.keys(object1);
  const objKeys2 = Object.keys(object2);

  if (objKeys1.length !== objKeys2.length) return false;

  for (let key of objKeys1) {
    const value1 = object1[key];
    const value2 = object2[key];

    const isObjects = isObject(value1) && isObject(value2);

    if ((isObjects && !isDeepEqual(value1, value2)) ||
      (!isObjects && value1 !== value2)
    ) {
      return false;
    }
  }
  return true;
};

export const floatParsing = (str) => {
  return parseFloat(str.toString()?.replace(/\s/g, "")?.replace(",", "."))
}

export const removeCommaAndPointInString = (str) => {
  if (!str) return ''
  return str.replace(/\D/g,'');
}

export const cleanedInput = (input) =>  {
  return input.trim().replace(/\s+/g, ' ').replace(/\s([,.!?;:])/g, '$1')
}

export const deepClone = (obj) => {
  if (obj === null || typeof obj !== 'object') {
      return obj;
  }

  // Special case for Date objects
  if (obj instanceof Date) {
      return new Date(obj.getTime());
  }

  // Create an array or object to hold the cloned properties
  const newObj = Array.isArray(obj) ? [] : {};

  // Recursively clone each property
  for (let key in obj) {
      newObj[key] = deepClone(obj[key]);
  }

  return newObj;
}

export const deepCloneAdvanced = (obj, hash = new WeakMap()) => {
  if (Object(obj) !== obj) return obj; // primitives
  if (obj instanceof Date) return new Date(obj);
  if (obj instanceof RegExp) return new RegExp(obj);
  if (obj instanceof Function) return obj;

  // Handle special objects
  if (hash.has(obj)) return hash.get(obj); // cyclic reference
  if (obj instanceof Map) return new Map([...obj].map(([key, val]) => [key, deepCloneAdvanced(val, hash)]));
  if (obj instanceof Set) return new Set([...obj].map(val => deepCloneAdvanced(val, hash)));
  if (obj instanceof Blob) return new Blob([obj], { type: obj.type });
  if (obj instanceof FileList) return obj;
  if (obj instanceof ImageData) return new ImageData(new Uint8ClampedArray(obj.data), obj.width, obj.height);
  if (Array.isArray(obj)) return obj.map(val => deepCloneAdvanced(val, hash));
  if (obj instanceof ArrayBuffer) return obj.slice(0);
  if (ArrayBuffer.isView(obj)) return new obj.constructor(obj.buffer.slice(0), obj.byteOffset, obj.length);

  // Regular objects
  const result = obj instanceof Object ? Object.create(Object.getPrototypeOf(obj)) : {};
  hash.set(obj, result);
  return Object.assign(result, ...Object.keys(obj).map(key => ({ [key]: deepCloneAdvanced(obj[key], hash) })));
}

export const formatLabelWordBreak = (label, maxLineLength = 15) => {
  const words = label.split(' ');
  let formattedLabel = '';
  let line = '';

  words.forEach((word) => {
    if ((line + word).length > maxLineLength) {
      formattedLabel += line.trim() + '\n';
      line = word + ' ';
    } else {
      line += word + ' ';
    }
  });
  formattedLabel += line.trim();
  return formattedLabel;
}

