/* eslint-disable */
//Error State Custom/ Error Message Custom/
import { MESSAGE_TOTAL_BOBOT, MESSAGE_BOBOT_SCORE, MESSAGE_EMPTY_FIELD, MESSAGE_TOTAL_SCORE, MESSAGE_CANNOT_SAME_VALUE } from '@/core/constant/errorMessage.js'
export const Store = {
  namespaced: true,
  name: 'errorRfp',
  state: {
    errorProposalInformation: {
      approverUkkpId: { value: false, message: '' },
      directApproverId: { value: false, message: '' },
      firstApproverDlogId: { value: false, message: '' },
      secondApproverDlogId: { value: false, message: '' },
      projectName: { value: false, message: '' },
      technicalCoordinatorOfficePhone: { value: false, message: '' },
      ext: { value: false, message: '' },
      budgetSpent: { value: false, message: '' },
      kodeWbsLev1: { value: false, message: '' },
    },

    errorProjectInformation: {
      background: { value: false, message: '' },
      tujuanProyek: { value: false, message: '' },
      jangkaWaktuProyek: { value: false, message: '' }
    },
    errorProjectRequirement: { value: false, message: '', counter: 0 },
    errorProjectRequirement1: {
      persyaratanProdukDanJasa: { value: false, message: '' },
      persyaratanTeknis: { value: false, message: '' },
      arsitekturDanStandar: { value: false, message: '' },
      implementasi: { value: false, message: '' },
      pengembanganDanInterface: { value: false, message: '' },
      projectStatusReporting: { value: false, message: '' },
      sumberDayaYangTersedia: { value: false, message: '' },
      serviceLevel: { value: false, message: '' },
      pengamanTi: { value: false, message: '' },
      dokumentasi: { value: false, message: '' },
      pelatihan: { value: false, message: '' },
      dukunganPemeliharaan: { value: false, message: '' },
      acceptanceTest: { value: false, message: '' },
      referensi: { value: false, message: '' },
    },
    errorProjectRequirement2: {
      persyaratanProdukDanJasaFile: { value: false, message: '' },
      persyaratanTeknisFile: { value: false, message: '' },
      arsitekturDanStandarFile: { value: false, message: '' },
    },
    hideCheckErrorTotalScore: true,
    errorScoringInformationBobotPrice: { value: false, message: MESSAGE_BOBOT_SCORE },
    errorScoringInformationBobotTotal: { value: false, message: MESSAGE_TOTAL_BOBOT }
  },
  mutations: {
    SET_ERROR_PROPOSAL_INFORMATION(state, payload) {
      state.errorProposalInformation = payload
    },
    SET_ERROR_PROJECT_INFORMATION(state, payload) {
      state.errorProjectInformation = payload
    },
    SET_ERROR_SCORING_INFORMATION_BOBOT_PRICE(state, payload) {
      state.errorScoringInformationBobotPrice = payload
    },
    SET_ERROR_SCORING_INFORMATION_BOBOT_TOTAL(state, payload) {
      state.errorScoringInformationBobotTotal = payload
    },
    SET_HIDE_CHECK_ERROR_TOTAL_SCORE(state, payload) {
      state.hideCheckErrorTotalScore = payload
    },
    SET_ERROR_PROJECT_REQUIREMENT(state, payload) {
      state.errorProjectRequirement = payload
    },
    SET_ERROR_PROJECT_REQUIREMENT1(state, payload) {
      state.errorProjectRequirement1 = payload
    },
    SET_ERROR_PROJECT_REQUIREMENT2(state, payload) {
      state.errorProjectRequirement2 = payload
    }
  },
  actions: {
    checkErrorProposalInformationDraft({ state, dispatch }, payload) {
      dispatch('checkErrorProposalInformationField', { key: 'projectName' })
      return !state.errorProposalInformation.projectName.value
    },
    checkErrorProposalInformationSubmit({ commit, state, rootState, dispatch }, payload) {
      const { roleOprKp } = payload
      if (roleOprKp || rootState.rfp.proposalInformation.adaUkkp) {
        dispatch('checkErrorProposalInformationField', { key: 'approverUkkpId' })
      }
      if (!roleOprKp) {
        dispatch('checkErrorProposalInformationField', { key: 'directApproverId' })
      }
      dispatch('checkErrorProposalInformationField', { key: 'firstApproverDlogId' })
      dispatch('checkErrorProposalInformationField', { key: 'secondApproverDlogId' })
      dispatch('checkErrorProposalInformationField', { key: 'projectName' })
      dispatch('checkErrorProposalInformationField', { key: 'technicalCoordinatorOfficePhone' })
      dispatch('checkErrorProposalInformationField', { key: 'ext' })
      dispatch('checkErrorProposalInformationField', { key: 'budgetSpent' })
      dispatch('checkErrorProposalInformationField', { key: 'kodeWbsLev1' })
      return !Object.values(state.errorProposalInformation).some(e => e.value)
    },
    checkErrorProposalInformationField({ commit, state, rootState }, payload) {
      const {key} = payload
      if (!rootState.rfp.proposalInformation[key]) {
        commit('SET_ERROR_PROPOSAL_INFORMATION', { ...state.errorProposalInformation, [key]: { value: true, message: MESSAGE_EMPTY_FIELD }})
      } else {
        commit('SET_ERROR_PROPOSAL_INFORMATION', { ...state.errorProposalInformation, [key]: { value: false, message: '' }})
      }
      return !state.errorProposalInformation[key].value
    },
    checkErrorProjectInformation({ commit, state, rootState, dispatch}, payload) {
      const { isSubmit } = payload
      if (isSubmit) {
        dispatch('checkErrorProjectInformationField', { key: 'tujuanProyek' })
        dispatch('checkErrorProjectInformationField', { key: 'jangkaWaktuProyek' })
        dispatch('checkErrorProjectInformationField', { key: 'background' })
      }
      return !Object.values(state.errorProjectInformation).some(e => e.value)
    },
    checkErrorProjectInformationField({ commit, state, rootState }, payload) {
      const {key} = payload
      if (!rootState.rfp.projectInformation[key] && state.errorProjectInformation.message !== MESSAGE_EMPTY_FIELD) {
        commit('SET_ERROR_PROJECT_INFORMATION', {...state.errorProjectInformation, [key]: { value: true, message: MESSAGE_EMPTY_FIELD }})
      } else if (state.errorProjectInformation.message === MESSAGE_EMPTY_FIELD) {
        commit('SET_ERROR_PROJECT_INFORMATION', {...state.errorProjectInformation, [key]: { value: false, message: '' }})
      }
      return !state.errorProjectInformation[key].value
    },
    checkErrorProjectInformationLength({ commit, state, rootState }, payload) {
      const {key, totalChar, name, maxLength} = payload
      if (totalChar > maxLength) {
        commit('SET_ERROR_PROJECT_INFORMATION', {...state.errorProjectInformation, [key]: { value: true, message: `Kolom ${name} Maksimal 4500 Karakter` }})
      } else if (!rootState.rfp.projectInformation[key]) {
        commit('SET_ERROR_PROJECT_INFORMATION', {...state.errorProjectInformation, [key]: { value: true, message: MESSAGE_EMPTY_FIELD }})
      } else {
        commit('SET_ERROR_PROJECT_INFORMATION', {...state.errorProjectInformation, [key]: { value: false, message: '' }})
      }
      return !state.errorProjectInformation[key].value
    },
    checkErrorProjectRequirement({ commit, state, rootState }, payload) {
      return !Object.values(state.errorProjectRequirement1).some(e => e.value)
    },
    checkErrorProjectRequirementLength({ commit, state, rootState }, payload) {
      const { key, totalChar, name, maxLength } = payload
      if (totalChar > maxLength) {
        commit('SET_ERROR_PROJECT_REQUIREMENT1', {...state.errorProjectRequirement1, [key]: { value: true, message: `Kolom ${name} Maksimal 4500 Karakter` }})
      } else {
        commit('SET_ERROR_PROJECT_REQUIREMENT1', {...state.errorProjectRequirement1, [key]: { value: false, message: '' }})
      }
      return !state.errorProjectRequirement1[key].value
    },
    combineVendorCandidateLocalPartner({ commit, state, rootState}, payload) {
      const result = []
      rootState.rfp.vendorCandidate.forEach(e => {
        e.localPartners.forEach(el => {
          result.push(el)
        })
        result.push(e)
      })
      return result
    },
    async checkFilledVendor({dispatch}, e) {
      const filled = e.reason || e.vendorEmail || e.vendorName
      return Boolean(filled)
    },
    async checkLocalPartnerField ({dispatch}, e) {
      const errMsg = { value: true, message: MESSAGE_EMPTY_FIELD }
      if(e.localPartners?.length > 0) {
        return e.localPartners?.map((el,j) => {
          if((!el.reason || !el.vendorEmail || !el.vendorName)){
            return { ...el, error: { ...el.error, ...(!el.reason && {reason: errMsg}), ...el.vendorName ? {} : {vendorName: errMsg}, ...el.vendorEmail ? {} : {vendorEmail: errMsg} }}
          } else return el
        })
      }
      return e.localPartners

    },
    async checkErrorEmptyField({ state }, e) {
      const errMsg = { value: true, message: MESSAGE_EMPTY_FIELD }
      return { ...e.error, ...e.reason ? {} : {reason: errMsg}, ...e.vendorEmail ? {} : {vendorEmail: errMsg}, ...e.vendorName ? {} : {vendorName: errMsg}}
    },
    async validationVendorCandidateField({ commit, rootState, dispatch }, payload) {
      try {
        const vendorCandidate = rootState.rfp.vendorCandidate
        const dataCommit = await Promise.all(
          await vendorCandidate.map(async (e,i) => {
            const checkFilledVendor = await dispatch('checkFilledVendor', e)
            const checkLocalPartnerField = await dispatch('checkLocalPartnerField', e)
            const checkFilledLocalPartner = e.localPartners?.some(el => (el.vendorName || el.vendorEmail || el.reason))
            if(i === 0 || checkFilledVendor || checkFilledLocalPartner) {
              return { ...e, 
                localPartners: checkLocalPartnerField,
                error: await dispatch('checkErrorEmptyField', e)
              }
            } else {
              return e
            }
        }))
        commit('rfp/SET_VENDOR_CANDIDATE', dataCommit, { root: true })
      } catch (error) {
        return error
      }
    },
    async checkErrorVendorCandidateField({ commit, state, rootState, dispatch }, payload) {
      try {
        await dispatch('validationVendorCandidateField')
        return !rootState.rfp.vendorCandidate.some(e => (
          e.error?.vendorName?.value || e.error?.vendorEmail?.value || e.error?.reason?.value
          || (e.localPartners?.some(el => (el.error?.vendorName?.value || el.error?.vendorEmail?.value || el.error?.reason?.value)))
        ))
      } catch (error) {
        return error
      }
    },
    async checkErrorVendorCandidateVendorName({ commit, state, rootState, dispatch}, payload) {
      const combineVendorCandidateLocalPartner = await dispatch('combineVendorCandidateLocalPartner')
      const duplicateElements = combineVendorCandidateLocalPartner.filter((item, i) => {
				return (combineVendorCandidateLocalPartner.map(el => el.vendorId).indexOf(item.vendorId) !== i && item.vendorId) 
          || (combineVendorCandidateLocalPartner.map(el => el.vendorName).indexOf(item.vendorName) !== i && item.vendorName)
			})
			rootState.rfp.vendorCandidate.forEach((item, i) => {
				if (duplicateElements.length > 0) {
          if (duplicateElements.some(e => (e.vendorId === item.vendorId && item.vendorId) || (e.vendorName === item.vendorName && item.vendorName))) {
            dispatch('commitVendorCandidate', { i, j: null, errorMessage: { value: true, message: MESSAGE_CANNOT_SAME_VALUE }})
          } else {
            dispatch('commitVendorCandidate', { i, j: null, errorMessage: { value: false, message: '' }})
          }
          item.localPartners.forEach((local, j) => {
            if (duplicateElements.some(e => (e.vendorId === local.vendorId && local.vendorId) || (e.vendorName === local.vendorName && local.vendorName))) {
              dispatch('commitVendorCandidate', { i, j, errorMessage: { value: true, message: MESSAGE_CANNOT_SAME_VALUE }})
            } else {
              dispatch('commitVendorCandidate', { i, j, errorMessage: { value: false, message: '' }})
            }
          })
				} else {
          dispatch('commitVendorCandidate', { i, j: null, errorMessage: { value: false, message: '' }})
          item.localPartners.forEach((local, j) => {
            dispatch('commitVendorCandidate', { i, j, errorMessage: { value: false, message: '' }})
          })
				}
			})
      return rootState.rfp.vendorCandidate.every(e => e.error.vendorNameDuplicate.value === false && e.localPartners.every(el => el.error.vendorNameDuplicate.value === false))
    },
    commitVendorCandidate({ commit, state, rootState, dispatch }, payload) {
      const { i, j, errorMessage } = payload
      if (j === null) {
        const dataCommit = rootState.rfp.vendorCandidate?.map((el,idx) => ({ ...el, error: { ...el.error, vendorNameDuplicate: idx === i? (errorMessage) : el.error.vendorNameDuplicate}}))
        commit('rfp/SET_VENDOR_CANDIDATE', dataCommit, { root: true })
      } else {
        const dataCommit = rootState.rfp.vendorCandidate?.map((el,idx) => ({ ...el, localPartners: el.localPartners?.map((ele, jdx) => ({ ...ele, error: { ...ele.error, vendorNameDuplicate: (jdx === j && idx === i)? (errorMessage) : ele.error.vendorNameDuplicate } }))}))
        commit('rfp/SET_VENDOR_CANDIDATE', dataCommit, { root: true })
      }
    },
    async checkErrorScoringInformationDraft({ commit, state, rootState, dispatch}, payload) {
      const { routeName } = payload
      let result = false
      if (routeName === 'RFP') {
        result = await dispatch('caseSaveAsDraftCreateNewProject')
      } else {
        result = await dispatch('caseSaveAsDraftContinueDraft')
      }
      if (result) return true
      return dispatch('checkErrorScoringInformation')
    },
    async checkErrorScoringInformationSubmit({ dispatch }, payload) {
      await dispatch('checkErrorScoringInformationField')
      return dispatch('checkErrorScoringInformation')
    },
    async checkErrorScoringEmptyField({ state }, e) {
      const errMsg = { value: true, message: MESSAGE_EMPTY_FIELD }
      return { ...e.name ? {} : {errorSubField: errMsg}, ...e.bobot ? {} : {errorScore: errMsg}}
    },
    async checkErrorScoringInformationField({ commit, rootState, dispatch }, payload) {
      const scoringTeknisCriteria = rootState.rfp.scoringTeknisCriteria
      const dataCommit = await Promise.all(
        await scoringTeknisCriteria.map(async (e,i) => {
        return {
          ...e,
          errorScoreTotal: e.bobot ? e.errorScoreTotal : {...e.errorScoreTotal, value: true},
          rfpScoringTeknis:  await Promise.all( await e.rfpScoringTeknis.map(async (el, j) => {
                if (j === 0 || (el.name || el.bobot)) {
                  const errors = await dispatch('checkErrorScoringEmptyField', el)
                  return {...el, ...errors}
                }  else {
                  return el
                }
              })
          )
        }
      })
      )
      commit('rfp/SET_SCORING_TEKNIS_CRITERIA', dataCommit, { root: true })
    },
    async checkErrorScoringInformation({ commit, state, rootState, dispatch}, payload) {
      commit('SET_HIDE_CHECK_ERROR_TOTAL_SCORE', false)
      const checkScoringInformation = rootState.rfp.scoringTeknisCriteria.every((e ,i) => {
        return e.rfpScoringTeknis.every(k => !k.error.value && !k.errorSubField.value && !k.errorScore.value) === true && e.errorScoreTotal.value === false
      })
			const checkScoringInformationBobotTotal = !state.errorScoringInformationBobotTotal.value
			const checkScoringInformationBobotPrice = !state.errorScoringInformationBobotPrice.value
			return checkScoringInformation && checkScoringInformationBobotTotal && checkScoringInformationBobotPrice
    },
    caseSaveAsDraftCreateNewProject({ commit, state, rootState, dispatch }, payload) {
      let result = true
      rootState.rfp.scoringTeknisCriteria.forEach((e,i) => {
        if (e.bobot && i !== 3) {
          result = false
        }
        e.rfpScoringTeknis.forEach((el,j) => {
          if (el.bobot || el.name) {
            result = false
          }
        }) 
      })
      return result
    },
    async caseSaveAsDraftContinueDraft({ commit, state, rootState, dispatch }, payload) {
      let result = true
      // if there is no different with state initial will pass error check
      rootState.rfp.scoringTeknisCriteria.forEach((e,i) => {
        let scoringTeknisCriteria2 = rootState.rfp.scoringTeknisCriteria2
        if (+e.bobot !== +scoringTeknisCriteria2[i]?.bobot) {
          result = false
        }
        if (e.rfpScoringTeknis.length > 0) {
          e.rfpScoringTeknis.forEach((el, j) => {
            if (+el.bobot !== +scoringTeknisCriteria2[i]?.rfpScoringTeknis[j]?.bobot && scoringTeknisCriteria2[i]?.rfpScoringTeknis[j]?.bobot !== undefined) {
              result = false
            }
            if (el.name !== scoringTeknisCriteria2[i]?.rfpScoringTeknis[j]?.name && scoringTeknisCriteria2[i]?.rfpScoringTeknis[j]?.name !== undefined) {
              result = false
            }
          })
        }
      })
      return result
    },
    resetState({commit, state, rootState }, payload) {
      const { saveAsDraft } = payload
      const dataErrorProjectInformation = {
        background: state.errorProjectInformation.background.message === MESSAGE_EMPTY_FIELD? { value: false, message: '' } : state.errorProjectInformation.background,
        tujuanProyek: state.errorProjectInformation.tujuanProyek.message === MESSAGE_EMPTY_FIELD? { value: false, message: '' } : state.errorProjectInformation.tujuanProyek,
        jangkaWaktuProyek: state.errorProjectInformation.jangkaWaktuProyek.message === MESSAGE_EMPTY_FIELD? { value: false, message: '' } : state.errorProjectInformation.jangkaWaktuProyek
      }
      commit('SET_ERROR_PROJECT_INFORMATION', dataErrorProjectInformation)
      
      if (!saveAsDraft) {
        const dataErrorScoringInformationBobotPrice = { value: false, message: MESSAGE_BOBOT_SCORE }
        commit('SET_ERROR_SCORING_INFORMATION_BOBOT_PRICE', dataErrorScoringInformationBobotPrice)
        const dataErrorScoringInformationBobotTotal = { value: false, message: MESSAGE_TOTAL_BOBOT }
        commit('SET_ERROR_SCORING_INFORMATION_BOBOT_TOTAL', dataErrorScoringInformationBobotTotal)

        const dataCommitScoringTeknis = rootState.rfp.scoringTeknisCriteria.map((e,i) => {
          const rfpScoringTeknis = e.rfpScoringTeknis.map((el,j) => {
              return {
                ...el,
                error: { value: false, message: '' },
                errorSubField: { value: false, message: '' }, 
                errorScore: { value: false, message: '' }
              }
            })
          return { ...e, errorScoreTotal: { value: false, message: MESSAGE_TOTAL_SCORE }, rfpScoringTeknis: rfpScoringTeknis }
        })
        commit('rfp/SET_SCORING_TEKNIS_CRITERIA', dataCommitScoringTeknis, { root: true })
      }

      const dataErrorProposalInformation = {
        approverUkkpId: { value: false, message: '' },
        directApproverId: { value: false, message: '' },
        firstApproverDlogId: { value: false, message: '' },
        secondApproverDlogId: { value: false, message: '' },
        projectName: { value: false, message: '' },
        technicalCoordinatorOfficePhone: { value: false, message: '' },
        ext: { value: false, message: '' },
        budgetSpent: { value: false, message: '' },
        kodeWbsLev1: { value: false, message: '' },
      }
      commit('SET_ERROR_PROPOSAL_INFORMATION', dataErrorProposalInformation)
      const dataErrorProjectRequirement = { value: false, message: '', counter: 0 }
      commit('SET_ERROR_PROJECT_REQUIREMENT', dataErrorProjectRequirement)

      const dataErrorProjectRequirement2 = {
        persyaratanProdukDanJasaFile: { value: false, message: '' },
        persyaratanTeknisFile: { value: false, message: '' },
        arsitekturDanStandarFile: { value: false, message: '' },
      }
      commit('SET_ERROR_PROJECT_REQUIREMENT2', dataErrorProjectRequirement2)
      commit('SET_HIDE_CHECK_ERROR_TOTAL_SCORE', true)

      const dataCommitVendorCandidate = rootState.rfp.vendorCandidate.map((e, i) => {
        return {
          ...e,
          error: {
            vendorName: { value: false, message: '' },
            vendorNameDuplicate: { value: false, message: '' },
            vendorEmail: { value: false, message: '' },
            reason: { value: false, message: '' }
          },
          localPartners: e.localPartners?.map(el => ({ ...el, error: {
            vendorName: { value: false, message: '' },
            vendorNameDuplicate: { value: false, message: '' },
            vendorEmail: { value: false, message: '' },
            reason: { value: false, message: '' }
          }, }))
        }
      })
      commit('rfp/SET_VENDOR_CANDIDATE', dataCommitVendorCandidate, { root: true })

      
    }
  }
  
}