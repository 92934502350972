class Service {
  constructor() {
    this._serviceNames = [];
  }

  registerService(ServiceParam) {
    const tempService = new ServiceParam(this);
    const serviceName = tempService.name;

    if (this._serviceNames.includes(serviceName)) {
      throw new Error(`service ${serviceName} exist`);
    }

    this._serviceNames.push(serviceName);
    this[serviceName] = tempService;
  }
}

const service = new Service();

const services = [];
const requireServices = require.context("./services", true, /\.js$/);
requireServices.keys().forEach((fileName) => {
  const file = requireServices(fileName);
  services.push(file.default);
});

for (const i in services) {
  service.registerService(services[i]);
}

export default service;
