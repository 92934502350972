/* eslint-disable */
import { ENDPOINT_MASTERDATA_COA, ENDPOINT_MASTERDATA_COST_CENTER, ENDPOINT_MASTERDATA_WBS_LEV3_CODE, ENDPOINT_RFP_APPROVAL_DETAIL, 
  ENDPOINT_RFP_APPROVAL_PROCUREMENT, ENDPOINT_USER_APPROVER_PROCUREMENT, ENDPOINT_RFP_APPROVAL_DELETE_DRAFT, ENDPOINT_RFP_APPROVAL_LOG, 
  ENDPOINT_RFP_APPROVAL_APPROVAL, ENDPOINT_RFP_APPROVAL_CHANGE_APPROVER, ENDPOINT_RFP_APPROVAL_CHANGE_REQUESTER, 
  ENDPOINT_RFP_APPROVAL_APPROVE_WITH_AGREEMENT, ENDPOINT_RFP_APPROVAL_SECURITY_CODE, ENDPOINT_USER_REQUESTER_PROCUREMENT, ENDPOINT_RFP_APPROVAL_PREVIEW } from '@/core/constant/menuEndpoint'
import { TERPILIH, TIDAK_TERPILIH, CHECKER, RECOMMENDATION, APPROVER  } from '@/core/constant/procurementApproval.js'
import { APPROVAL_SELECTED, APPROVAL_ELIMINATED } from '@/core/constant/qualification.js'

import { parseLocaleNumber, formatThousandSeparate } from "@/core/components/helpers/formatRupiah.js";
import { deepCloneAdvanced } from "@/core/components/helpers";
const mandatory = 'Wajib Diisi'
const initState = {
  recommendations: { approvalType: RECOMMENDATION, approvalStatus: null, name: '', search: '', id: null, isDeleted: false, tempId: 'id0' },
  checker: { approvalType: CHECKER, approvalStatus: null, name: '', search: '', id: null, isDeleted: false, tempId: 'id0' },
  approver: { approvalType: APPROVER, approvalStatus: null, name: '', search: '', id: null, isDeleted: false, tempId: 'id0' },
}
const initDataCostBudgetInformationList = [{
  id: null,
  costCenterId: '',
  costCenterSearch: '',
  wbsElement: '',
  wbsName: '',
  wbsElementSearch: '',
  coaCode: '',
  coaDescription: '',
  coaCodeSearch: '',
  budgetValueIdr: '',
  isDeleted: false,
  error: {
    "Cost Center": '',
    "Budget Value": '',
  }
}]

export const Store = {
  namespaced: true,
  name: 'procurementApproval',
  state: {
    mainPage: '',
    errorMaxLength: {
      'Introduction / Background & Needs': '',
      'Project Goals': '',
      'Reason for Vendor Selection': '',
    },
    errorRecommendation: {
      'Recommendation': false,
      'Approver': false
    },
    selectedRecommendation: [initState.recommendations],
    selectedChecker: [initState.checker],
    selectedApprover: [initState.approver],
    selectedCandidate: {totalNominalProject: 0},
    errorVendorCandidate: [],
    dataProcurementApproval: {},
    negotiationResultList: [],
    costBudgetInformationListClone: [],
    logApproval: {},
    counterIntervalProcurement: 0,
  },
  mutations: {
    SET_MAIN_PAGE(state, payload) {
      state.mainPage = payload
    },
    SET_ERROR_MAX_LENGTH(state, payload) {
      state.errorMaxLength = payload
    },
    SET_ERROR_RECOMMENDATION(state, payload) {
      state.errorRecommendation = payload
    },
    SET_SELECTED_CHECKER(state, payload) {
      state.selectedChecker = payload
    },
    SET_SELECTED_RECOMMENDATION(state, payload) {
      state.selectedRecommendation = payload
    },
    SET_SELECTED_APPROVER(state, payload) {
      state.selectedApprover = payload
    },
    SET_SELECTED_CANDIDATE(state, payload) {
      state.selectedCandidate = payload
    },
    SET_VENDOR_CANDIDATE_SCORING(state, payload) {
      state.vendorCandidateScoring = payload
    },
    SET_ERROR_VENDOR_CANDIDATE(state, payload) {
      state.errorVendorCandidate = payload
    },
    SET_DATA_PROCUREMENT_APPROVAL(state, payload) {
      state.dataProcurementApproval = payload
    },
    SET_NEGOTIATION_RESULT_LIST(state, payload) {
      state.negotiationResultList = payload
    },
    SET_COST_AND_BUDGET_INFORMATION_LIST_CLONE(state, payload) {
      state.costBudgetInformationListClone = payload
    },
    SET_LOG_APPROVAL(state, payload) {
      state.logApproval = payload
    },
    SET_COUNTER_INTERVAL_PROCUREMENT(state, payload) {
      state.counterIntervalProcurement = payload
    }
  },
  actions: {
    checkErrorLength({ commit, state }, payload) {
      return Object.values(state.errorMaxLength).some(e => e)
    },
    messageErrorLength({ commit, state }, payload) {
      return Object.keys(state.errorMaxLength).filter(e => state.errorMaxLength[e])
    },
    checkErrorRecommendation({ commit, state }, payload) {
      const {
        selectedRecommendation,
        selectedApprover
      } = state
      const recommendations = selectedRecommendation.filter(e => !e.isDeleted).filter(item => item.name !== '')
      const approver = selectedApprover.filter(e => !e.isDeleted).filter(item => item.name !== '')
      const filledApprover = approver.length > 0
      const filledRecommendations = recommendations.length > 0
      commit('SET_ERROR_RECOMMENDATION', {Recommendation: !filledRecommendations, Approver: !filledApprover})
      return filledRecommendations && filledApprover
    },
    messageErrorRecommendation({ commit, state }, payload) {
      const {errorRecommendation} = state
      return Object.keys(errorRecommendation).filter(e => errorRecommendation[e])
    },
    commitErrorCostBudgetInformationSubmit({ commit, state }, payload) {
      const { dataProcurementApproval } = state
      const dataCommit = dataProcurementApproval.costBudgetInformationList.map((e, i) => {
        const condition = e.costCenterId || e.budgetValueIdr || i === 0
        return { ...e, 
        error: { ...e.error, 
          "Cost Center": condition && !e.costCenterId && !e.isDeleted ? mandatory : '',
          "Budget Value": condition && !e.budgetValueIdr && !e.isDeleted? mandatory : e.error["Budget Value"] }
        }})
      commit('SET_DATA_PROCUREMENT_APPROVAL', { ...dataProcurementApproval, costBudgetInformationList: dataCommit })
      return dataCommit
    },
    async checkErrorCostBudgetInformation({ commit, state, dispatch }, payload) {
      const { isSubmit } = payload
      if (state.dataProcurementApproval.costBudgetInformationList?.length === 0) {
        const dataCommit = initDataCostBudgetInformationList
        commit('SET_DATA_PROCUREMENT_APPROVAL', { ...state.dataProcurementApproval, costBudgetInformationList: dataCommit })
      }
      let result = []
      if (isSubmit) {
        result = await dispatch('commitErrorCostBudgetInformationSubmit')
      } else {
        result = state.dataProcurementApproval.costBudgetInformationList.filter(obj => {
          return obj.error['Budget Value'] !== mandatory
        })
      }
      return result.every(e => Object.values(e.error).every(el => !el))
    },
    async getMasterdataCoa({ commit, state, dispatch }, payload) {
      try {
        const { coaCode } = payload
        return await this.$api.get(ENDPOINT_MASTERDATA_COA, { coaCode })
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async getMasterdataCostCenter({ commit, state, dispatch }, payload) {
      try {
        const { costCenterId } = payload
        return await this.$api.get(ENDPOINT_MASTERDATA_COST_CENTER, { costCenterId })
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async getMasterdataWbsLev3Code({ commit, state, dispatch }, payload) {
      try {
        const { wbsElement } = payload
        return await this.$api.get(ENDPOINT_MASTERDATA_WBS_LEV3_CODE, { wbsElement })
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    dataCommitNegotiationResultList({ commit, state, dispatch }, payload) {
      const negotiationResultList = payload
      return negotiationResultList?.map(e => ({ ...e, 
        collapse: true, 
        isAllSelected: e.negotiationResultDetails?.resultItemList?.every(el => el.isSelected) || false,
        errorMaxLength: {
          'Note Internal BCA': '',
          'Exchange Rate': ''
        },
        negotiationResultDetails: e.negotiationResultDetails === null? null : { ...e.negotiationResultDetails, exchangeRate: formatThousandSeparate(e.negotiationResultDetails.exchangeRate, 'idr') },
        localPartnerList: e.localPartnerList === null? null : e.localPartnerList?.map(el => ({ ...el, 
          negotiationResultDetails: { ...el.negotiationResultDetails, exchangeRate: formatThousandSeparate(el.negotiationResultDetails.exchangeRate, 'idr') },
          collapse: true, 
          isAllSelected: el.negotiationResultDetails?.resultItemList?.every(ele => ele.isSelected) || false,
          errorMaxLength: {
            'Note Internal BCA': '',
            'Exchange Rate': ''
          },
        })),
      }))
    },
    dataCommitApprovals({ commit, state, dispatch }, payload) {
      const approvalListSort = payload
      if (approvalListSort?.length) {
        const recommendations = approvalListSort?.filter(e => e.approvalType === RECOMMENDATION)?.map(e => {
          const { username, ...newRes } = { ...e, search: e.name, userName: e.username}
          return newRes})
        commit('SET_SELECTED_RECOMMENDATION', recommendations.length === 0 ? [initState.recommendations] : recommendations)
        const checker = approvalListSort?.filter(e => e.approvalType === CHECKER)?.map(e => {
          const { username, ...newRes } = { ...e, search: e.name, userName: e.username }
            return newRes})
        commit('SET_SELECTED_CHECKER', checker.length === 0 && recommendations.length === 0 ? [initState.checker] : checker)
        const approver = approvalListSort?.filter(e => e.approvalType === APPROVER)?.map(e => {
          const { username, ...newRes } = { ...e, search: e.name, userName: e.username }
          return newRes})
        commit('SET_SELECTED_APPROVER', approver.length === 0 ? [initState.approver] : approver)
      } else {
        commit('SET_SELECTED_CHECKER', [initState.checker])
        commit('SET_SELECTED_RECOMMENDATION', [initState.recommendations])
        commit('SET_SELECTED_APPROVER', [initState.approver])
      } 
    },
    async getApproval({ commit, state, dispatch }, payload) {
      try {
        const { rfpId } = payload
        const response = await this.$api.get(ENDPOINT_RFP_APPROVAL_DETAIL, {rfpId})
        const negotiationResultList = await dispatch('dataCommitNegotiationResultList', response.data.result.negotiationResultList)
        const negotiationResultListClone = deepCloneAdvanced(negotiationResultList)
        const approvalListSort = response.data.result.approvalList?.sort((a,b) => a.approvalTypeSeq - b.approvalTypeSeq)
        dispatch('dataCommitApprovals', approvalListSort)
        const candidateScoringList = response.data.result.candidateScoringList?.map(e => ({ ...e,
          error: '',
          candidateScoringDetails: { ...e.candidateScoringDetails, status: e.candidateScoringDetails.status && e.candidateScoringDetails.status === 'Pilih Status'? TERPILIH : e.candidateScoringDetails.status },
          localPartnerList: e.localPartnerList?.map(el => ({ ...el, 
            error: '',
            candidateScoringDetails: { ...el.candidateScoringDetails, status: e.candidateScoringDetails.status && el.candidateScoringDetails.status === 'Pilih Status'? TERPILIH : el.candidateScoringDetails.status }
          })) || e.localPartnerList
        }))
        const costBudgetInformationList = response.data.result.costBudgetInformationList?.length ? 
          response.data.result.costBudgetInformationList.map(e => ({ ...e, 
            costCenterSearch: e.costCenterId,
            wbsElementSearch: e.wbsElement,
            coaCodeSearch: e.coaCode,
            error: {
              "Cost Center": '',
              "Budget Value": '',
            },
            budgetValueIdr: formatThousandSeparate(e.budgetValueIdr?.toString(), 'idr') })) : initDataCostBudgetInformationList
        const costBudgetInformationListClone = deepCloneAdvanced(costBudgetInformationList)
        commit('SET_DATA_PROCUREMENT_APPROVAL', { ...response.data.result, attachmentList: response.data.result.attachmentList || [], negotiationResultList, candidateScoringList,
          costBudgetInformationList, approvalList: approvalListSort
         })
        commit('SET_NEGOTIATION_RESULT_LIST', negotiationResultListClone)
        commit('SET_COST_AND_BUDGET_INFORMATION_LIST_CLONE', costBudgetInformationListClone)
        dispatch('getApprovalProcurementAll', { isMaximize: false })
        return response
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async getUserApproverProcurement({ commit, state, dispatch }, payload) {
      try {
        const { approvalId, approvalType, nominalProject, nameFilter, usernameList } = payload
        return await this.$api.get(ENDPOINT_USER_APPROVER_PROCUREMENT, { approvalId, approvalType, nominalProject, nameFilter, usernameList })
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async getApprovalLog({ commit, state, dispatch }, payload) {
      try {
        const { approvalId } = payload
        const response = await this.$api.get(ENDPOINT_RFP_APPROVAL_LOG, { approvalId })
        const commitLogApproval = { ...response.data.result, logDetails: response.data.result?.logDetails?.sort((a,b) => a.approvalTypeSeq - b.approvalTypeSeq)}
        commit('SET_LOG_APPROVAL', commitLogApproval)
        return response
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    payloadApprovalProcurement({ commit, state, dispatch }, payload) {
      const { isMaximize } = payload
      const stateNegotiationResultList = isMaximize?  state.negotiationResultList : state.dataProcurementApproval.negotiationResultList
      return stateNegotiationResultList?.map(e => {
        const { errorMaxLength, collapse, isAllSelected, ...newRes } = e
        return { ...newRes, 
          negotiationResultDetails: e.negotiationResultDetails === null? e.negotiationResultDetails : { ...e.negotiationResultDetails, exchangeRate: parseLocaleNumber(e.negotiationResultDetails.exchangeRate, 'idr')},
          localPartnerList: e.localPartnerList === null? e.localPartnerList : e.localPartnerList.map(el => {
            return { ...el, negotiationResultDetails: { ...el.negotiationResultDetails, exchangeRate: parseLocaleNumber(el.negotiationResultDetails.exchangeRate, 'idr')}
            }
          })
        }
      })
    },
    async getApprovalProcurementAll({ commit, state, dispatch }, payload) {
      try {
        const { isMaximize } = payload
        const data = await dispatch('payloadApprovalProcurement', { isMaximize })
        const response = await this.$api.post(ENDPOINT_RFP_APPROVAL_PROCUREMENT, data)
        const result = response.data.result
        const stateNegotiationResultList = isMaximize?  state.negotiationResultList : state.dataProcurementApproval.negotiationResultList
        const dataCommit = stateNegotiationResultList.map((e,i) => {
          return {
            ...e,
            negotiationResultDetails: e.negotiationResultDetails === null? null : {
              ...e.negotiationResultDetails,
              vatAmount: result.procurementApprovalVendor[i].procurementApprovalDetails.vatAmount,
              finalPrice: result.procurementApprovalVendor[i].procurementApprovalDetails.finalPrice,
              initialPrice: result.procurementApprovalVendor[i].procurementApprovalDetails.initialPrice,
              grandTotalIdr: result.procurementApprovalVendor[i].procurementApprovalDetails.grandTotalIdr,
              subTotal: result.procurementApprovalVendor[i].procurementApprovalDetails.subTotal,
              resultItemList: e.negotiationResultDetails.resultItemList.map((ell, jl) => ({ ...ell, finalPrice: result.procurementApprovalVendor[i].procurementApprovalDetails.itemList[jl].finalPrice })),
            },
            localPartnerList: e.localPartnerList === null? null : e.localPartnerList.map((el, j) => {
              return {
                ...el,
                negotiationResultDetails: {
                  ...el.negotiationResultDetails,
                  vatAmount: result.procurementApprovalVendor[i].localPartnerList[j].procurementApprovalDetails.vatAmount,
                  finalPrice: result.procurementApprovalVendor[i].localPartnerList[j].procurementApprovalDetails.finalPrice,
                  initialPrice: result.procurementApprovalVendor[i].localPartnerList[j].procurementApprovalDetails.initialPrice,
                  grandTotalIdr: result.procurementApprovalVendor[i].localPartnerList[j].procurementApprovalDetails.grandTotalIdr,
                  subTotal: result.procurementApprovalVendor[i].localPartnerList[j].procurementApprovalDetails.subTotal,
                  resultItemList: el.negotiationResultDetails.resultItemList.map((ell, jl) => ({ ...ell, finalPrice: result.procurementApprovalVendor[i].localPartnerList[j].procurementApprovalDetails.itemList[jl].finalPrice })),
                },
              }
            })
          }
        })
        if (isMaximize) {
          commit('SET_NEGOTIATION_RESULT_LIST', dataCommit)
        } else {
          commit('SET_DATA_PROCUREMENT_APPROVAL', { ...state.dataProcurementApproval, negotiationResultList: dataCommit })
        }
        commit('SET_SELECTED_CANDIDATE', response.data.result)
        return response
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async payloadPostApproval({ commit, state, dispatch }, payload) {
      const { isSubmit } = payload
      //approvalList
      const selectedChecker = state.selectedChecker.filter(e => e.userName).map((e,i) => ({ id: e.id, username: e.userName, approvalType: e.approvalType,  approvalTypeSeq: i+1, isDeleted: e.isDeleted }))
      const selectedRecommendation = state.selectedRecommendation.filter(e => e.userName).map((e,i) => ({ id: e.id, username: e.userName, approvalType: e.approvalType,  approvalTypeSeq: i+1, isDeleted: e.isDeleted }))
      const selectedApprover = state.selectedApprover.filter(e => e.userName).map((e,i) => ({ id: e.id, username: e.userName, approvalType: e.approvalType,  approvalTypeSeq: i+1, isDeleted: e.isDeleted }))
      //data
      let candidateScoringList = []
      state.dataProcurementApproval.candidateScoringList?.forEach(e => {
        if (e.candidateScoringDetails.status === TERPILIH) {
          candidateScoringList.push({ candidateId: e.candidateId, status: APPROVAL_SELECTED })
        } else if (e.candidateScoringDetails.status === TIDAK_TERPILIH) {
          candidateScoringList.push({ candidateId: e.candidateId, status: APPROVAL_ELIMINATED })
        }
        e.localPartnerList?.forEach(el => {
          if (el.candidateScoringDetails.status === TERPILIH) {
            candidateScoringList.push({ candidateId: el.candidateId, status: APPROVAL_SELECTED })
          } else if (el.candidateScoringDetails.status === TIDAK_TERPILIH) {
            candidateScoringList.push({ candidateId: el.candidateId, status: APPROVAL_ELIMINATED })
          }
        })
      })
      const { isSubmitted, dataCommit, ...data} = {
        ...state.dataProcurementApproval,
        isSubmit,
        candidateScoringList,
        attachmentList: state.dataProcurementApproval.attachmentList,
        negotiationResultList: state.dataProcurementApproval.negotiationResultList?.map(e => {
          const { errorMaxLength, collapse, isAllSelected, ...newRes } = e
          return { ...newRes, 
            negotiationResultDetails: e.negotiationResultDetails === null? e.negotiationResultDetails : { ...e.negotiationResultDetails, exchangeRate: parseLocaleNumber(e.negotiationResultDetails.exchangeRate, 'idr')},
            localPartnerList: e.localPartnerList === null? e.localPartnerList : e.localPartnerList.map(el => {
              return { ...el, negotiationResultDetails: { ...el.negotiationResultDetails, exchangeRate: parseLocaleNumber(el.negotiationResultDetails.exchangeRate, 'idr') } }
            })
          }
        }),
        costBudgetInformationList: state.dataProcurementApproval.costBudgetInformationList?.filter(e => (e.id || e.costCenterId || e.wbsElement || e.coaCode || e.budgetValueIdr))?.map(e => ({
          ...e,
          budgetValueIdr: parseLocaleNumber(e.budgetValueIdr, 'idr'),
        })),
        approvalList: [ ...selectedChecker, ...selectedRecommendation, ...selectedApprover ]
      }
      return data
    },
    async postApproval({ commit, state, dispatch }, payload) {
      try {
        const { isSubmit } = payload
        const payloadPostApproval = await dispatch('payloadPostApproval', { isSubmit })
        return await this.$api.post(ENDPOINT_RFP_APPROVAL_DETAIL, payloadPostApproval)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async cancelProcurementApproval({ commit, state, dispatch }, payload) {
      try {
        const { approvalId } = payload
        return await this.$api.put(`${ENDPOINT_RFP_APPROVAL_DELETE_DRAFT}/${approvalId}`)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async putApprovalApproval({ commit, state, dispatch }, payload) {
      try {
        const { approvalId, notes, action } = payload
        return await this.$api.put(`${ENDPOINT_RFP_APPROVAL_APPROVAL}/${approvalId}`, { notes, action })
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async putChangeApprovers({ commit, state, dispatch }, payload) {
      try {
        const { approvalId, reason } = payload
        const selectedChecker = state.selectedChecker.filter(e => e.userName).map((e,i) => ({ id: e.id, username: e.userName, approvalType: e.approvalType,  approvalTypeSeq: i+1, isDeleted: e.isDeleted }))
        const selectedRecommendation = state.selectedRecommendation.filter(e => e.userName).map((e,i) => ({ id: e.id, username: e.userName, approvalType: e.approvalType,  approvalTypeSeq: i+1, isDeleted: e.isDeleted }))
        const selectedApprover = state.selectedApprover.filter(e => e.userName).map((e,i) => ({ id: e.id, username: e.userName, approvalType: e.approvalType,  approvalTypeSeq: i+1, isDeleted: e.isDeleted }))
        const approvalList = [...selectedChecker, ...selectedRecommendation, ...selectedApprover]
        return await this.$api.put(ENDPOINT_RFP_APPROVAL_CHANGE_APPROVER, { approvalId, reason, approvalList })
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async putApprovalApproveWithAgreement({ commit, state, dispatch }, payload) {
      try {
        const data = payload
        return await this.$api.put(ENDPOINT_RFP_APPROVAL_APPROVE_WITH_AGREEMENT, data)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async getApprovalSecurityCode({ commit, state, dispatch }, payload) {
      try {
        const { approvalId } = payload
        return await this.$api.get(`${ENDPOINT_RFP_APPROVAL_SECURITY_CODE}/${approvalId}`)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async getApprovalUserRequesterProcurement({ commit, state, dispatch }, payload) {
      try {
        const { approvalId, project, nameFilter } = payload
        return await this.$api.get(ENDPOINT_USER_REQUESTER_PROCUREMENT, { approvalId, project, nameFilter })
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async putApprovalChangeRequester({ commit, state, dispatch }, payload) {
      try {
        const { approvalId, newRequester, reason } = payload
        return await this.$api.put(ENDPOINT_RFP_APPROVAL_CHANGE_REQUESTER, { approvalId, newRequester, reason })
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async postApprovalPreview({ commit, state, dispatch }, payload) {
      try {
        const { isSubmit } = payload
        const data = await dispatch('payloadPostApproval', { isSubmit })
        const response = await this.$api.post(ENDPOINT_RFP_APPROVAL_PREVIEW, data, { responseType: 'blob' })
        dispatch('file/fileReaderOpenNewTab', response, { root: true })
        return response
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    resetStateProcurement({ commit, state, dispatch }, payload) {
      commit('SET_ERROR_MAX_LENGTH', {
        'Introduction / Background & Needs': '',
        'Project Goals': '',
        'Reason for Vendor Selection': '',
      })
      commit('SET_ERROR_RECOMMENDATION', {
        'Recommendation': false,
        'Approver': false
      })
      commit('SET_MAIN_PAGE', '')
    }
  },
  
}