/* eslint-disable */
import { ENDPOINT_RFQ_LOI, ENDPOINT_RFQ_LOI_SUBMIT, ENDPOINT_RFQ_LOI_APPROVAL, ENDPOINT_RFQ_LOI_CHANGE_APPROVAL, ENDPOINT_RFQ_LOI_CANCEL, ENDPOINT_RFQ_LOI_SECURITY_CODE, ENDPOINT_RFQ_LOI_PREVIEW, ENDPOINT_RFQ_LOI_CONFIRMATION, ENDPOINT_USER_REQUESTER_SKT_LOI, ENDPOINT_RFQ_LOI_CHANGE_REQUESTER } from '@/core/constant/menuEndpoint'


export const Store = {
  namespaced: true,
  name: 'loiRfq',
  state: {
    draftLoi: {},
    approvals: {
      REVIEWER_DLOG: { search: '' },
      APPROVER_DLOG: { search: '' },
      APPROVER_GSIT: { search: '' },
      REVIEWER_GSIT: { search: '' },
    },
    attachments: [],
    mainPage: '',
    showNeedToCreateLoi: false
  },
  mutations: {
    SET_DRAFT_LOI(state, payload) {
      state.draftLoi = payload
    },
    SET_APPROVALS(state, payload) {
      state.approvals = payload
    },
    SET_ATTACHMENTS(state, payload) {
      state.attachments = payload
    },
    SET_MAIN_PAGE(state, payload) {
      state.mainPage = payload
    },
    SET_SHOW_NEED_LOI(state, payload) {
      state.showNeedToCreateLoi = payload
    },
  },
  actions: {
    async getLoi({ commit, state, dispatch }, payload) {
      try {
        const { rfqId } = payload
        const response = await this.$api.get(`${ENDPOINT_RFQ_LOI}/${rfqId}`)
        const error = {
          'Note Internal BCA': '',
          'Term of Payment': '',
          'Note Negotiation / BCA Request': '',
          'Note for Bank Guarantee': '',
          'PIC Vendor': '',
          'Email Vendor': '',
          'Approver ITP': '',
          'Approver GSIT': '',
        }
        commit('SET_DRAFT_LOI', { ...response.data.result, 
          collapse: state.draftLoi.collapse !== undefined? state.draftLoi.collapse : false, 
          securityCode: state.draftLoi?.securityCode ?? '', 
          openSecurityCode: state.draftLoi?.openSecurityCode !== undefined? state.draftLoi?.openSecurityCode : false,
          error })
        commit('SET_APPROVALS', {
          REVIEWER_DLOG: { search: '' },
          APPROVER_DLOG: { search: '' },
          APPROVER_GSIT: { search: '' },
          REVIEWER_GSIT: { search: '' },
        })
        for (const iterator of response.data.result.approvals) {
          commit('SET_APPROVALS', { ...state.approvals, [iterator.approvalType]: { ...iterator, search: iterator.name} })
        }
        if (!response.data.result.lastModifiedAt) {
          commit('SET_SHOW_NEED_LOI', true)
        }
        return response
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    dataPayload({ commit, state, dispatch }, payload) {
      const { isPreview } = payload
      const arrayApprovals = []
      for (const key in state.approvals) {
        if (state.approvals[key].username) {
          const { search, ...res } = state.approvals[key]
          arrayApprovals.push(res)
        }
      }
      if (isPreview) {
        const { lastModifiedAt, statusDocument, error, collapse, securityCode, openSecurityCode, ...data } = { 
          ...state.draftLoi, 
          approvals: arrayApprovals
        }
        return data
      } else {
        const { lastModifiedAt, statusDocument, error, collapse, securityCode, negotiationResultDetails, openSecurityCode, ...data } = { 
          ...state.draftLoi, 
          approvals: arrayApprovals
        }
        return data
      }
    },
    async saveAsDraftLoi({ commit, state, dispatch }, payload) {
      try {
        const data = await dispatch('dataPayload', { isPreview: false })
        return await this.$api.post(ENDPOINT_RFQ_LOI, data)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async updateDraftLoi({ commit, state, dispatch }, payload) {
      try {
        const data = await dispatch('dataPayload', { isPreview: false })
        return await this.$api.put(ENDPOINT_RFQ_LOI, data)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async submitDraftLoi({ commit, state, dispatch }, payload) {
      try {
        const data = await dispatch('dataPayload', { isPreview: false })
        return await this.$api.post(ENDPOINT_RFQ_LOI_SUBMIT, data)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async updateSubmitLoi({ commit, state, dispatch }, payload) {
      try {
        const data = await dispatch('dataPayload', { isPreview: false })
        return await this.$api.put(ENDPOINT_RFQ_LOI_SUBMIT, data)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async postLoiPreview({ commit, state, dispatch }, payload) {
      try {
        const data = await dispatch('dataPayload', { isPreview: true })
        const response = await this.$api.post(ENDPOINT_RFQ_LOI_PREVIEW, data, { responseType: 'blob' })
        dispatch('file/fileReaderOpenNewTab', response, { root: true })
        return response
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async approvalLoi({ commit, state, dispatch }, payload) {
      try {
        const { rfqId, data } = payload
        return await this.$api.put(`${ENDPOINT_RFQ_LOI_APPROVAL}/${rfqId}`, data)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async changeApproval({ commit, state, dispatch }, payload) {
      try {
        const { rfqId, data } = payload
        return await this.$api.put(`${ENDPOINT_RFQ_LOI_CHANGE_APPROVAL}/${rfqId}`, data)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async cancelLoi({ commit, state, dispatch }, payload) {
      try {
        const { rfqId } = payload
        const response = await this.$api.put(`${ENDPOINT_RFQ_LOI_CANCEL}/${rfqId}`)
        dispatch('resetState')
        return response
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async getLoiSecurityCode({ commit, state, dispatch }, payload) {
      try {
        const { rfqId } = payload
        return await this.$api.get(ENDPOINT_RFQ_LOI_SECURITY_CODE, { rfqId })
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async needToCreateLoi({ commit, state, dispatch }, payload) {
      try {
        const { rfqId } = payload
        return await this.$api.put(`${ENDPOINT_RFQ_LOI_CONFIRMATION}/${rfqId}`)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async getApprovalUserRequesterLoi({ commit, state, dispatch }, payload) {
      try {
        const { rfxId, project, nameFilter } = payload
        return await this.$api.get(ENDPOINT_USER_REQUESTER_SKT_LOI, { rfxId, project, nameFilter })
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async changeRequester({ commit, state, dispatch }, payload) {
      try {
        const { rfqId, requesterUsername, requesterName, reason } = payload
        return await this.$api.put(ENDPOINT_RFQ_LOI_CHANGE_REQUESTER, {rfqId, requesterUsername, requesterName, reason})
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async resetState({ commit, state, dispatch }, payload) {
      commit('SET_APPROVALS', {
        REVIEWER_DLOG: { search: '' },
        APPROVER_DLOG: { search: '' },
        APPROVER_GSIT: { search: '' },
        REVIEWER_GSIT: { search: '' },
      })
      commit('SET_MAIN_PAGE', '')
      commit('SET_SHOW_NEED_LOI', false)
    }
  }
  
}