/* eslint-disable */
import { ENDPOINT_REPORT_CHART, ENDPOINT_REPORT_DOWNLOAD, ENDPOINT_DASHBOARD_TOP, ENDPOINT_DASHBOARD_MONTHLY } from "@/core/constant/menuEndpoint";
const cancelledFilter = true
export const Store = {
	namespaced: true,

	// nama module, untuk call di component menggunakan "example/<nama_method>"
	name: 'report',

	state: {
    chartDataRFP: [],
    chartDataRFQ: [],
    chartDataRFX: [],
    chartDataRFI: [],
    chartAvgRfx: [],
    chartAvgApproval: [],
    chartAvgDoc: [],
    cardInformation: {},
    chartSla: [],
    chartSlaBan: [],
    chartEfficiency: [],
    chartEfficiencyMillion: [],
	},

	getters: {},

	mutations: {
    SET_CHART_DATA_RFP(state, payload) {
      state.chartDataRFP = payload
    },
    SET_CHART_DATA_RFQ(state, payload) {
      state.chartDataRFQ = payload
    },
    SET_CHART_DATA_RFX(state, payload) {
      state.chartDataRFX = payload
    },
    SET_CHART_DATA_RFI(state, payload) {
      state.chartDataRFI = payload
    },
    SET_CHART_AVG_RFX(state, payload) {
      state.chartAvgRfx = payload
    },
    SET_CHART_AVG_APPROVAL(state, payload) {
      state.chartAvgApproval = payload
    },
    SET_CHART_AVG_DOC(state, payload) {
      state.chartAvgDoc = payload
    },
    SET_CARD_INFORMATION(state, payload) {
      state.cardInformation = payload
    },
    SET_CHART_SLA(state, payload) {
      state.chartSla = payload
    },
    SET_CHART_SLA_BAN(state, payload) {
      state.chartSlaBan = payload
    },
    SET_CHART_EFFICIENCY(state, payload) {
      state.chartEfficiency = payload
    },
    SET_CHART_EFFICIENCY_MILLION(state, payload) {
      state.chartEfficiencyMillion = payload
    },
	},

	actions: {
    async setDataChart({commit}, payload) {
      try {
        const {moduleName, data} = payload
        switch(moduleName) {
          case 'rfp':
            commit('SET_CHART_DATA_RFP', data);
            break;
          case 'rfq':
            commit('SET_CHART_DATA_RFQ', data);
            break;
          case 'rfx':
            commit('SET_CHART_DATA_RFX', data);
            break;
          case 'rfi':
            commit('SET_CHART_DATA_RFI', data);
            break;
          case 'top5Rfx':
            commit('SET_CHART_AVG_RFX', data);
            break;
          case 'top5Approval':
            commit('SET_CHART_AVG_APPROVAL', data);
            break;
          case 'top5Doc':
            commit('SET_CHART_AVG_DOC', data);
            break;
        }
      } catch (error) {
        return error
      }
    },
    async getReportChart({ dispatch, state, }, payload) {
      try {
        const { startYear, endYear, moduleName} = payload
        const response = await this.$api.get(ENDPOINT_REPORT_CHART, { startYear, endYear, moduleName, cancelledFilter } )
        await dispatch('setDataChart', {moduleName, data: response.data.result})
        return response
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async getDashboardTop({ commit, state, dispatch }, payload) {
      try {
        const response = await this.$api.get(ENDPOINT_DASHBOARD_TOP, { cancelledFilter })
        commit('SET_CARD_INFORMATION', response.data.result)
        return response
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async getDashboardMonthlyChart({commit, state, dispatch}, payload) {
      try {
        const { startYear, endYear, moduleName} = payload
        const response = await this.$api.get(ENDPOINT_DASHBOARD_MONTHLY, { startYear, endYear, moduleName, cancelledFilter })
        const data = response.data.result
        switch(moduleName) {
          case 'sla':
            commit('SET_CHART_SLA', data);
            break;
          case 'ban':
            commit('SET_CHART_SLA_BAN', data);
            break;
          case 'efficiency':
            commit('SET_CHART_EFFICIENCY', data);
            break;
          case 'efficiencyMillion':
            commit('SET_CHART_EFFICIENCY_MILLION', data);
            break;
        }
        return response
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async getDownloadReport({ commit, state, dispatch,}, payload) {
      try {
        const { reportName, start, end, type } = payload
        const response = await this.$api.get(ENDPOINT_REPORT_DOWNLOAD, { reportName, start, end }, {responseType:'blob'})
        const fileType = {
          csv: { type: 'text/csv', name: 'csv' },
          xlsx: { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', name: 'xlsx'}
        }
        const blob = new Blob([response.data], { type: fileType[type] })
        const link = window.URL.createObjectURL(blob)
        const fileLink = document.createElement('a');
        fileLink.href = link;
        fileLink.setAttribute('download', `${reportName} ${start} - ${end}.${fileType[type].name}`);
        document.body.appendChild(fileLink);
        fileLink.click()
        return response
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    }
	}
}