<template>
  <div class="fixed bottom-0 z-40 w-11/12 mb-6 right-8 sm:right-16 lg:right-32">
    <div class="absolute bottom-0 right-0 mb-6">
      <ButtonGista
        type="primary"
        color="tertiary"
        rounded
        @click="click"
      >
        <div class="flex items-center">
          <p class="mr-3 text-sm sm:text-lg lg:text-2xl icon-arrow-up"></p>
          <p class="text-sm sm:text-base">Back To Top</p>
        </div>
      </ButtonGista>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'BackToTop',
    methods: {
      click() {
        document.getElementById('scrollArea').scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      }
    }
  }
</script>