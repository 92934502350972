//constant for error message custom

export const MESSAGE_EMPTY_FIELD = 'Wajib Diisi'
export const MESSAGE_PROJECT_REQUIREMENT = 'Minimal Isi Salah Satu'
export const MESSAGE_CANNOT_SAME_VALUE = 'Tidak Boleh Sama'
export const MESSAGE_TOTAL_SCORE = 'Total Score Harus Sama'
export const MESSAGE_BOBOT_SCORE = 'Bobot Score Minimal 25'
export const MESSAGE_TOTAL_BOBOT = 'Total Bobot Harus 100'
export const MESSAGE_FILE_TIDAK_DISUPPORT = 'File Tidak Di Support'
export const MESSAGE_FILE_SIZE = 'File yang diunggah tidak boleh lebih dari'


//constant for error pop up message
//for pop up validation save as draft if project name empty
export const MESSAGE_POPUP_VALIDATION_SAVE_AS_DRAFT = 'Ada Kesalahan Pengisian Form'
//for pop up validation submit if has error on validation
export const MESSAGE_POPUP_VALIDATION_SUBMIT = 'Ada Kesalahan Pengisian Form'
