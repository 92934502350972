<template>
  <div id="app_theme">
    <navbar class="md:hidden"></navbar>
    <gista-sidebar></gista-sidebar>
    <gista-frame>
      <div
        ref="scrollArea"
        id="scrollAreaSolutip"
        class="relative main-container md:my-0"
        >
        <transition name="block" mode="out-in">
          <router-view/>
        </transition>
      </div>
    </gista-frame>
  </div>
</template>

<script>
import Navbar from '@/core/components/dashboard/Navbar.vue';
export default {
  name: "App",
  props: ["keycloak"],
  components: {
    Navbar,
  },
  computed: {
    loading: {
			get() {
				return this.$store.state.modal.loading
			},
			set(value) {
				this.$store.commit('modal/SET_LOADING', value)
			}
		},
    menu() {
			return this.$store.state.dashboard.menu
		},
  },
  async beforeCreate() {
    // /** @type {Api}*/
    this.api = this.$service.Api;
  },
  async mounted() {
    const userProfile = {
      ...this.$store.state.dashboard.userProfile,
      userId: this.keycloak.tokenParsed.sub,
      name: this.keycloak.tokenParsed.name,
      email: this.keycloak.tokenParsed.email,
      username: this.keycloak.tokenParsed.preferred_username,
    }
    this.$store.commit('dashboard/SET_USERPROFILE', userProfile)
    this.loading.loadingDashboard = true
    //get menu role access
    const r1 = this.$store.dispatch('dashboard/getMenu')
    //get user detail
		const r2 = this.$store.dispatch("dashboard/getUserDetail");
    const [res1, res2] = await Promise.all([r1, r2])
    if (res1?.status === 200 || res2?.status === 200) {
      this.loading.loadingDashboard = false
    }
    /* Start of : Get error from API service & set error message to store */
    this.api.onError((error, header) => {
      const payload = {
        message: error.message ? error.message : `${error.error} ${error.path}`,
        errorMessages: error.errors,
        traceId: header ? header["x-b3-traceid"] : "",
      };
      this.$store.commit("setLoadingState", false);
      this.$store.commit("setErrorDialog", payload);
    });
    /* End of : Get error from API service & set error message to store */

    /* Start of : Get success message from API service & set success message to store */
    this.api.onSuccess((data) => {
      this.$store.commit("setSuccessDialog", data);
    });
    /* End of : Get success message from API service & set success message to store */
  }
};
</script>
