<template>
  <div>
    <div class="relative flex h-full">
      <div class="flex items-center justify-center">
        <div class="z-10 rounded-full border-6" :class="`${isInProgress? 'border-warning': 'border-success'}`"></div>
        <div v-if="lastIndex" class="absolute z-20 rounded-full border-6" :class="`${isInProgress? 'border-warning': 'border-primary'}`"></div>
        <div v-if="lastIndex" class="absolute z-10 rounded-full border-12" :class="`${isInProgress? 'border-warning-lightest': 'border-primary-lightest'}`"></div>
      </div>
      <div class="flex" :class="firstIndex? 'items-end': ''">
        <div class="border-l absolute right-1.5 border-gray-lightest transtiton transform duration-500 ease-in" :class="noLine? '' : lineFull ? 'h-full' : 'h-1/2'"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'StepLine',
  props: ['lastIndex', 'firstIndex', 'lineFull', 'noLine', 'isInProgress']
}
</script>

<style scoped>
.border-6 {
  border-width: 6px;
}
.border-12 {
  border-width: 12px;
}
</style>