<template>
  <gista-modal
    width="1/2"
    :value="modal[nameModal].value"
    :hideClose="false"
    customClass="max-w-2xl bg-white"
    :scrollable="true"
    @input="closeModal"
  >
    <div class="w-full p-6 flex flex-col">
      <div class="flex flex-auto flex-col justify-center">
        <div class="flex justify-center mb-6">
          <img src="@/core/assets/icons/message/rejected.svg" alt="error" class="w-28" />
        </div>
        <div v-if="modal[nameModal].title" class="flex justify-center mb-6">
          <p class="text-error text-xl font-semibold">{{modal[nameModal]?.title}}</p>
        </div>
        <div v-if="modal[nameModal].message" class="flex justify-center mb-6">
          <p class="text-gray text-sm text-center whitespace-pre-wrap">{{modal[nameModal]?.message}}</p>
        </div>
        <div v-if="modal[nameModal].traceid" class="flex justify-end mb-6">
          <p class="text-gray text-sm">traceid: <span class="text-gray-darkest">{{modal[nameModal]?.traceid}}</span></p>
        </div>
      </div>
      <div class="flex flex-none justify-center">
        <button-gista @click="closeModal" color="error" customClass="w-60">OK</button-gista>
      </div>
    </div>
  </gista-modal>
</template>

<script>
export default {
  name: "ModalError",
  props: {
    title: String,
    message: String,
    traceid: String,
    nameModal: String,
  },
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    modal: {
      get() {
        return this.$store.state.modal.modal
      },
      set(value) {
        this.$store.commit('modal/SET_MODAL', value)
      }
    }
  },
  methods: {
    click() {
      this.$emit("click")
      this.modal[this.nameModal].value = false
    },
    closeModal() {
      this.modal[this.nameModal].value = false
    }
  },
}
</script>