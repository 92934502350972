<template>
  <div v-if="showDetailBan && menuRfpNegotiationBanApprove?.canUpdate && isEnableButtonApprover">
    <div class="w-full p-4">
      <div v-if="detailBanApprover.disableApprovalButton && detailBanApprover?.showMessageAddedVendor">
        <p class="text-sm font-normal text-gray">BAN Approval</p>
        <div class="flex flex-wrap mt-1 mb-6 text-sm font-medium text-warning-dark">Unable to approve/reject due to added vendor candidate</div>
      </div>
      <div class="flex flex-col justify-between gap-6 sm:flex-row">
        <ButtonGista 
          type="secondary"
          color="error"
          :isDark="false"
          :disabled="detailBanApprover.disableApprovalButton"
          @click="toggle"
          customClass="mr-6 w-1/2">
          Reject
        </ButtonGista>
        <ButtonGista 
          type="primary"
          color="success"
          @click="toggle2"
          :disabled="detailBanApprover.disableApprovalButton"
          class="w-1/2">
          Approve</ButtonGista>
      </div>
		</div>
    <GistaModal 
      :value="isOpen"
      customClass="max-w-2xl bg-white"
			@input="toggle">
			<ValidationObserver v-slot="{ handleSubmit }">
        <div class="w-full p-5">
          <div class="flex justify-center w-full">
            <h5 class="text-lg font-semibold text-gray">Tolak BAN</h5>
          </div>
          <div class="flex-wrap w-full mb-4">
            <ValidationProvider name="Reason" rules="required|max:500" v-slot="{errors}">
              <TextareaGista
                label="Reason"
                placeholder="Leave a comment here"
                v-model="reason"
                :error="!!errors[0]"
                mandatory
              >
                <template #message>
                  <p>{{errors[0]}}</p>
                </template>
              </TextareaGista>
            </ValidationProvider>

          </div>
          <div class="flex justify-center">
            <ButtonGista 
              type="primary"
              color="error"
              @click="handleSubmit(clickReject)"
              customClass="w-full sm:w-56">
              Reject
            </ButtonGista>

          </div>
        </div>
      </ValidationObserver>
		</GistaModal>
    <GistaModal 
      :value="isOpen2"
      customClass="max-w-2xl bg-white"
			@input="toggle2">
      <ValidationObserver v-slot="{ handleSubmit }">
        <div class="w-full p-5">
          <div class="w-full mb-1">
            <h4 class="mb-8 text-3xl text-center font-medium">Setujui</h4>
            <div class="flex-wrap w-full mb-4">
              <ValidationProvider name="Reason" rules="max:500" v-slot="{errors}">
                <TextareaGista
                  label="Reason"
                  placeholder="Leave a comment here"
                  v-model="reason"
                  :error="!!errors[0]"
                >
                  <template #message>
                    <p>{{errors[0]}}</p>
                  </template>
                </TextareaGista>
              </ValidationProvider>

            </div>
          </div>
          <div class="flex justify-center">
            <ButtonGista 
              color="success"
              customClass="w-full sm:w-56"
              @click="handleSubmit(clickApprove)"
              >
              Approve
            </ButtonGista>

          </div>
        </div>
      </ValidationObserver>
		</GistaModal>
  </div>
</template>

<script>
import { MESSAGE_SUCCESS_BAN_APPROVE, MESSAGE_SUCCESS_BAN_REJECT } from '@/core/constant/successMessage'

export default {
  name: "RfpApproval",
  data() {
    return {
      isOpen: false,
      isOpen2: false,
      reason: ''
    }
  },
  computed: {
    banDetails() {
      return this.$store.state.negotiation.banDetails
    },
    userProfile() {
      return this.$store.state.dashboard.userProfile
    },
    showDetailBan() {
			return this.$store.state.negotiation.showDetailBan
		},
    menuRfpNegotiationBanApprove() {
			return this.$store.getters['dashboard/menuRfpNegotiationBanApprove']
		},
    detailBanApprover() {
      return this.$store.state.negotiation.detailBanApprover
    },
    roleApprove() {
      const username = this.userProfile.username
      return username === this.detailBanApprover.waitingApproval
    },
    isEnableButtonApprover() {
      return this.detailBanApprover.isEnableApprovalButton
    },
    projectLogRfp() {
			return this.$store.state.projectLog.projectLogRfp
		},
    modal: {
			get() {
				return this.$store.state.modal.modal
			},
			set(value) {
				this.$store.commit('modal/SET_MODAL', value)
			}
		},
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen
      this.reason = ''
    },
    toggle2() {
      this.isOpen2 = !this.isOpen2
      this.reason = ''
    },
    async fetchBanApproved() {
      this.$store.dispatch('negotiation/refetchDetailBanApprover', { negotiationId: this.banDetails.negotiationId })
    },
    fetchBanRejected() {
      this.$store.dispatch('negotiation/refetchDetailBanApprover', { negotiationId: this.banDetails.negotiationId })
    },
    async clickApprove() {
      try {
        this.modal.modalLoading = true
        const response = await this.$store.dispatch('negotiation/putBanApprove', { candidateId: this.banDetails.vendorCandidateId, reason: this.reason })
        if (response?.status < 300 && response?.status >= 200) {
          this.modal.modalSuccessApproved = { value: true, title: MESSAGE_SUCCESS_BAN_APPROVE }
          this.modal.modalLoading = false
          this.toggle2()
          this.$store.commit('projectLog/SET_RFP_LOG_ID', this.projectLogRfp?.rfpLogs?.at(-1)?.rfpLogId)
          this.fetchBanApproved()
          this.$emit('refetch')
        } else {
          this.$store.dispatch('modal/throwError', { response })
        }
      } catch (error) {
        this.modal.modalLoading = false
				this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
      }
    },
    async clickReject() {
      try {
        this.modal.modalLoading = true
        const response = await this.$store.dispatch('negotiation/putBanReject', { candidateId: this.banDetails.vendorCandidateId, reason: this.reason })
        if (response?.status < 300 && response?.status >= 200) {
          this.modal.modalSuccessApproved = { value: true, title: MESSAGE_SUCCESS_BAN_REJECT }
          this.modal.modalLoading = false
          this.toggle()
          this.$store.commit('projectLog/SET_RFP_LOG_ID', this.projectLogRfp?.rfpLogs?.at(-1)?.rfpLogId)
          this.$store.dispatch('negotiation/getDetailBanApprover', { negotiationId: this.banDetails.negotiationId })
          this.fetchBanRejected()
          this.$emit('refetch')
        } else {
          this.$store.dispatch('modal/throwError', { response })
        }
      } catch (error) {
        this.modal.modalLoading = false
				this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
      }
    }
  }
}
</script>