<template>
	<div class="w-full" v-if="sktLoiPage && enableApproval && userApproval && menuRfpSktLoiApproval?.canUpdate">
		<div class="w-full p-4">
      <p class="text-sm font-normal text-gray">{{isSingleVendor? 'LOI': draftSktLoi.needLoi? 'SKT & LOI' : 'SKT'}} Approval</p>
      <div class="flex flex-wrap mt-1 mb-6 text-sm font-medium text-gray-dark">Please review the {{isSingleVendor? 'LOI of vendor': draftSktLoi.needLoi? 'SKT & LOI of each vendor' : 'SKT of each vendor'}} </div>
      <div class="flex flex-col justify-between gap-6 sm:flex-row">
        <ButtonGista 
          type="secondary"
          color="error"
          :isDark="false"
          @click="toggle"
          customClass="w-full">
          Reject
        </ButtonGista>
        <ButtonGista 
          type="primary"
          color="success"
          @click="toggleApprove"
          class="w-full">
          Approve</ButtonGista>
      </div>
		</div>
		<GistaModal 
      :value="isOpen"
      customClass="max-w-2xl bg-white"
			@input="toggle">
			<ValidationObserver v-slot="{ handleSubmit }">
        <div class="w-full p-5">
          <div class="flex justify-center w-full">
            <h5 class="text-lg font-semibold text-gray">Tolak {{isSingleVendor? 'LOI': draftSktLoi.needLoi? 'SKT & LOI' : 'SKT'}}</h5>
          </div>
          <div class="flex-wrap w-full mb-4">
            <ValidationProvider name="Reason" rules="required|max:500" v-slot="{errors}">
              <TextareaGista
                label="Reason"
                placeholder="Leave a comment here"
                v-model="reason"
                :error="!!errors[0]"
                mandatory
              >
                <template #message>
                  <p>{{errors[0]}}</p>
                </template>
              </TextareaGista>
            </ValidationProvider>

          </div>
          <div class="flex justify-center">
            <ButtonGista 
              type="primary"
              color="error"
              @click="handleSubmit(clickReject)"
              customClass="w-full sm:w-60">
              Reject
            </ButtonGista>

          </div>
        </div>
      </ValidationObserver>
		</GistaModal>
		<GistaModal 
      :value="isOpen2"
      customClass="max-w-2xl bg-white"
			@input="toggleApprove">
			<ValidationObserver v-slot="{ handleSubmit }">
        <div class="w-full p-5">
          <div class="flex justify-center w-full">
            <h5 class="text-lg font-semibold text-gray">Setujui semua {{isSingleVendor? 'LOI': draftSktLoi.needLoi? 'SKT & LOI' : 'SKT'}}?</h5>
          </div>
          <div class="flex-wrap w-full mb-4">
            <ValidationProvider name="Reason" rules="max:500" v-slot="{errors}">
              <TextareaGista
                label="Reason"
                placeholder="Leave a comment here"
                v-model="reason"
                :error="!!errors[0]"
              >
                <template #message>
                  <p>{{errors[0]}}</p>
                </template>
              </TextareaGista>
            </ValidationProvider>

          </div>
          <div class="flex justify-center">
            <ButtonGista 
              color="success"
              @click="handleSubmit(clickApprove)"
              customClass="w-full sm:w-60">
              Approve
            </ButtonGista>

          </div>
        </div>
      </ValidationObserver>
		</GistaModal>
	</div>
</template>
<script>
import { MESSAGE_SUCCESS_APPROVE_SKT_LOI_ALL, MESSAGE_SUCCESS_APPROVE_SKT_ALL, MESSAGE_SUCCESS_APPROVE_LOI_ALL, MESSAGE_SUCCESS_REJECT_SKT_LOI_ALL, MESSAGE_SUCCESS_REJECT_SKT_ALL, MESSAGE_SUCCESS_REJECT_LOI_ALL } from '@/core/constant/successMessage'
export default {
	name: "SktLoiApproval",
	data() {
		return {
			isOpen: false,
			isOpen2: false,
      reason: '',
		}
	},
  computed: {
    modal: {
			get() {
				return this.$store.state.modal.modal
			},
			set(value) {
				this.$store.commit('modal/SET_MODAL', value)
			}
		},
    draftSktLoi: {
      get() {
        return this.$store.state.sktLoi.draftSktLoi
      },
      set(value) {
        this.$store.commit('sktLoi/SET_DRAFT_SKT_LOI', value)
      }
    },
    enableApproval() {
      return this.draftSktLoi.approvals?.some(e => e.approvalStatus === 'AWAITING_APPROVAL')
    },
    userProfile() {
      return this.$store.state.dashboard.userProfile
    },
    userApproval() {
      const user = this.draftSktLoi.approvals.find(e => e.approvalStatus === 'AWAITING_APPROVAL')
      return this.userProfile.username === user.username
    },
    reviewerDlog() {
      return this.draftSktLoi.approvals.find(e => e.approvalType === 'REVIEWER_DLOG')
    },
    approverDlog() {
      return this.draftSktLoi.approvals.find(e => e.approvalType === 'APPROVER_DLOG')
    },
    reviewerGsit() {
      return this.draftSktLoi.approvals.find(e => e.approvalType === 'REVIEWER_GSIT')
    },
    approverGsit() {
      return this.draftSktLoi.approvals.find(e => e.approvalType === 'APPROVER_GSIT')
    },
    sktLoiPage() {
      return this.$route.path.includes('skt-loi')
    },
    menuRfpSktLoiApproval() {
			return this.$store.getters['dashboard/menuRfpSktLoiApproval']
		},
    isSingleVendor() {
      return this.draftSktLoi.sktVendors?.length === 0
    },
  },
	methods: {
    async clickReject() {
      this.modal.modalLoading = true
      const response = await this.$store.dispatch('sktLoi/approvalSkt', { rfpId: this.$route.params.projectId, data: { notes: this.reason, action: 'REJECT' } })
      if (response?.status < 300) {
        this.$store.dispatch('projectLog/refetchSktLoiLog', { rfpId: this.$route.params.projectId })
        this.modal.modalSuccessApproved = { value: true, title: this.isSingleVendor? MESSAGE_SUCCESS_REJECT_LOI_ALL : this.draftSktLoi.needLoi? MESSAGE_SUCCESS_REJECT_SKT_LOI_ALL : MESSAGE_SUCCESS_REJECT_SKT_ALL }
        this.toggle()
      }
      this.modal.modalLoading = false
    },
    async clickApprove() {
      this.modal.modalLoading = true
      const response = await this.$store.dispatch('sktLoi/approvalSkt', { rfpId: this.$route.params.projectId, data: { notes: this.reason, action: 'APPROVE' } })
      if (response?.status < 300) {
        this.$store.dispatch('projectLog/refetchSktLoiLog', { rfpId: this.$route.params.projectId })
        this.modal.modalSuccessApproved = { value: true, title: this.isSingleVendor? MESSAGE_SUCCESS_APPROVE_LOI_ALL : this.draftSktLoi.needLoi? MESSAGE_SUCCESS_APPROVE_SKT_LOI_ALL : MESSAGE_SUCCESS_APPROVE_SKT_ALL }
        this.toggleApprove()
      }
      this.modal.modalLoading = false
    },
		toggle() {
			this.isOpen = !this.isOpen
      this.reason = ''
		},
		toggleApprove() {
			this.isOpen2 = !this.isOpen2
      this.reason = ''
		},
	},
}
</script>

<style scoped>
.width-button-submit {
  max-width: 40rem;
}
</style>