<template>
  <gista-modal
    width="1/2"
    :value="!canAccess"
    :hideClose="true"
    customClass="max-w-2xl bg-white"
    :scrollable="true"
    @input="closeModal"
  >
    <div class="w-full min-height-modal p-6 flex flex-col">
      <div class="flex flex-auto flex-col justify-center">
        <div class="flex justify-center mb-6">
          <img src="@/core/assets/icons/message/noitems.svg" alt="error" class="w-28" />
        </div>
        <div class="flex justify-center mb-6">
          <p class="text-error text-xl font-semibold">Akses Ditolak</p>
        </div>
        <div class="flex justify-center mb-6">
          <p class="text-gray text-sm text-center whitespace-pre-wrap">Anda tidak memiliki akses ke project ini</p>
        </div>
      </div>
      <div class="flex flex-none justify-center">
        <button-gista @click="closeModal" color="error" customClass="w-60">OK</button-gista>
      </div>
    </div>
  </gista-modal>
</template>

<script>
import { PATH_ROOT } from '@/core/constant/routeName'

export default {
  name: "ModalProjectAccessDenied",
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    canAccess: {
      get() {
        return this.$store.state.projectLog.canAccess
      },
      set(val) {
        this.$store.commit('projectLog/SET_CAN_ACCESS', val)
      }
    },
  },
  methods: {
    async closeModal() {
      this.canAccess = true
      const query = await this.$store.dispatch('dashboard/setQueryBacktoHome')
			this.$router.push({ path: `/${PATH_ROOT}`, query }).catch(() => ({}))
    }
  },
}
</script>