const Example = () =>
  import(
    /* webpackChunkName: "Example" */ "@/modules/module-example/pages/Example"
  );
  import { PATH_ROOT } from '@/core/constant/routeName'

export const Routes = [
  {
    path: `/${PATH_ROOT}/example`,
    name: "example",
    component: Example,
  },
];
