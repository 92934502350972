/* eslint-disable */
export const Store = {
	namespaced: true,

	// nama module, untuk call di component menggunakan "example/<nama_method>"
	name: 'width',

	state: {
    projectDetailContainerWidth: '',
		quotationDetailContainerWidth: '',
	},

	getters: {},

	mutations: {
    SET_PROJECT_DETAIL_CONTAINER_WIDTH(state, payload) {
      state.projectDetailContainerWidth = payload
    },
		SET_QUOTATION_DETAIL_CONTAINER_WIDTH(state, payload) {
			state.quotationDetailContainerWidth = payload
		},
	},

	actions: {
    
	}
}