/* eslint-disable */
import { ENDOPINT_LIST_OFFICIAL_SIGNATURE_APPROVER, ENDOPINT_OFFICIAL_SIGNATURE_LIST_EMPLOYEE, ENDPOINT_OFFICIAL_SIGNATURE_APPROVER } from '@/core/constant/menuEndpoint'
export const Store = {
	namespaced: true,

	// nama module, untuk call di component menggunakan "example/<nama_method>"
	name: 'config',

	state: {
        listEmployee: [],
        listApprover: []
	},

	getters: {},

	mutations: {
        SET_LIST_APPROVER(state, payload) {
            state.listApprover = payload
        },
        SET_LIST_EMPLOYEE(state, payload) {
            state.listEmployee = payload
        }
	},

	actions: {
        async getApproverList({commit, state, dispatch}, payload) {
            try {
                const page = payload?.page || 0
                const size = payload?.size || 10
                const name = payload?.search || ''
                const response = await this.$api.get(ENDOPINT_LIST_OFFICIAL_SIGNATURE_APPROVER, { page, size, name })
                commit('SET_LIST_APPROVER', response.data.result)
                return response
            } catch (error) {
                await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
                return error.response
            }
        },
        async getEmployeeList({commit, state, dispatch}, payload) {
            try {
                const response = await this.$api.get(ENDOPINT_OFFICIAL_SIGNATURE_LIST_EMPLOYEE, payload)
                commit('SET_LIST_EMPLOYEE', response.data.result)
                return response
            } catch (error) {
                await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
                return error.response
            }
        },
        async postNewOfficialSignature({commit, state, dispatch}, payload) {
            try {
                const formData = payload
                return await this.$api.post(ENDPOINT_OFFICIAL_SIGNATURE_APPROVER, formData)
            } catch (error) {
                await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
                return error.response
            }
        },
        async updateStatusOfficialSignature({commit, state, dispatch}, payload) {
            try {
                const formData = payload.formData
                return await this.$api.put(ENDPOINT_OFFICIAL_SIGNATURE_APPROVER + payload.id, formData)
            } catch (error) {
                await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
                return error.response
            }
        }
	}
}