<template>
  <div v-if="(showChangeVerificator) && menuRfpNegotiationBanVerificatorChange?.canUpdate">
		<div class="w-full p-4">
			<div class="">
				<ButtonGista type="secondary"
					color="primary"
					@click="handleOpen"
					customClass="w-full">
					Change Verificator</ButtonGista>
			</div>
		</div>
		<GistaModal :value="isOpen"
			customClass="max-w-2xl bg-white"
			@input="handleClose">
			<ValidationObserver v-slot="{ handleSubmit }">
				<div class="w-full p-5">
					<div class="flex justify-center w-full mb-4">
						<h5 class="text-lg font-semibold text-gray">Change Verificator</h5>
					</div>
					<div class="w-full mb-4">
						<ValidationProvider name="newVerificator"
							rules="required"
							v-slot="{ errors }">
							<AutocompleteNewSolutip
								label="New Verificator"
								placeholder="(Search)"
								:options="approverNegotiation"
								@updateInput="inputSearchVerificator($event)"
								@updateAutoComplete="changeVerificator($event)"
								@blur="blurVerificator"
								@focus="focusVerficator"
								v-model="newVerificatorNameLocalState"
								:isLoading="isLoadingVerificator"
								:error="!!errors[0]"
								textField="name"
                textField2="jobTitle"
								iconLeft="icon-search text-xl text-gray-light"
                :mandatory="true"
							>
								<template #message>
									<p class="mt-1 text-xs text-error">{{ errors[0] }}</p>
								</template>
							</AutocompleteNewSolutip>
						</ValidationProvider>
					</div>
          <div class="w-full mb-4">
						<ValidationProvider name="notes"
							rules="required|max:500"
							v-slot="{ errors }">
							<TextareaGista label="Notes"
								placeholder="Leave a comment here"
								v-model="form.notes"
								:error="!!errors[0]"
								:mandatory="true">
								<template #message>
									<p>{{ errors[0] }}</p>
								</template>
							</TextareaGista>
						</ValidationProvider>
					</div>
					<div class="flex justify-end">
						<ButtonGista type="primary"
							color="success"
							@click="handleSubmit(clickChangeVerificator)"
							customClass="w-full sm:w-56">
							Change Verificator
						</ButtonGista>

					</div>
				</div>
			</ValidationObserver>
		</GistaModal>
	</div>
</template>
<script>
import AutocompleteNewSolutip from '@/core/components/custom/AutocompleteNew'
import { ITP_VERIFICATOR, SPV_ITP_APPROVAL, REPRESENTATIVE_GSIT, SPV_GSIT_APPROVAL, REPRESENTATIVE_UKKP, SPV_UKKP_APPROVAL } from '@/core/constant/negotiation.js'
import { MESSAGE_SUCCESS_CHANGE_VERIFICATOR } from '@/core/constant/successMessage'

export default {
  name: "RfpChangeVerificator",
  props: ["refetch"],
  data() {
    return {
      isOpen: false,
      newVerificatorNameLocalState: '',
      isLoadingVerificator: false,
      form: {
				newVerificatorName: '',
				newVerificatorId: '',
				notes: '',
			},
    }
  },
  computed: {
    showChangeVerificator() {
      return (this.showDetailBan || this.showVendorDetails) && !this.renegotiationProcess && this.isFinalDeal && this.isEnableChangeVerificator
    },
    isEnableChangeVerificator() {
      return this.negotiationDetails?.isEnableChangeVerificator
    },
    approverNegotiation() {
      return this.$store.state.negotiation.approverNegotiation
    },
    detailBanApprover() {
      return this.$store.state.negotiation.detailBanApprover
    },
    negotiationDetails() {
      return this.$store.state.negotiation.negotiationDetails
    },
    renegotiationProcess() {
      return this.$store.state.negotiation.negotiationDetails?.isEnableRenegotiation
    },
    isFinalDeal() {
      return this.$store.state.negotiation.negotiationDetails?.negotiationStatus === 'Final Deal'
    },
    paramSearchVerificator() {
      if (this.detailBanApprover.itpVerificatorStatus) {
        return ITP_VERIFICATOR
      } else if (this.detailBanApprover.directApproverItpStatus) {
        return SPV_ITP_APPROVAL
      } else if (this.detailBanApprover.representGsitStatus) {
        return REPRESENTATIVE_GSIT
      } else if (this.detailBanApprover.directApproverGsitStatus) {
        return SPV_GSIT_APPROVAL
      } else if (this.detailBanApprover.representUkkpStatus) {
        return REPRESENTATIVE_UKKP
      } else if (this.detailBanApprover.directApproverUkkpStatus) {
        return SPV_UKKP_APPROVAL
      } else {
        return ''
      }
    },
    banDetails() {
      return this.$store.state.negotiation.banDetails
    },
    showDetailBan() {
			return this.$store.state.negotiation.showDetailBan
		},
    menu() {
			return this.$store.state.dashboard.menu
		},
    menuRfpNegotiationBanVerificatorChange() {
			return this.$store.getters['dashboard/menuRfpNegotiationBanVerificatorChange']
    },
    projectLogRfp() {
			return this.$store.state.projectLog.projectLogRfp
		},
    modal: {
      get() {
        return this.$store.state.modal.modal
      },
      set(value) {
        this.$store.commit('modal/SET_MODAL', value)
      }
    },
    showVendorDetails() {
        return this.$store.state.negotiation.showVendorDetails
    },
  },
  methods: {
    async handleOpen() {
      this.isOpen = true
    },
    handleClose() {
      this.resetForm()
      this.isOpen = false
    },
    async inputSearchVerificator(e) {
      this.isLoadingVerificator = true
      const offers = this.negotiationDetails?.offers
      const negotiationId = offers[offers.length - 1].negotiationId
      await this.$store.dispatch('negotiation/getUserApproverNegotiation', { name: e, project: 'RFP', negotiationId  })
      this.isLoadingVerificator = false
    },
    changeVerificator(e) {
      this.form.newVerificatorId = e.userName
      this.form.newVerificatorName = e.name
      this.newVerificatorNameLocalState = e.name
    },
    blurVerificator() {
      this.newVerificatorNameLocalState = this.form.newVerificatorName
    },
    async focusVerficator() {
      this.isLoadingVerificator = true
      const offers = this.negotiationDetails?.offers
      const negotiationId = offers[offers.length - 1].negotiationId
      await this.$store.dispatch('negotiation/getUserApproverNegotiation', { name: this.newVerificatorNameLocalState, project: 'RFP', negotiationId })
      this.isLoadingVerificator = false
    },
    resetForm() {
      this.form = {
        newVerificatorName: '',
				newVerificatorId: '',
				notes: '',
      }
      this.newVerificatorNameLocalState = ''
    },
    fetchBanApprover() {
      let negotiationId
      if(this.showVendorDetails) {
        const offers = this.negotiationDetails?.offers
        negotiationId = offers[offers.length - 1].negotiationId
      } else negotiationId = this.banDetails.negotiationId
      this.$store.dispatch('negotiation/getDetailBanApprover', { negotiationId })
      setTimeout(() => {
        this.$store.dispatch('negotiation/getDetailBanApprover', { negotiationId })
      }, 3000)
    },
    async clickChangeVerificator() {
      try {
        this.modal.modalLoading = true
        const newVerificator = this.form.newVerificatorId
        
        let candidateId
        if (this.showVendorDetails) {
          candidateId = this.negotiationDetails?.vendorCandidateId
        } else {
          candidateId = this.banDetails.vendorCandidateId
        }
        const response = await this.$store.dispatch('negotiation/putNegotationChangeVerificator', { newVerificator, candidateId, note: this.form.notes })
        if (response?.status < 300 && response?.status >= 200) {
          this.modal.modalLoading = false
          this.handleClose()
          this.resetForm()
          this.$store.commit('projectLog/SET_RFP_LOG_ID', this.projectLogRfp?.rfpLogs?.at(-1)?.rfpLogId)
          this.$emit("refetch")
          this.fetchBanApprover()
          this.modal.modalSuccessSaved = { value: true, title: MESSAGE_SUCCESS_CHANGE_VERIFICATOR }
        } else {
          this.$store.dispatch('modal/throwError', { response })
        }
      } catch (error) {
        console.log(error);
        this.modal.modalLoading = false
        this.modal.modalError = { value: true, traceid: error.traceid, title: error.title, message: error.message }
      }
    }
  },
  components: {
    AutocompleteNewSolutip
  }
}
</script>