<template>
  <div>
    <div class="w-full p-4" v-if="approvalPage && isSubmitted && mainPage !== CHANGE && menuRfpApprovalChangeApprover?.canUpdate && someWaitingApproval && requester">
      <div class="flex flex-col justify-between gap-6 sm:flex-row">
        <ButtonGista 
          type="secondary"
          color="primary"
          @click="clickChangeApprovers"
          customClass="w-full">
          Change Approvers</ButtonGista>
      </div>
    </div>
  </div>
</template>

<script>
import { CHANGE } from '@/core/constant/procurementApproval.js'

export default {
  name: 'ApprovalChangeApprover',
  data() {
    return {
      isOpen: false,
      CHANGE
    };
  },
  computed: {
    dataProcurementApproval() {
      return this.$store.state.procurementApproval.dataProcurementApproval
    },
    isSubmitted() {
      return this.dataProcurementApproval.isSubmitted
    },
    mainPage() {
      return this.$store.state.procurementApproval.mainPage
    },
    approvalPage() {
      return this.$route.path.includes('approval')
    },
    menuRfpApprovalChangeApprover() {
			return this.$store.getters['dashboard/menuRfpApprovalChangeApprover']
		},
    logApproval() {
      return this.$store.state.procurementApproval.logApproval
    },
    someWaitingApproval() {
      return this.logApproval.waitingApproval || this.logApproval?.logDetails?.some(e => e.requiredUploadSignedApproval && e.approvalStatus === null)
    },
    userDetail() {
      return this.$store.state.dashboard.userProfile;
    },
    requester() {
      return this.logApproval.userRequester?.toLowerCase() === this.userDetail.username?.toLowerCase()
    },
  },
  methods: {
    clickChangeApprovers() {      
      this.$store.commit('procurementApproval/SET_MAIN_PAGE', CHANGE)
      this.$store.dispatch('projectLog/resetInterval')
    },
  }
};
</script>